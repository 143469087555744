import { MouseEvent, useContext, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ModalDDJJ from "../../ModalDDJJ/ModalDDJJ";
import { useDDJJHook } from "../../../hooks/useDDJJHook";
import { useDarDeBajaHook } from "../../../hooks/useDarDeBajaHook";
import { ModalDarDeBaja } from "../../ModalDarDeBaja/ModalDarDeBaja";
import { UserContext } from "../../../context/userContext/UserContexts";
import { roles } from "../../../helper/roles";

export const MenuCardInfoUser = ({ nroCuenta }: { nroCuenta: string })=> {

    const { user } = useContext(UserContext)
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const { width } = useWindowDimensions();

    const {
        openModaDDJJ,
        setOpenModaDDJJ,
        handleOpenModaDDJJ,
    } = useDDJJHook()

    const {
        openModalDarDeBaja,
        setOpenModalDarDeBaja,
        handleOpenModalDarDeBaja,
    } = useDarDeBajaHook()
    
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                sx={{mr:0, pr:0}}
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <img
                    src={require("../../../images/tabla/puntosBlancos.png")}
                    alt="logo"
                />
            </Button>
            <Menu
                marginThreshold={(width*0.09)}
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div>
                <MenuItem
                    onClick={ () => navigate("/Historial-ordenes", { state: { nroCuentaNavigate: nroCuenta }} ) }
                    sx={{
                        color: "#004B6F",
                        justifyContent: "space-around",
                    }}
                >
                    <p>Historial de órdenes</p>
                </MenuItem>

                {/* <MenuItem
                    onClick={handleOpenModaDDJJ}
                    sx={{
                        color: "#004B6F",
                    }}
                >
                    <p>Enviar DDJJ</p>
                </MenuItem>
                    <ModalDDJJ
                        nroDeCuenta={nroCuenta}
                        open={openModaDDJJ}
                        setOpen={setOpenModaDDJJ}
                    /> */}

                {    
                (user.rol === roles.comercial || user.rol === roles.administrador) &&
                    <>            
                        <MenuItem
                            onClick={handleOpenModalDarDeBaja}
                            sx={{
                                color: "#004B6F",
                            }}
                        >
                            <p>Dar de baja</p>
                        </MenuItem>
                        <ModalDarDeBaja
                            nroDeCuenta={nroCuenta}
                            open={openModalDarDeBaja}
                            setOpen={setOpenModalDarDeBaja}
                        />
                    </>
                }
                </div>
            </Menu>
        </div>
    );
}