import { TableColumn } from 'react-data-table-component';
import { RowConciliacionesExterior } from '../../interfaces/interfacesRows/rows';

const difColor = "#FFBABA";

export const columnsConciliacionesExterior: TableColumn<RowConciliacionesExterior>[] = [
    {
        selector: (row: RowConciliacionesExterior) => row.symbol,
        name: "Instrumento",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesExterior) => row["diferencia"] < 0,
                style: {
                    backgroundColor: difColor,
                },
            },
        ],
    },    
    {
        selector: (row: RowConciliacionesExterior) => row.tenenciaExterior,
        name: "Tenencia en exterior",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesExterior) => row["diferencia"] < 0,
                style: {
                    backgroundColor: difColor,
                },
            },
        ],
    },    
    {
        selector: (row: RowConciliacionesExterior) => row.tenenciaSistema,
        name: "Tenencia en Ingemática",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesExterior) => row["diferencia"] < 0,
                style: {
                    backgroundColor: difColor,
                },
            },
        ],
    },    
    {
        selector: (row: RowConciliacionesExterior) => row.diferencia,
        name: "Diferencia",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesExterior) => row["diferencia"] < 0,
                style: {
                    backgroundColor: difColor,
                },
            },
        ],
    },
]