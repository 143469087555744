import { TableColumn } from 'react-data-table-component';
import { RowLiquidezBlanqueo } from '../../interfaces/interfacesRows/rows';
import { formatCurrency } from '../formatCurrency';

// Función para generar una columna con los estilos comunes
const crearColumna = (
    selector: (row: RowLiquidezBlanqueo) => any,
    name: string,
    center: boolean = true,
    grow?: number
): TableColumn<RowLiquidezBlanqueo> => ({
    selector,
    name,
    center,
    grow,
    style: {
        color: "#004B6F",
    },
});

const columnsLiquidezBlanqueo: TableColumn<RowLiquidezBlanqueo>[] = [
    crearColumna((row) => row.comitente, "Comitente"),
    crearColumna(({ ultimaOperacionPesos }) => ultimaOperacionPesos ? `${ultimaOperacionPesos.FechaLiquidacion} - ${diasDesdeFecha(ultimaOperacionPesos.FechaLiquidacion)} días` : "-", "Fecha desde el último Pago/Cobro $$"),
    crearColumna((row) => row.saldoPesos ? formatCurrency(row.saldoPesos) : "-", "Saldo liquido pesos"),
    crearColumna(({ ultimaOperacionPesosDolares }) => ultimaOperacionPesosDolares ? `${ultimaOperacionPesosDolares.FechaLiquidacion} - ${diasDesdeFecha(ultimaOperacionPesosDolares.FechaLiquidacion)} días` : "-", "Fecha desde ultimo Pago/Cobro U$D"),
    crearColumna((row) => row.saldoDolares ? formatCurrency(row.saldoDolares) : "-", "Saldo liquido dolares"),
];

function diasDesdeFecha(fechaStr: string): number {
    // Obtener la fecha de hoy en Buenos Aires (UTC-3)
    const hoyBuenosAires = new Date();
    const utcOffset = -3; // Buenos Aires está en UTC-3
    hoyBuenosAires.setHours(hoyBuenosAires.getHours() + utcOffset);

    // Parsear la fecha ingresada en el formato "DD/MM/YY"
    const [dia, mes, año] = fechaStr.split("/").map(Number);
    const añoCompleto = año < 100 ? 2000 + año : año; // Asumir años como 20XX si son de 2 dígitos

    const fechaIngresada = new Date(añoCompleto, mes - 1, dia); // Mes en Date es 0-indexado

    // Calcular la diferencia en milisegundos
    const diffMs = hoyBuenosAires.getTime() - fechaIngresada.getTime();

    // Convertir la diferencia a días
    const diasDiff = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    return diasDiff;
}

export { columnsLiquidezBlanqueo };
