import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Button, CircularProgress } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container, FileText, Input, Label, Row, Subtitulo, SubtituloContainer, WhiteBox } from "./elements";
import { useGeneradorArchivo } from "../../hooks/useGeneradorArchivo";


export const GeneradorPrecios = () => {

    const { width, height } = useWindowDimensions();

    const {
        name,
        handleDownload,
        archive,
        fileInputRef,
        handleChange,
    } = useGeneradorArchivo();

    return (
        <div
            style={{
                width: width * 0.85,
                height: height * 0.92,
                left: width * 0.15,
                top: height * 0.062,
                position: "absolute",
            }}
        >
            <Container>
                <SubtituloContainer>
                    <Subtitulo>GENERADOR DE PRECIOS INGEMATICA</Subtitulo>
                </SubtituloContainer>
                <WhiteBox>
                    <Row>
                        <Label htmlFor="upload">
                            <Icon icon="material-symbols:download" style={styles.logo} />
                            Seleccionar archivo
                        </Label>
                        <Input
                            ref={fileInputRef} // Asignar la referencia al input
                            type="file"
                            accept=".prn"
                            name="upload"
                            id="upload"
                            onChange={(e) => 
                                handleChange(e)
                            }
                        />
                        {name ? (
                            <FileText>
                                {name?.substring(name.lastIndexOf("\\"))}
                            </FileText>
                        ) : (
                            <FileText>Ningún archivo selec.</FileText>
                        )}
                    </Row>
                    <Button
                        style={{
                            marginTop: "24px",
                            backgroundColor: "#004B6F",
                            color: 'white',
                            fontSize: "0.7em",
                            padding: "0.7em 2em",
                        }}
                        onClick={handleDownload}
                        variant="contained"
                        size="medium"
                        disabled={archive ? false : true}
                    >
                        DESCARGAR
                    </Button>
                </WhiteBox>
            </Container>
        </div>
    );
};


const styles = {
    logo: {
        fontSize: "2em",
        marginRight: "0.5em",
    }
}