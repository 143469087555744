import { GetTenenciaDolarRentaResponse, GetTenenciaResponse } from "../interfaces/interfaceTenecia/getTenenciaInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getTenencia(cuenta: string) {
    const params = { accountNumber: cuenta };
    return await apiInstance.get<GetTenenciaResponse>('/tenencia', { params });
}

export async function TransferirDolares({ accountNumber, especie, cantidad, tipoDolar }: { tipoDolar: string, accountNumber: string, especie: string, cantidad: number }) {
    return await apiInstance.post<GetTenenciaResponse>('/transferirDolarRenta', { accountNumber, especie, cantidad, tipoDolar });
}

export async function getTenenciaDolarRenta(cuenta: string) {
    const params = { accountNumber: cuenta };
    return await apiInstance.get<GetTenenciaDolarRentaResponse>('/tenenciaDolarRenta', { params });
}

