import React from 'react'
import { date } from '../../../helper/calcularDateTenencia';
import { formatName } from '../../../helper/formatearNombreTenencia';
import { Container, CuentaInfo, FechaTenencia, LogoContainerPuntos, Name } from './elements';
import { MenuCardInfoUser } from '../MenuCardInfoUser/MenuCardInfoUser';

export interface DataCard {
    nroCuenta: string;
    userName: string;
}

export const CardInfoUser = ({ nroCuenta, userName }: DataCard) => {
    
    return (
        <Container>
            <div style={{ display:"flex", justifyContent: "space-between"}}>
                <div style={{display: "flex"}}>
                    <CuentaInfo>
                        Cuenta N° {nroCuenta}
                    </CuentaInfo>
                    <Name>
                        {formatName(userName)}
                    </Name>
                </div>
                <LogoContainerPuntos>
                    <MenuCardInfoUser nroCuenta={nroCuenta}/>
                </LogoContainerPuntos>
            </div>
            <FechaTenencia>
                Tenencia hasta {date()}
            </FechaTenencia>
        </Container>
    )
}
