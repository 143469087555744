import styled from "styled-components";

export const DataBox = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 0 0.5em 0;
`;

export const SubContainer = styled.div`
    display: flex;
    white-space: nowrap;
`;

export const Sub = styled.h3`
    font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const PlusIconContainer = styled.div`
    display: flex;
`;

export const PlusTexto = styled.p`
    color: #035e88;
    font-weight: 600;
    line-height: 1.5em;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: stretch;
    height: 2.3em;
    float: right;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline:none;
    font-size: 0.9em;
`;

export const InputSearch = styled.input`
    border-radius: 0.5em;
    background: white;
    border: none;
    outline:none;
    font-size: 1em;
    font-weight: bold;
    font-family: inherit;
    color: rgb(102, 102, 102);
    padding-left: 1em;
`;

export const SearchButton = styled.button`
    border-radius: 2em;
    background: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding-right: 1em;
`;

export const CriterioContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0.8em 0;
`;

export const DownloadButton = styled.button`
    cursor: pointer;
    border: none;
    background-color: inherit;
`;

export const CheckContainer = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-right: 1em;
`;

export const CheckText = styled.p`
    font-size: 1em;
    font-weight: 500;
    margin: 0;
`;

export const RowContent = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 25em;
    justify-content: end;
    align-items: center;
`;

export const Button = styled.button`
    cursor: pointer;
    border: none;
    background-color: inherit;
`;
