import {useContext, useEffect, useState} from 'react';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { DatosClaveEspecie } from '../interfaces/interfaceNombreEspecie/interfaceNombreEspecie';
import { TipoMercado } from '../components/CardEspecieOrdenes/enums';

interface Props {
  nombre: string;
  nroOrden: string;
}

export const useCardEspecieOrdenesHook = ({nombre, nroOrden}: Props) => {
  const [data, setData] = useState<DatosClaveEspecie[] | null>(null)
  const {tipoMercado, dataExterior, dataLocal} = useContext(MercadoContext)
  const [ loadingCancelarOrden, setLoadingCancelarOrden ] = useState<boolean>(false)

  useEffect(() => {
    if (tipoMercado === TipoMercado.Local) {
      setData(dataLocal);
    } else {
      setData(dataExterior);
    }
  }, [tipoMercado, dataLocal, dataExterior]);

  function esExterior(): boolean {
    return nombre.endsWith('_US');
  }

  function formatTipoAccion(accion: string) {
    let formatValue = '';
    switch (accion) {
      case 'COPE OFF':
        formatValue = 'COMPRA';
        break;
      case 'VOPE OFF':
        formatValue = 'VENTA';
        break;
      default:
        formatValue = accion.toUpperCase();
        break;
    }
    return formatValue;
  }

  function formatHour(hora: string) {
    if (hora){
    const [hours, minutes] = hora?.split(':');
    return `${hours}:${minutes}`;
    } else {
      return ""
    }
  }

  return {
    formatTipoAccion,
    formatHour,
    esExterior,
    loadingCancelarOrden,
  };
};
