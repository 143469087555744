export function formatCurrency(x: null | number | string | undefined) {
    if (x === null || x === undefined) {
        return '';
    } else {

        let numberSeparado = Number(x).toFixed(2).toString().split('.');
        //let numberSeparado = x.toString().split('.');

        if (numberSeparado.length > 1) {
            numberSeparado[1] = numberSeparado[1].substring(0, 2);
        }
        let numeroDosDecimales = numberSeparado.join(',');


        return numeroDosDecimales.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

export function diferenciasGalloCaja(numCaj: number | undefined, numGall: number | undefined) {
    const caja = numCaj ? numCaj : 0
    const gallo = numGall ? numGall : 0

    return caja - gallo
}