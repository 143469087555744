import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const paginationModel = { page: 0, pageSize: 10 };

export const columnsToMap = [
    { "name": "fechaHora", "headerName": "Fecha/Hora",  "minWidth": 180, "flex": 1},
    { "name": "nroCliente", "headerName": "Cliente",  "minWidth": 110, "flex": 1},
    { "name": "moneda", "headerName": "Moneda",  "minWidth": 90, "flex": 1},
    { "name": "importe", "headerName": "Importe",  "minWidth": 90, "flex": 1},
    { "name": "banco", "headerName": "Banco",  "minWidth": 200, "flex": 1},
    { "name": "CBU", "headerName": "CBU",  "minWidth":  250, "flex": 1},
    { "name": "tipoIdentImp", "headerName": "Tipo de ident. imp.",  "minWidth":  100, "flex": 1},
    { "name": "numIdentImp", "headerName": "Num de ident. imp.",  "minWidth":  100, "flex": 1},
    { "name": "titular", "headerName": "Titular",  "minWidth":  230, "flex": 1},
    { "name": "estado", "headerName": "Estado", "minWidth":  130, "flex": 1},
    { "name": "options", "headerName": "", "minWidth":  70, "flex": 1, renderHeader: () => <MoreHorizIcon fontSize="large" color="action"/>},
]