import { GetOrdenesHistorial } from "../../interfaces/interfacesOrdenes/interfacesOrdenes";

export function formatEstado(estado: string) {
    let formatValue;
    switch (estado) {
        case 'Pendiente Operador': formatValue = 'EN PROCESO';
            break;
        case 'Recibida': formatValue = 'EN PROCESO';
            break;
        case 'PENDIENTE': formatValue = 'EN PROCESO';
            break;
        case 'NUEVO': formatValue = 'EN PROCESO';
            break;
        case 'Anulada': formatValue = 'CANCELADA';
            break;
        case 'Cumplida': formatValue = 'TERMINADA';
            break;
        case 'APROBADA': formatValue = 'TERMINADA';
            break;
        case 'RECHAZADO': formatValue = 'RECHAZADA';
            break;
        default:
            formatValue = estado?.toUpperCase();
        break;
    }
    return formatValue;
}

export const fechaReMapeada = (fecha:string) => {
    if (fecha){
        return `${fecha.substring(6,8)}/${fecha.substring(4,6)}/${fecha.substring(0,4)}`
    }
}

export function procesarOrdenesHistorial(data: GetOrdenesHistorial[] | undefined): GetOrdenesHistorial[] {
    function generateUniqueId(): string {
        return Math.random().toString(36)
    }

    if (!data || !Array.isArray(data)) {
        return [];
    }

    return data.map((item) => ({
        IdUnico: generateUniqueId(),
        estado: formatEstado(item.estado || ""), // Formatear estado, o "" si no viene
        tipoOrden: item.tipoOrden || "",
        especie: item.especie || "",
        hora: item.hora || "",
        fecha: item.fecha || "",
        cantidad: item.cantidad || "",
        precio: item.precio || "",
        importe: item.importe || "0",
        ticker: item.ticker || "",
        nroOrden: item.nroOrden || "",
        porcentajeEjercido: item.porcentajeEjercido ?? 0, // Si no viene, poner 0
        nroEspecie: item.nroEspecie || "",
        ejercido: item.ejercido ?? 0, // Si no viene, poner 0
        especieAmpliado: item.especieAmpliado || "",
        plazo: item.plazo === "Contado inmediato" ? "C. I." : item.plazo || "",
        vencimiento: item.vencimiento === "Permanente" ? ultimoDiaHabil(item.fecha) 
                    : item.vencimiento === "Diaria" ? item.fecha
                    : item.plazo === "24 hs" ? item.fecha
                    : ""
    }));
}

function ultimoDiaHabil(fechaStr: string): string {
    const [dia, mes, anio] = fechaStr.split('/').map(Number);

    // Crear una fecha del último día del mes
    const ultimoDiaMes = new Date(anio, mes, 0);

    // Iterar hacia atrás hasta encontrar un día hábil (lunes a viernes)
    while (ultimoDiaMes.getDay() === 0 || ultimoDiaMes.getDay() === 6) {
        ultimoDiaMes.setDate(ultimoDiaMes.getDate() - 1);
    }

    // Formatear la fecha en dd/mm/aaaa
    const diaHabil = String(ultimoDiaMes.getDate()).padStart(2, '0');
    const mesHabil = String(ultimoDiaMes.getMonth() + 1).padStart(2, '0');
    const anioHabil = String(ultimoDiaMes.getFullYear());

    return `${diaHabil}/${mesHabil}/${anioHabil}`;
}