import styled from "styled-components";

export const ContainerAzul = styled.div<{width: number, height: number}>`
    background-color: #00386E; 
    border-radius: 10px; 
    padding-top: ${(props) => props.height * 0.018}px;
    padding-left: ${(props) => props.width * 0.015}px;
    height: 6em;
`

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    height: 4em;
`

export const ContainerRigth = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
`

export const ContainerInfoYFecha = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 10em;
`

export const FechaTenencia = styled.p`
    color: white; 
    margin-top: 1em;
    font-size: 0.8em;
    max-width: 12em;
    width: 100%;
`

export const CuentaInfo = styled.p`
    color: white; 
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 4px;
    max-width: 12em;
    width: 100%;
`

export const Name = styled.p`
    font-size: 16px;
    margin-inline: 8px;
    margin-top: 2px;
    color: white;
    width: auto;
    height: 4em;
    min-width: 10em;
    max-width: 17em;
    white-space: pre-wrap;
    word-wrap: break-word;
`;

export const DialogoText = styled.p`
    margin: 6px;
    color: black; 
    font-weight: 400;
    font-size: 14px;
`


export const ContainerLeft = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-between;
    height: 3em;
`

export const ContainerTipoDeCuenta = styled.div`
    display: flex;
    flex-direction: column;
    margin-block: 1em;
    width: 100%;
    align-items: center;
    color: white;
`

export const TipoDeCuentaText = styled.h4`
    font-weight: 400;
`

export const ContainerNameYPaper = styled.div`
    display: flex;
    align-items: flex-start;
    height: 100%;
`