import { GetConciliacionesResp } from "../interfaces/interfaceConciliaciones/getConciliacionesInterface.js";
import { ProcesarConciliacionesResponse } from "../interfaces/interfaceConciliaciones/procesarConciliacionesInterface.js";
import { GetConciliacionesTitulosResp } from "../interfaces/interfaceConciliacionesTitulos/getConciliacionesTitulosInterface.js";
import { ProcesarConciliacionesTitulosResponse } from "../interfaces/interfaceConciliacionesTitulos/procesarConciliacionesInterface.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function procesarConciliaciones(csv: any, xls: any) {
	let data = new FormData();
	data.append("cajaDeValores", csv);
	data.append("dataGallo", xls);
	return await apiInstance.post<ProcesarConciliacionesResponse>("/procesarConciliaciones", data);
}

export async function getConciliaciones() {
	return await apiInstance.get<GetConciliacionesResp>("/conciliaciones");
}

export async function getConciliacionesTitulos() {
	return await apiInstance.get<GetConciliacionesTitulosResp>("/procesamientoTItulos");
}

export async function procesarConciliacionesTitulos(csv: any, xls: any, tipoProcesamiento: string) {
	let data = new FormData();
	data.append("cajaDeValores", csv);
	data.append("dataGallo", xls);
	data.append("tipoProcesamiento", tipoProcesamiento)
	return await apiInstance.post<ProcesarConciliacionesTitulosResponse>("/procesarTitulos", data,);
}