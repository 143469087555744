import { MouseEvent, useState } from "react"
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { roles } from "../../../helper/roles";
import ModalCambiarEstadoOrden from "../ModalCambiarEstadoOrden/ModalCambiarEstadoOrden";

// Menu que se usa en las tablas de ordenes exterior

interface Props {
    id: string, 
    cliente: string,
    especie: string,
    operacion: string,
    cantidad: string,
    precio: string,
    importe: string,
    market: boolean,
}

export const acciones = {
    operar: 'OPERAR',
    anular: 'ANULAR',
    cambiar: 'CAMBIAR',
} 

export default function MenuOrdenesExterior({id, cliente, especie, operacion, cantidad, precio, importe, market} : Props) {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openModalEstadoOrden, setOpenModalEstadoOrden] = useState(false);
    const handleOpenModalEstadoOrden = (accionSeleccionada : string) => { 
        setAccion(accionSeleccionada);
        setOpenModalEstadoOrden(true);
    };
    const [accion, setAccion] = useState<string>('');

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    color: "#004B6F",
                    textTransform: "capitalize",
                    fontSize: "1.1em",
                    height: 25,
                }}
            >
                <img
                    src={require("../../../images/tabla/puntos.png")}
                    alt="logo"
                />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem
                    onClick={() => handleOpenModalEstadoOrden(acciones.operar)}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "center",
                    }}
                >
                    Operar
                </MenuItem>
                <MenuItem
                    onClick={() => handleOpenModalEstadoOrden(acciones.anular)}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "center",
                    }}
                >
                    Anular
                </MenuItem>
                <MenuItem
                    onClick={() => handleOpenModalEstadoOrden(acciones.cambiar)}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "center",
                    }}
                >
                    Cambiar
                </MenuItem>
                <ModalCambiarEstadoOrden 
                    id={id}
                    open={openModalEstadoOrden}
                    setOpen={setOpenModalEstadoOrden}
                    cliente= {cliente}
                    especie= {especie}
                    operacion= {operacion}
                    cantidad= {cantidad}
                    precio= {precio}
                    importe= {importe}
                    accion={accion}
                    market={market}
                />
            </Menu>
        </div>
    );
}
