import { GetDatosPorFecha } from "../interfaces/interfConciliacionExteior/interfConciliacionExterior.js";
import apiInstance from "../utils/Axios.js";

export async function postConciliacionExterior (data:any) {

    const resp = await apiInstance.post("/conciliacionesExteriores", data);
    return resp.data.body.arrayTenencia
}

export async function getFechasDeConciliaciones () {
    const resp = await apiInstance.get("/conciliacionesExteriores/fechas");
    return resp
}

export async function getDatosPorFecha (id: string) {
    const resp = await apiInstance.get<GetDatosPorFecha>(`/conciliacionesExteriores/${id}`);
    return resp.data.body
}