import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles"
import { Autocomplete, Box, Button, TextField } from "@mui/material";

export const SecondContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-block: 1em;
    height: 5em;
    background-color: white;
`

export const StyledAutocomplete = muiStyled(Autocomplete)(({ theme }) => ({
    minHeight: "3em",
    backgroundColor: "white",
    marginLeft:"1em",
    lineHeight: "0px",
    "& .MuiAutocomplete-popupIndicator": {
        display: "none",
    },
    "& .MuiOutlinedInput-root": {
        paddingRight: "8px",
    },
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: "5px",
    border: "none",
    boxShadow: "2px 4px 8px rgba(0, 0, 0.1, 0.2)",
    outline: "none",
    height: "3em",
    "& .MuiOutlinedInput-notchedOutline": {
        height: "30px",
        border: "none",
    },
    "& .MuiFormLabel-root": {
        lineHeight: "30px",
    },
}))

export const DateContainer = styled.div`
    display: flex;
    align-items: center;
    width: 14em;
    position: relative;
    margin-right: 10px;
    height: 3em;
    z-index: 10;

    .react-datepicker__calendar-icon {
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #555;
    padding: 0;

    &:hover {
        color: #000;
    }
`;

export const InputDate = styled.input`
    width: 14em;
    height: 3em;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 4px 8px rgba(0, 0, 0.1, 0.2);
    outline: none;
    padding: 0 10px;
    display: flex;
    align-items: center;
`;

export const ButtonSearch = muiStyled(Button)(({ theme }) => ({
    display: 'flex',
    height: "100%",
    minWidth: "9em",
    backgroundColor: "#004B6F",
    color: "white",
    alignSelf:"flex-end",
    fontSize: "16px",
    marginInline: "1em",
    "&:hover": {
        backgroundColor: "#003855",
    },
}))