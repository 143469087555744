import { useCardEspecieOrdenesHook } from '../../hooks/useCardEspecieOrdenesHook'; 
import { MercadoContext } from "../../context/mercadoContext/MercadoContext"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { TipoMercado } from './enums';
import { useContext } from 'react';
import { AccionText, Button, ColumnContainer, ColumnData, ColumnTitles, Container, Divider, LoadingContainer,
    EstadoContainer, EstadoText, FirstColumn, HoraText, Img, NombreText, ProgressbarContainer, Row, SecondColumn, Text, TextData, TextRow, ThirdColumn } from "./elements"
import 'react-circular-progressbar/dist/styles.css';
import { formatCurrency } from '../../helper/formatCurrency';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { CircularProgress } from '@mui/material';
import { separacionDecimal } from '../../helper/separacionDecimal';
import { formatPorcentaje } from '../../helper/formatPorcentaje';

interface Props {
  estado: string;
  accion: string;
  nombre: string;
  hora: string;
  cantidad: string;
  precio: string;
  importe: string;
  nroOrden: string;
  porcentaje: number;
  ejercido: number;
}

export const CardEspecieOrdenes = ({ estado, accion, nombre, hora, cantidad, precio, importe, nroOrden, porcentaje, ejercido }: Props) => {
  
  const {
    formatTipoAccion,
    formatHour,
    esExterior,
    // cancelarOrden,
    loadingCancelarOrden,
  } = useCardEspecieOrdenesHook({ nombre, nroOrden });

  const { width } = useWindowDimensions();

  const { tipoMercado } = useContext(MercadoContext);

  function getSymbol(): string {
    return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
  }

  return (
    <Container>
      <Divider>
        {/* Primera columna TIPO/PORCENTAJE/HORA */}
        <FirstColumn>
          <AccionText>{formatTipoAccion(accion)}</AccionText>
          <ProgressbarContainer>
            <CircularProgressbar
              value={porcentaje}
              text={`${formatPorcentaje(porcentaje)}%`}
              styles={buildStyles({
                textColor: "#00386E",
                pathColor: estado === 'TERMINADA' ? '#03DAC5' : estado === 'EN PROCESO' || estado === 'PARCIAL' ? '#F6B40E' : 'rgba(60, 60, 67, 0.6)',
              })}
            />
          </ProgressbarContainer>
          <HoraText>{formatHour(hora)}</HoraText>
        </FirstColumn>

        {/* SEGUNDA COLUMNA ESPECIE/DATA ORDEN */}
        <SecondColumn>
          <Row>
            <NombreText>{nombre.endsWith('_US') ? nombre.slice(0, nombre.length - 3) : nombre}</NombreText>
            <Img
              src={
                esExterior()
                  ? require('../../images/banderas/bandera-usa.png')
                  : require('../../images/banderas/bandera-argentina-cuenta.png')
              }
            />
          </Row>
          <ColumnContainer>
            <>
              <ColumnTitles>
                <TextRow>
                  <Text>Cantidad</Text>
                  <TextData>{`${ejercido}/${cantidad}`}</TextData>
                </TextRow>

                <TextRow>
                  <Text>
                    Precio
                  </Text>
                  <TextData>
                    {nombre.includes('_US') 
                      ? 'USD ' + formatCurrency(parseFloat(precio).toFixed(2)) 
                      : '$ ' + formatCurrency(parseFloat(precio).toFixed(2))}
                  </TextData>
                </TextRow>

                <TextRow>
                  <Text>
                    Importe
                  </Text>
                  <TextData>
                  {nombre.includes('_US') 
                      ? 'USD ' + formatCurrency(parseFloat(importe).toFixed(2)) 
                      : '$ ' + formatCurrency(parseFloat(importe).toFixed(2))}
                  </TextData>
                </TextRow>

              </ColumnTitles>
            </>
          </ColumnContainer>
        </SecondColumn>
      </Divider>

      {/* TERCERA COLUMNA ESTADO */}
      <ThirdColumn>
        {loadingCancelarOrden
          ? <LoadingContainer style={{ height: '100%', marginTop: '1.5em' }}>
            <CircularProgress style={{ color: '#004B6F', width: '20px', height: '20px' }} />
          </LoadingContainer>
          : <div style={{ marginTop: '2em' }}>
            <EstadoContainer
              style={{
                backgroundColor: estado === 'EN PROCESO' || estado === 'PARCIAL' ? '#F6B40E' : 
                  estado === 'TERMINADA' ? '#03DAC5' : 
                  estado === 'CANCELADA' || estado === 'RECHAZADA'
                        // ? '#47AAFA' 
                        // :  estado === 'RECHAZADA' 
                    ? '#B00020' : 
                    'rgba(176, 0, 32, 0.38)',
              }}
            >
              <EstadoText
                style={{ color: estado === 'RECHAZADA'|| estado === "CANCELADA" ? 'white' : '#000000' }}
              >
                {estado === 'PARCIAL' ? 'EN PROCESO' : estado}
              </EstadoText>
            </EstadoContainer>
          </div>
        }
      </ThirdColumn>
    </Container>
  );
};
