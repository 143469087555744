import { Operaciones } from "../hooks/useOperarCarteras.js";
import { GetOrdenesBymaResponse } from "../interfaces/interfaceOperarCarteras/getOrdenesByma.js";
import { GetOrdenesCanceladasBymaResponse } from "../interfaces/interfaceOperarCarteras/getOrdenesCanceladasByma.js";
import { OperarCarteraBymaResponse } from "../interfaces/interfaceOperarCarteras/OperarCarterasByma.js";
import apiInstance from "../utils/Axios.js";

export async function operarCarteras(operaciones: Operaciones[]) {
	return await apiInstance.post("/operarCartera", { operaciones });
}

export async function operarCarterasByma(operaciones: Operaciones[],) {
	return await apiInstance.post<OperarCarteraBymaResponse>("/operarCarteraByma", { operaciones });
}
export async function getOrdenByma() {
	return await apiInstance.get<GetOrdenesBymaResponse>("/ordenByma");
}

export async function cancelarOrdenByma(params: any) {
	return await apiInstance.post("/cancelarOrdenByma", { params });
}

export async function getOrderCanceled() {
	return await apiInstance.get<GetOrdenesCanceladasBymaResponse>("/OrderCanceled");
}

export async function getOrdenesGallo() {
	return await apiInstance.get("/ordenesGallo");
}

export async function cancelarOrdenGallo(params: any) {
	return await apiInstance.post("/cancelarOrdenGallo", params );
}

export async function stopOrdenesByma() {
	return await apiInstance.post("/stopProcessingByma");
}