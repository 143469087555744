import { GetOrdenesHistorial } from "../interfaces/interfacesOrdenes/interfacesOrdenes.js";
import apiInstance from "../utils/Axios.js";

export async function getHistorialOrdenes(accountNumber: string){
	
	let nroCuenta = Number(accountNumber)

	const resp:any = await apiInstance.get<GetOrdenesHistorial>(`/OrdersAccountNumber?accountNumber=${nroCuenta}`);

	const data = resp.data.body.orders
	
	return data
}

export async function sendDDJJ(cuenta: string) {
    const cuentaParseada: number = Number(cuenta)

	return await apiInstance.post(`/sendEmailDDJJ`, cuentaParseada)
}