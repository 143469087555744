import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Icon } from '@iconify/react';
import { TableColumn } from 'react-data-table-component';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { DownloadButton, DataBox, InputSearch, SearchButton, SearchContainer, Sub, SubContainer } from "./elements";
import { baseURL } from "../../utils/Axios";
import { useState } from "react";
import useTableBlanqueoLiquidez from "../../hooks/useTableBlanqueoLiquidez";

// Tabla que se renderiza en las diferentes screens

interface Props {
    subtitulo: string,
    columns: TableColumn<any>[],
}

export default function TableBlanqueo({ subtitulo, columns }: Props) {
    const { width, height } = useWindowDimensions();
    const {
        blanqueoDataFilter,
        handleInput,
        paginationOpciones,
        loading,
        filtro
    } = useTableBlanqueoLiquidez();

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: subtitulo === "PEDIDOS DOLARES TENENCIA" ? '6em' : height * 0.75,
                maxHeight: "33em",
                // minWidh: "30em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
                // borderRadius: "20px"
            },
        },

        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "58px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
                //wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            },
        },
    };

    let altura = subtitulo === "PEDIDOS DOLARES TENENCIA" ?
        `${height * 0.22}px`
        : `${height * 0.75}px`;


    let widthContainer = subtitulo !== "PEDIDOS DOLARES TENENCIA" ? width * 0.84 : "85%"
    let heightContainer = subtitulo !== "PEDIDOS DOLARES TENENCIA" ? height * 0.85 : undefined


    return (
        <div style={{ width: widthContainer, height: heightContainer }}>

            <DataBox>
                <SubContainer>
                    <Sub>{subtitulo}</Sub>
                </SubContainer>
                <div style={{ display: "flex" }}>

                    <SearchContainer>
                        <InputSearch
                            type="text"
                            placeholder="Buscar..."
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
                            value={filtro}
                        />
                        <SearchButton
                            type="submit"
                        >
                            <img
                                src={require("../../images/tabla/search-icon.png")}
                                alt="logo"
                                style={{ width: "1em" }}
                            />
                        </SearchButton>
                    </SearchContainer>
                </div>
            </DataBox>


            <DataTable
                columns={columns}
                data={blanqueoDataFilter!}
                pagination={true}
                paginationPerPage={25}
                paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                paginationComponentOptions={paginationOpciones}
                fixedHeader
                fixedHeaderScrollHeight={altura}
                // minHeight={altura}
                customStyles={customStyles}
                noDataComponent={
                    loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1em" }}>
                            <CircularProgress style={{ color: "#004B6F" }} />
                        </Box>
                    ) : (
                        <>No se encontró ningún elemento</>
                    )
                }
                responsive
                keyField="id"
            />
        </div>
    );
}


const styles = {
    links: {
        textDecoration: "none",
        cursor: "pointer",
        marginRight: "2em",
        marginTop: "0.25em",
    },
    menu: {
        display: "flex",
        backgroundColor: "white",
        height: "2.2em",
        borderRadius: "14px",
        padding: 0,
        margin: 0,
    },
    listItem: {
        display: "flex",
        padding: "0.1em 0.5em 0.1em 0.5em",
        margin: 0,
        fontSize: "0.9em",
        cursor: "pointer",
    },
    menuItem: {
        fontSize: "0.9em",
        backgroundColor: "white",
        color: "rgba(0, 75, 111, 0.75)",
    },
    plusIcon: {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
    logo: {
        fontSize: "1.7em",
        color: "rgba(85, 86, 88, 0.75)",
        margin: "0.2em",
    },
}