import { useState, useEffect } from "react";
import {
	cancelarOrdenByma,
	getOrdenByma,
	getOrderCanceled,
} from "../api/operar_cartera";
import { EstadoCarteraButton, OrdenCancel } from "../components/EstadoCarteraButton/EstadoCarteraButton";
import { AxiosResponse } from "axios";
import { SelectChangeEvent } from '@mui/material/Select';
import { GetOrdenesBymaResponse, OrdStatus, ResultGetOrdenesByma } from "../interfaces/interfaceOperarCarteras/getOrdenesByma";
import { GetOrdenesCanceladasBymaResponse, ResultGetOrdenesCanceladasByma } from "../interfaces/interfaceOperarCarteras/getOrdenesCanceladasByma";

interface Orden {
	fecha: string,
	created: string,
	cliente: string,
	operacion: string,
	especie: string,
	cantidad: number,
	precio: number,
	estado: JSX.Element,
	estadoFilt: string,
	operador: string | null,
}

const arrMenuList = [

	"En proceso",

	"Terminada",

	"Rechazada",

	"Cancelada",

	"Caucion",

	"Martin Rava",

	"Andres Rava",
];

export const useOrdenesCartera = () => {
	const [ordenes, setOrdenes] = useState<Orden[]>();
	const [ordenesFilt, setOrdenesFilt] = useState<Orden[]>();
	const [actualMenuEstado, setActualMenuEstado] = useState<string>("Todas");
	const [loading, setLoading] = useState<boolean>(false);
	const [filtroActual, setFiltroActual] = useState<string[]>([]);

	const handleChange = (event: SelectChangeEvent<typeof filtroActual>) => {
		const {
			target: { value },
		} = event;
		// Obtenemos los valores seleccionados
		let selectedValues = typeof value === 'string' ? value.split(',') : value;

		setFiltroActual(selectedValues);

		// Filtrar las órdenes de acuerdo a los estados seleccionados
		if (selectedValues.length === 0 ) {
			// Mostrar todas si no hay filtros
			setOrdenesFilt(ordenes);
		} else {
			// Filtrar las órdenes de acuerdo a los filtros seleccionados
			const filtered = ordenes?.filter((e) => {

				const estados = ["En proceso", "Terminada", "Rechazada", "Cancelada"];
				const estadoFiltrado = e.estadoFilt?.toLowerCase();
				const caucionSeleccionada = selectedValues.includes("Caucion");
				const estadoSeleccionado = selectedValues.some(value => estados.includes(value));
				const operadorSeleccionado = selectedValues.includes("Andres Rava") || selectedValues.includes("Martin Rava");

				// Filtro acumulativo por estado
				const filtroPorEstado = estadoSeleccionado && selectedValues.some((estado) => estado.toLowerCase() === estadoFiltrado);

				// Filtro por "Caucion" 
				const filtroPorCaucion = caucionSeleccionada && e.especie?.toLowerCase()?.startsWith("pesos");

				// Filtro por operador
				const filtroPorOperador = operadorSeleccionado && e.operador && selectedValues.some((operador) => 
					(operador === "Andres Rava" && e.operador === "Andres Rava") || 
					(operador === "Martin Rava" && e.operador === "Martin Rava")
				);
		
				if (caucionSeleccionada && estadoSeleccionado && operadorSeleccionado) {
					// Filtro por caucion, estado y operador
					return filtroPorEstado && filtroPorCaucion && filtroPorOperador;
				} else if (caucionSeleccionada && estadoSeleccionado) {
					// Filtro por caucion y estado
					return filtroPorEstado && filtroPorCaucion;
				} else if (caucionSeleccionada && operadorSeleccionado) {
					// Filtro por caucion y operador
					return filtroPorCaucion && filtroPorOperador;
				} else if (estadoSeleccionado && operadorSeleccionado) {
					// Filtro por estado y operador
					return filtroPorEstado && filtroPorOperador;
				} else {
					// Filtro por caucion, estado o operador depende el seleccionado
					return filtroPorEstado || filtroPorCaucion || filtroPorOperador;
				}
			});
			setOrdenesFilt(filtered);
		}
	};

	useEffect(() => {
		getOrdenes();
	}, []);

	const filterOrder = (e: ResultGetOrdenesByma, cancelledOrders: ResultGetOrdenesCanceladasByma[]) => {
		for (let i = 0; i < cancelledOrders.length; i++) {
			if (e.OrderID === cancelledOrders[i].OrderID) {
				return false;
			} else {
				return true;
			}
		}
	};

	const getOrdenes = async () => {

		setLoading(true);
		
		const res: AxiosResponse<GetOrdenesBymaResponse> = await getOrdenByma();
		const resCanceled: AxiosResponse<GetOrdenesCanceladasBymaResponse> = await getOrderCanceled();

		const orders = res?.data?.body?.result;
		const cancelledOrders = resCanceled?.data?.body?.result;

		// const filteredOrders = orders
		const filteredOrders = orders.filter((e) =>
			filterOrder(e, cancelledOrders)
		);

		// console.log('FILT', filteredOrders)

		const allOrders = [...filteredOrders, ...cancelledOrders];
		// console.log(allOrders)

		// console.log('RESCANCELED', resCanceled?.data?.body?.result)
		// console.log(res?.data?.body?.result);

		if (res?.data?.body?.result) {
			const filtData = res?.data?.body?.result.map((e) => ({
				fecha: `${e.createdAt.slice(8, 10)}-${e.createdAt.slice(5, 7)}-${e.createdAt.slice(0, 4)}`,
				created:e.createdAt,
				cliente: e.Account,
				operacion: e.Side === "1" ? "C" : "V",
				especie: e.SecurityID,
				cantidad: e.OrderQty,
				precio: e.Price,
				estado: (
					<EstadoCarteraButton
						cancelarOrden={cancelarOrden}
						estado={setEstado(e.OrdStatus)}
						orderCancel={{OrigClOrdID: e.ClOrdID, OrderID: e.OrderID, Symbol: e.Symbol, SecurityID: e.SecurityID, Side: e.Side}}
					/>
				),
				estadoFilt: setEstado(e.OrdStatus),
				operador: e.OperatorUser ? e.OperatorUser : null,
			}));

			setOrdenes(filtData.reverse());
			setOrdenesFilt(filtData.reverse());
			setLoading(false);
		}
	};

	const setEstado = (estado : string) => {
		let aux: string;
		if(estado === OrdStatus.Nuevo || estado === OrdStatus.ParcialmenteTerminada || estado === OrdStatus.PendingNew || estado == OrdStatus.RemplazoPendiente){
			aux = 'EN PROCESO'
		} else if(estado === OrdStatus.Rechazado){
			aux = 'RECHAZADA'
		} else if(estado === OrdStatus.Cancelada || estado === OrdStatus.Suspendido || estado === OrdStatus.Expirado){
			aux = 'CANCELADA'
		} else if(estado === OrdStatus.Terminada){
			aux = 'TERMINADA'
		} else {
			aux = estado;
		}
		return aux;
	}
	const cancelarOrden = async (orden: OrdenCancel) => {
		const res = await cancelarOrdenByma(orden);
		// console.log("cancelarRes", res);
		getOrdenes();
	};

	const [listaMenuEstado, setlistaMenuEstado] = useState("Todas");

	const [openEstado, setOpenEstado] = useState<HTMLElement | null>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleOpenEstado = (event: React.MouseEvent<HTMLElement>) => {
		setOpenEstado(event.currentTarget);
	};

	const handleClickEstado = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setSelectedIndex(index);
		setlistaMenuEstado(arrMenuList[index]);
		filtPorEstado(arrMenuList[index]);
		setOpenEstado(null);
	};

	const handleCloseEstado = () => {
		setOpenEstado(null);
	};

	const filtPorEstado = (estado: string) => {
		setActualMenuEstado(estado);
		estado = estado.toLowerCase();

		if (estado === "todas") {
			setOrdenesFilt(ordenes);
		} else {
			let filtered = ordenes?.filter((e) =>
				e.estadoFilt?.toLowerCase().includes(estado)
			);

			setOrdenesFilt(filtered);
		}
	};

	return {
		ordenesFilt,
		openEstado,
		handleCloseEstado,
		handleOpenEstado,
		handleClickEstado,
		selectedIndex,
		arrMenuList,
		loading,
		filtroActual,
		handleChange,
	};
};
