import { useState, ChangeEvent } from "react";
import useBlanqueoHook from "./useBlanqueoHook";


export default function useTableBlanqueoLiquidez() {
	const [loading, setLoading] = useState(true);
	const [filtro, setFiltro] = useState("")
	const {
		blanqueoDataFilter
	} = useBlanqueoHook({ setLoading, filtro });


	//se llama a las funciones que hacen las peticiones al montarse el componente

	const paginationOpciones = {
		rowsPerPageText: "Filas por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos",
	};

	function handleInput(e: ChangeEvent<HTMLInputElement>) {
		setFiltro(e.target.value);
	}

	return {
		blanqueoDataFilter,
		handleInput,
		paginationOpciones,
		loading,
		filtro
	};
}
