import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { TransferenciasGenArchivo, TransferenciasGenArchivoLiqExt } from '../../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos';
import { SelectRowStyle, TextRowStyle } from './elementsMui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TransferenciasTotales } from '../../hooks/useGenerarArchivo';
import debounce from "lodash/debounce"

const paginationModel = { page: 0, pageSize: 10 };

const TextRow = React.memo((
    { value, onChange, params }:
        { value: string; onChange: (value: string) => void; params: GridRenderCellParams<TransferenciasTotales> }
) => {
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => { setLocalValue(value) }, [value])

    const debouncedOnChange = useMemo(() => debounce((value: string) => onChange(value), 250), [onChange])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setLocalValue(newValue)
        debouncedOnChange(newValue)
    }

    return (
        <TextRowStyle
            variant="outlined"
            size="small"
            value={localValue}
            onChange={handleChange}
            fullWidth
        />
    )
})

const SelectRow = React.memo((
    { value, onChange }:
        { value: string; onChange: (event: SelectChangeEvent<unknown>) => void }
) => (
    <SelectRowStyle
        value={value}
        onChange={onChange}
        variant="outlined"
        size="small"
        fullWidth
    >
        <MenuItem value="DELIVER">DELIVER</MenuItem>
        <MenuItem value="RECEIVE">RECEIVE</MenuItem>
    </SelectRowStyle>
),
)

interface PropsTableGenerarArchivo {
    tipoTransferencias: string;
    data: TransferenciasGenArchivo[] | TransferenciasGenArchivoLiqExt[]
    setData: React.Dispatch<React.SetStateAction<TransferenciasGenArchivo[] | TransferenciasGenArchivoLiqExt[]>>
    setSeleccionados: React.Dispatch<React.SetStateAction<string[]>>
}

export const TableGenerarArchivo = ({ data, setData, setSeleccionados, tipoTransferencias }: PropsTableGenerarArchivo) => {

    let editableFields: string[] = ['origen', 'destino', 'tipoMovimiento', 'instrumento', 'cantidad', 'fecha'];

    if (tipoTransferencias === "LIQUIDACIÓN EXTERIOR") {
        data = data.filter((obj: TransferenciasTotales) =>
            obj.tipoTransferencias === "LIQUIDACIÓN EXTERIOR"
        );
        editableFields.splice(3, 0, "agenteDestino");
    } else {
        data = data.filter((obj: TransferenciasTotales) =>
            obj.tipoTransferencias !== "LIQUIDACIÓN EXTERIOR"
        );
    }

    const handleInputChange = useCallback((
        value: string,
        params: GridRenderCellParams<TransferenciasTotales>
    ) => {
        setData((filasPrevias) =>
            filasPrevias.map((fila) => (
                fila._id === params.id ?
                    { ...fila, [params.field]: value }
                    : fila))
        )
    }, [setData])

    const columns: GridColDef<TransferenciasGenArchivo>[] = editableFields.map((field) => ({
        field,
        headerName: returnHeaderName(field),
        flex: 1,
        renderCell: (params) => (
            field !== "tipoMovimiento" ?
                <TextRow
                    value={params.row[field as keyof TransferenciasTotales]?.toString() || ""}
                    onChange={(value) => handleInputChange(value, params)}
                    params={params}
                />
                :
                <SelectRow
                    value={params.row[field]?.toString() || ""}
                    onChange={(event) => handleInputChange(event.target.value as string, params)}
                />
        )
    }
    ));

    function returnHeaderName(field: string) {
        switch (field) {
            case "origen":
                return "CUENTA DE ORIGEN"
            case "destino":
                return "CUENTA DESTINO"
            case "tipoMovimiento":
                return "TIPO DE MOVIMIENTO"
            case "agenteDestino":
                return "AGENTE DESTINO"
            default:
                return field.toUpperCase()
        }
    }

    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        setSeleccionados(selectionModel.map((id: GridRowId) => id.toString()))
    };

    return (
        <div style={{ width: "100%", paddingLeft: 8, paddingRight: 8 }}>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
                getRowId={(row) => row._id}
                density='compact'
                disableAutosize={true}
                disableColumnFilter={true}
                disableColumnResize={true}
                disableColumnMenu={true}
                disableRowSelectionOnClick={true}
                onRowSelectionModelChange={itm => handleRowSelection(itm)}
            />
        </div>
    );
};