import React from 'react'
import { ContainerButtons, Container, TextoBotones } from "./elements";

interface Props {
    setBotonActual: React.Dispatch<React.SetStateAction<string>> | ((opcion: string) => void);
    botonActual: string;
    listaBotones: string[];
}

const BotonesPanelesNuevo = ({ setBotonActual, botonActual, listaBotones }: Props) => {

    return (
        <Container>
            {listaBotones.map(l =>
                <ContainerButtons
                    key={l}
                    onClick={() => setBotonActual(l)}
                    style={{
                        borderBottom: botonActual === l ? `3px solid #00386E` : "none",
                    }}
                >
                    <TextoBotones
                        style={{
                            fontWeight: botonActual === l ? 500 : 400,
                            color: botonActual === l ? '#00386E' : "gray",
                        }}
                    >
                        {l}
                    </TextoBotones>
                </ContainerButtons>
            )
            }
        </Container >
    )
}

export default BotonesPanelesNuevo