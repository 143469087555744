import styled from "styled-components";

export const Container = styled.div<{ width: number; height: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width * 0.85}px;
    left: ${(props) => props.width * 0.15}px;
    top: ${(props) => props.height * 0.062}px;
`

export const SecondContainer = styled.div`
    height: 100%;
    display: flex; 
    flex-direction: column;
`;

export const CardsInfoUserContainer = styled.div`
    margin: 1em 0em 0em 3.8em;
    width: 85%;
`;

export const ContainerError = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 80%;
`;

export const TextoError = styled.p`
    font-size: 1.5em;
    font-weight: bold;
`;

export const ContainerLoading = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 100%;
`;

export const ListContainer = styled.div`
    overflow-y: scroll;
    padding-bottom: 1em;
    height: 58vh;
`;

export const SinOrdenesContainer = styled.div`
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SinOrdenesText = styled.p`
    font-size: 1.3em;
`; 

export const ErrorText = styled.p`
    font-size: 1em;
    text-align: center;
    margin-bottom: 5em;
`
