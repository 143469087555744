import React, { ChangeEvent, FormEvent } from 'react'
import { SearchButton, SearchContainer, SearchContainerSinInput, Subtitulo, SubtituloContainer } from './elements'

interface Props {
    handleInputHistorial: (e: ChangeEvent<HTMLInputElement>) => void,
    handleDoble: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    handleSubmitHistorial: (e: FormEvent) => void,
}

export const BuscadorNroCuenta = ({handleInputHistorial, handleDoble, handleSubmitHistorial}:Props) => {

    return (
        <div>
            <SubtituloContainer>
                <Subtitulo>
                    BUSCÁ EL NÚMERO DE CUENTA DEL CLIENTE/EMPRESA
                </Subtitulo>
            </SubtituloContainer>
            <SearchContainerSinInput>
                <SearchContainer
                    type="text"
                    placeholder="Buscar..."
                    onChange={(e) => handleInputHistorial(e)}
                    onKeyDown={(e) => handleDoble(e)}
                />
                <SearchButton
                    type="submit"
                    onClick={(e) => handleSubmitHistorial(e)}
                >
                    <img
                        src={require("../../images/tabla/search-icon.png")}
                        alt="logo"
                        style={{ width: "1em" }}
                    />
                </SearchButton>
            </SearchContainerSinInput>
        </div>
    )
}