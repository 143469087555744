import useWindowDimensions from "../../hooks/useWindowDimensions"
import { DataBoxTable, TableContainer, MensajeError, ButtonCloseAlert, ErrorContainer, Container, ContainerRight, ButtonUpload, RowContainerBetween, Select, Subtitulo, customStyles } from "./elements"
import DataTable from "react-data-table-component"
import { useConciliacionExterior } from "../../hooks/useConciliacionesExterior"
import { columnsConciliacionesExterior } from "../../helper/Colums/columnsConciliacionesExterior";
import { CircularProgress } from "@mui/material";
import { ConciliacionExteriorFilter } from "../../components/ConciliacionesExteriorFilter/ConciliacionesExteriorFilter";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
export const ConciliacionExterior = (  ) => {

    const { width, height } = useWindowDimensions()
    let altura = `${height * 0.75}px`;

    const {
        fechasPosibles,
        startDate, setStartDate,
        concilExtFiltInteractive,
        concilExtFiltSchwab,
        especies,
        loading,
        fileInputRef,
        tipoBroker,
        handleSearch,
        handleUpload,
        handleChangeTipoBroker,
        getEspecie,
        setEspecie,
        error,
        setError
    } = useConciliacionExterior()

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
            fileInputRef.current?.click();
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            const adjustedDate = new Date(date);
            adjustedDate.setHours(12, 0, 0, 0);
            setStartDate(adjustedDate);
        } else {
            setStartDate(null);
        }
    };

    const handleClearDate = () => { setStartDate(null) };

    return(
        <Container width={width} height={height}>
            <RowContainerBetween height={height}>
                <Subtitulo>CONCILIACIÓN EXTERIOR</Subtitulo>
                <ContainerRight width={width}>
                {error && (
                    <ErrorContainer>
                        <ErrorRoundedIcon style={{ color: "#c80000", marginRight: "5px" }} />
                            <MensajeError>El archivo no es correcto</MensajeError>
                        <ButtonCloseAlert onClick={() => setError(false)} >✖</ButtonCloseAlert>
                    </ErrorContainer>
                    )
                }
                    <ButtonUpload 
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleButtonClick}
                    >
                        SUBIR ARCHIVO
                    </ButtonUpload>
                    <input
                        type="file"
                        accept=".csv" 
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                    />
                    <Select
                        name="tiposBrokers"
                        value={tipoBroker}
                        onChange={(e) => handleChangeTipoBroker(e)}
                    >
                        <option value='INTERACTIVE'>
                            INTERACTIVE
                        </option>
                        <option value="SCHWAB">
                            SCHWAB
                        </option>
                    </Select>
                </ContainerRight>
            </RowContainerBetween>

            <ConciliacionExteriorFilter
                handleSearch={handleSearch}
                fechasPosibles={fechasPosibles}
                tipoBroker={tipoBroker}
                handleDateChange={handleDateChange}
                handleClearDate={handleClearDate}
                getEspecie={getEspecie}
                especies={especies}
                setEspecie={setEspecie}
                startDate={startDate}
            />

            <TableContainer> 
                <DataTable
                    columns={columnsConciliacionesExterior}
                    data={tipoBroker === "INTERACTIVE" ? concilExtFiltInteractive : concilExtFiltSchwab}
                    pagination
                    paginationPerPage={25}
                    customStyles={ customStyles(height) }
                    responsive
                    fixedHeader
                    keyField="id"
                    fixedHeaderScrollHeight={altura}
                    noDataComponent={
                        loading ? (
                            <DataBoxTable>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </DataBoxTable>
                        ) : (
                            <p style={{ marginTop: "2em" }}>No se encontró ningún elemento</p>
                        )
                    }
                />
            </TableContainer>
        </Container>
    )
}