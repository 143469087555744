import { utils, WorkSheet } from "xlsx";

export const ec = (r: number, c: number): string => {
    return utils.encode_cell({ r, c });
}

export const deleteRow = (ws: WorkSheet, rowIndex: number): void => {
    const range = utils.decode_range(ws["!ref"] || "A1"); // Obtiene el rango de celdas
    for (let R = rowIndex; R < range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            ws[ec(R, C)] = ws[ec(R + 1, C)];
        }
    }
    range.e.r--; // Reduce el rango de filas
    ws['!ref'] = utils.encode_range(range.s, range.e); // Actualiza el rango en la hoja de trabajo
}

export const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11, así que sumamos 1
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
}
