
import { TableColumn } from 'react-data-table-component';
import { RowConciliacionesTitulosTable } from '../../interfaces/interfacesRows/rows';
import { diferenciasGalloCaja, formatCurrency } from '../formatCurrency';

const errorColor = "#FFBABA";

const columnsConciliacionesTitulosTable: TableColumn<RowConciliacionesTitulosTable>[] = [

    {
        selector: (row: RowConciliacionesTitulosTable) => row["instrumento"] ? row["instrumento"] : "",
        name: "Instrumento",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTitulosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowConciliacionesTitulosTable) => row["accountNumber_Gallo"] ? row["accountNumber_Gallo"] : row["accountNumber_Caja"],
        name: "Comitente",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTitulosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowConciliacionesTitulosTable) => row["nombreTitular"] ? row["nombreTitular"] : "",
        name: "Nombre titular",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTitulosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowConciliacionesTitulosTable) => row["saldo_Caja"] === "" ? "" : formatCurrency(row["saldo_Caja"]),
        name: "Saldo en caja",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTitulosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowConciliacionesTitulosTable) => row["saldo_Gallo"] === "" ? "" : formatCurrency(row["saldo_Gallo"]),
        name: "Saldo en gallo",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowConciliacionesTitulosTable) => row["saldo_Gallo"] === "" ? "" : diferenciasGalloCaja(row["saldo_Caja"], row["saldo_Gallo"]),
        name: "Diferencia",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
];

export {
    columnsConciliacionesTitulosTable
};
