import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTenenciaHook } from "../../hooks/useTenenciaHook";
import { Container, SecondContainer, CardsInfoUserContainer, ContainerError, TextoError, ContainerLoading, 
    SinOrdenesContainer, SinOrdenesText, ErrorText, ListContainer } from "./elements";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom"
import { useHistorialOrdenesHook } from "../../hooks/useHistorialOrdenesHook";
import { CardEspecieOrdenes } from "../../components/CardEspecieOrdenes/CardEspecieOrdenes";
import BotonesPanelesNuevo from "../../components/BotonesPanelesNuevo/BotonesPanelesNuevo";
import { HistorialOrdenesFilter } from "../../components/HistorialOrdenesFilter/HistorialOrdenesFilter";
import { BuscadorNroCuenta } from "../../components/BuscadorNroCuenta/BuscadorNroCuenta";
import { CardInfoUser } from "../../components/TenenciaComponents/CardInfoUser/CardInfoUser";

export const HistorialOrdenes = () => {
    const { width, height } = useWindowDimensions()
    const location = useLocation()
    const navigate = useNavigate()
    const nroCuentaNavigate: string = location.state?.nroCuentaNavigate;

    const {
        ordenesFilt,
        loadingHistorial,
        infoCuenta,
        nroCuentaCliente,
        error,
        setLoadingHistorial,
        setActualMenuEstado,
        actualMenuEstado,
        formatEstado,
        errorOrders,
        loadingOrdenes,
        especie,
        setEspecie,
        especies,
        handleDownloadPDF,
        startDate,
        setStartDate,
        handleEffectHistorial,
        handleSubmit
    } = useHistorialOrdenesHook();

    const {
        handleKeyDownHistorial,
        handleInputHistorial,
        handleSubmitHistorial,
    } = useTenenciaHook();

    useEffect(() => {
        if (location.pathname === "/Historial-ordenes" && !nroCuentaNavigate) {
            navigate("/Tenencia")
        } else if (nroCuentaNavigate) {
            handleEffectHistorial(nroCuentaNavigate)
        }
    }, [location.pathname, nroCuentaNavigate, navigate]);
    
    const isLoading = loadingHistorial || loadingOrdenes
    
    const handleChangeDate = (fecha: Date | null) => {
        if (fecha) {
            setStartDate(fecha)
        }
    }
    
    const submitDownloadPDF = () => {
        if (infoCuenta) {
            handleDownloadPDF(nroCuentaNavigate, infoCuenta.userName, startDate)
        }
    }

    const handleDoble = (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleKeyDownHistorial(e); 
        setLoadingHistorial(true);          
    };

    return (
        <Container width={width} height={height}>
            <>
                <BuscadorNroCuenta
                    handleInputHistorial={handleInputHistorial}
                    handleDoble={handleDoble}
                    handleSubmitHistorial={handleSubmitHistorial}
                />
            </>
            { isLoading ? ( 
                <ContainerLoading>
                    <CircularProgress style={{ color: "#004B6F", marginTop: "3em" }} />
                </ContainerLoading>
            ) : (
                <>
                    {infoCuenta ? (
                            <SecondContainer>
                                <CardsInfoUserContainer>
                                    <CardInfoUser
                                        userName={infoCuenta.userName}
                                        nroCuenta={nroCuentaCliente}
                                    />
                                </CardsInfoUserContainer>
                            </SecondContainer>
                        ) : error.length ? (
                            <ContainerError>
                                <TextoError>{error}</TextoError>
                            </ContainerError>
                        ) : null
                    }
                    <HistorialOrdenesFilter
                        startDate={startDate}
                        handleChangeDate={handleChangeDate}
                        especie={especie}
                        especies={especies}
                        setStartDate={setStartDate}
                        setEspecie={setEspecie}
                        handleSubmit={handleSubmit}
                        submitDownloadPDF={submitDownloadPDF}
                        nroCuentaNavigate={nroCuentaNavigate}
                    />
                    <BotonesPanelesNuevo
                        setBotonActual={setActualMenuEstado}
                        botonActual={actualMenuEstado}
                        listaBotones={["Todas", "En proceso", "Terminada", "Rechazada", "Cancelada"]}
                    />
                    { errorOrders ? (
                        <SinOrdenesContainer>
                            <ErrorText>
                                No podemos mostrar tus órdenes en este momento.
                                Comunicate con tu asesor/a.
                            </ErrorText>
                        </SinOrdenesContainer>
                    ) : ordenesFilt.length === 0 ? (
                        <SinOrdenesContainer>
                            <SinOrdenesText>No hay órdenes</SinOrdenesText>
                        </SinOrdenesContainer>
                    ) : (
                        <>
                            <ListContainer>
                                {ordenesFilt.map((e) => (
                                    <CardEspecieOrdenes
                                        key={e.IdUnico}
                                        estado={formatEstado(e.estado)}
                                        accion={e.tipoOrden}
                                        nombre={e.especie}
                                        hora={e.hora}
                                        cantidad={e.cantidad}
                                        precio={e.precio}
                                        importe={e.importe}
                                        nroOrden={e.nroOrden}
                                        porcentaje={e.porcentajeEjercido}
                                        ejercido={e.ejercido}
                                    />
                                ))}
                            </ListContainer>
                        </>
                    )}
                </>
            )}
        </Container>
    );
}