import { styled } from "@mui/material/styles"
import { Button, TextField, Select, Autocomplete } from "@mui/material"

export const ButtonBuscar = styled(Button)(({ theme }) => ({
    height: "100%",
    width: "9%",
    backgroundColor: "#004B6F",
    marginLeft: "1%",
    color: "white",
    "&:hover": {
        backgroundColor: "#003855",
    },
}))

export const ButtonDownload = styled(Button)(({ theme }) => ({
    padding: "0px",
    margin: "0px",
    minWidth: "0px",
    height: "100%",
    fontSize: "24px",
    marginLeft: `16px`,
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: "5px",
    border: "none",
    boxShadow: "2px 4px 8px rgba(0, 0, 0.1, 0.2)",
    outline: "none",
    height: "32px",
    "& .MuiOutlinedInput-notchedOutline": {
        height: "30px",
        border: "none",
    },
    "& .MuiFormLabel-root": {
        lineHeight: "16px",
    },
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: "30%",
    marginRight: "1%",
}))

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    "& .MuiAutocomplete-popupIndicator": {
        display: "none",
    },
    "& .MuiOutlinedInput-root": {
        paddingRight: "8px",
    },
}))