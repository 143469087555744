import { GetGaraResponse } from "../interfaces/interfaceGara/getGara.js";
import { ProcesarGaraResponse } from "../interfaces/interfaceGara/procesarGara.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function procesarGara(csv: any) {
	let data = new FormData();
	data.append("archivoGara", csv);
	return await apiInstance.post<ProcesarGaraResponse>("/procesarDataGara", data);
}

export async function getGara() {
	return await apiInstance.get<GetGaraResponse>("/dataGara");
}
