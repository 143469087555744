import { type ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { getFechasDeConciliaciones, getDatosPorFecha, postConciliacionExterior } from "../api/conciliacionExterior";
import * as XLSX from "xlsx";
import { useInteractiveBroker, useSchwabBroker, extraccionDeFechas, loadLatestData } from "../helper/helperConciliacionesExteriores";
import { ConciliacionesTotales, EspeciesTotales, FechaBroker } from "../interfaces/interfConciliacionExteior/interfConciliacionExterior";

export const useConciliacionExterior = () => {

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [fechasPosibles, setFechasPosibles] = useState<FechaBroker>();
    const [allDocumentId, setAllDocumentId] = useState<[]>([]);
    const [tipoBroker, setTipoBroker] = useState<string>("INTERACTIVE");
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [concilExtTotal, setConcilExtTotal] = useState<ConciliacionesTotales>({
        conciliacionesSchwab: [],
        conciliacionesInteractive: [],
    });
    const [especies, setEspecies] = useState<EspeciesTotales>({
        especiesSchwab: [],
        especiesInteractive: []
    });
    const [especieSchwab, setEspecieSchwab ] = useState("");
    const [especieInteractive, setEspecieInteractive] = useState("");
    const [ error, setError] = useState<boolean>(false)

    const { processSchwabData } = useSchwabBroker();
    const { processInteractiveData } = useInteractiveBroker();

    useEffect(() => {
        cargaDeFechas();
    }, []);

    const cargaDeFechas = async () => {
        try {
            const resp = await getFechasDeConciliaciones();

            if (
                !resp ||
                !resp.data ||
                !Array.isArray(resp.data.body) ||
                resp.data.body.length === 0
            ) {
                console.warn("No se recibieron datos válidos de conciliaciones.");
                setFechasPosibles({ fechasSchwab: [], fechasInteractive: [] });
                return;
            }

            const documentosModificados = resp.data.body.map((e: any) => {
                const [dia, mes, año] = e.fecha.split("/");
                const fechaObjeto = new Date(Number(año), Number(mes) - 1, Number(dia));

                return {
                    ...e,
                    fechaISO: `${año}-${mes}-${dia}`,
                    fechaObjeto,
                };
            });

            const fechasClasificadas = extraccionDeFechas(documentosModificados)

            setAllDocumentId(documentosModificados);
            setFechasPosibles({
                fechasSchwab: fechasClasificadas.fechasSchwab.map(
                    (e: any) => e.fechaObjeto
                ),
                fechasInteractive: fechasClasificadas.fechasInteractive.map(
                    (e: any) => e.fechaObjeto
                ),
            });

            const { conciliacionesSchwab, conciliacionesInteractive, especiesSchwab, especiesInteractive } = await loadLatestData(fechasClasificadas);

            setConcilExtTotal({ conciliacionesSchwab, conciliacionesInteractive, });
            
            setEspecies({ especiesSchwab, especiesInteractive});
        } catch (error) {
            console.error("Error al recibir las fechas:", error);
            setFechasPosibles({ fechasSchwab: [], fechasInteractive: [] });
        }
    };

    const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setLoading(true);

        if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            if (event.target) {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                raw: false,
            });

            try {
                let dataTotal 
                dataTotal = processCsvData(jsonData)
                if (tipoBroker === "SCHWAB") {
                    dataTotal = processSchwabData(worksheet, jsonData);
                    const responseSchwab = await postConciliacionExterior(dataTotal);
                    setConcilExtTotal({
                        ...concilExtTotal,
                        conciliacionesSchwab: responseSchwab
                    });
                    setEspecies({
                        ...especies,
                        especiesSchwab: Array.from(new Set(responseSchwab.map((item: any) => item.symbol)))
                    });
                } else if (tipoBroker === "INTERACTIVE") {
                    dataTotal = processInteractiveData(worksheet, jsonData);
                    console.log(dataTotal);
                    const responseInteractive = await postConciliacionExterior(dataTotal);
                    console.log(responseInteractive);
                    setConcilExtTotal({
                        ...concilExtTotal,
                        conciliacionesInteractive: responseInteractive
                    });
                    setEspecies({
                        ...especies,
                        especiesInteractive: Array.from(new Set(responseInteractive.map((item: any) => item.symbol)))
                    });
                }
            } catch (error) {
                setError(true)
                console.error("Error al procesar o enviar los datos:", error);
            } finally {
                setLoading(false);
            }
            }
        };
        reader.readAsArrayBuffer(file);
        }
    };

    const processCsvData = (data: Array<any>) => {
        return data.map((row) =>
            row.map((cell: any) => {
                if (typeof cell === "string" && cell.match(/^\d{1,3}(,\d{3})*(.\d+)?$/)) {
                    return Number(cell.replace(/,/g, ""));
                }
                return cell;
            })
        );
    };

    const concilExtFiltInteractive = useMemo(() => {
        if (!concilExtTotal || !concilExtTotal.conciliacionesInteractive) return [];
        if (!especieInteractive) return concilExtTotal.conciliacionesInteractive.sort((a, b) => {
            if (a.diferencia < 0 && b.diferencia >= 0) return -1;
            if (a.diferencia >= 0 && b.diferencia < 0) return 1;
            if (a.diferencia > 0 && b.diferencia === 0) return -1;
            if (a.diferencia === 0 && b.diferencia > 0) return 1;
            return a.diferencia - b.diferencia; // Orden natural
        });
    
        return concilExtTotal.conciliacionesInteractive
            .filter((item) => item.symbol.toLowerCase() === especieInteractive.toLowerCase())
            .sort((a, b) => {
                if (a.diferencia < 0 && b.diferencia >= 0) return -1;
                if (a.diferencia >= 0 && b.diferencia < 0) return 1;
                if (a.diferencia > 0 && b.diferencia === 0) return -1;
                if (a.diferencia === 0 && b.diferencia > 0) return 1;
                return a.diferencia - b.diferencia;
            });
    }, [especieInteractive, concilExtTotal]);

    const concilExtFiltSchwab = useMemo(() => {
        if (!concilExtTotal || !concilExtTotal.conciliacionesSchwab) return [];
    
        const sortedData = concilExtTotal.conciliacionesSchwab.sort((a, b) => {
            if (a.diferencia < 0 && b.diferencia >= 0) return -1;
            if (a.diferencia >= 0 && b.diferencia < 0) return 1;
            if (a.diferencia > 0 && b.diferencia === 0) return -1;
            if (a.diferencia === 0 && b.diferencia > 0) return 1;
            return a.diferencia - b.diferencia;
        });
    
        if (!especieSchwab) return sortedData;
    
        return sortedData.filter(
            (item) => item.symbol.toLowerCase() === especieSchwab.toLowerCase()
        );
    }, [especieSchwab, concilExtTotal]);

    const handleChangeTipoBroker = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTipoBroker(e.target.value);
        setEspecie("")
    };

    const getEspecie = () => tipoBroker === "INTERACTIVE" ? especieInteractive : especieSchwab;
    const setEspecie = (value: string) => {
        tipoBroker === "INTERACTIVE" ? setEspecieInteractive(value) : setEspecieSchwab(value);
    };

    const handleSearch = async () => {
        if (!startDate) {
            console.warn("Selecciona una fecha antes de buscar.");
            return;
        }

        const formattedDate = startDate.toISOString().split("T")[0];
        const matchedDocument: any = allDocumentId.find(
            (doc: any) => doc.fechaISO === formattedDate
        );

        if (!matchedDocument) {
            console.warn("No se encontró un documento para la fecha seleccionada.");
            return;
        }
        try {
            const response = await getDatosPorFecha(matchedDocument._id);
            
            if(tipoBroker === "INTERACTIVE") { 
                setConcilExtTotal(prevState => {
                    const newState = {
                        ...prevState,
                        conciliacionesInteractive: response.arrayTenencia
                    };
                    return newState;
                });
            } else if(tipoBroker === "SCHWAB") { 
                setConcilExtTotal(prevState => {
                    const newState = {
                        ...prevState,
                        conciliacionesSchwab: response.arrayTenencia
                    };
                    return newState;
                });
            }
            setStartDate(null);
        } catch (error) {
            console.error("Error al buscar datos:", error);
        }
    };

    return {
        handleSearch,
        fechasPosibles,
        startDate,
        setStartDate,
        concilExtFiltInteractive,
        concilExtFiltSchwab,
        especies,
        loading,
        fileInputRef,
        tipoBroker,
        handleUpload,
        handleChangeTipoBroker,
        getEspecie,
        error,
        setEspecie,
        setError,
        concilExtTotal,
    };
};