import { useState } from "react";

export const useDarDeBajaHook = () => {

    const [openModalDarDeBaja, setOpenModalDarDeBaja] = useState<boolean>(false);

    const handleOpenModalDarDeBaja = () => {
        setOpenModalDarDeBaja(true)
    }

    return{
        openModalDarDeBaja,
        setOpenModalDarDeBaja,
        handleOpenModalDarDeBaja,
    }
}