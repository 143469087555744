import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 2em;
`;

export const LabelContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Input = styled.input`
    display: none;
`;

export const Label = styled.label`
    display: flex;
    height: 3em;
    padding: 1.5em;
    margin-top: 1.5em;
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
    border-radius: 10px;
    border: solid rgba(183, 183, 183, 0.6) 1px;
    cursor: pointer;
    color: #757474;
    font-weight: bolder;
    align-items: center;
    font-size: 1em;
`;

export const LogoAdjuntar = styled.img`
    width: 2em;
    height: 2em;
    margin-right: 0.8em;
`;

export const SelectContainer = styled.div`
    display: flex;
	justify-content: center;
    align-items: center;
    margin-top: 1.5em;
    width: 100%;
`;

export const Select = styled.select`
    width: 75%;
    border: none;
    box-shadow: 4px 4px 14px 0px rgba(235, 235, 237, 1) inset;
    border-radius: 4px;
    background-color: white;
    padding: 0.6em;
    background-image: url("https://api.iconify.design/ep/arrow-down-bold.svg");
    background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
    overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
    font-size: 1em;
    /* font-weight: lighter; */
    cursor: pointer;

    &:focus {
        outline: none;
        border: none;
    }
`;