import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { getOrdenesExterior, procesarOrdenesExterior } from "../api/ordenesExterior";
import { GetOrdenesExteriorResponse, Orden } from "../interfaces/interfaceOrdenesExterior/getOrdenesExteriorInterface";
import MenuOrdenesExterior from "../components/Table/MenuOrdenesExterior/MenuOrdenesExterior";
import { EstadoPedidoExterior } from "../components/Table/EstadoPedidoExterior/EstadoPedidoExterior";
import ModalImporteExterior from "../components/Table/ModalImporteExterior/ImporteExterior";

// Hook que manipula la data de las órdenes exterior 

interface OrdenExterior {
    id: string;
    fecha: string;
    cliente: string;
    recibio: JSX.Element | string;
    ejecutadas: number;
    operacion: string;
    cantidad: string;
    precio: string;
    puntos: JSX.Element | string;
    estadoActual: string,
    market: boolean;
    especie: string;
    importeInicial: string;
    importeFinal: string; 
}

export interface OrdenCheck {
    id: string,
}

interface Props {
    setLoading: (param: boolean) => void,
}

export default function useOrdenesExteriorHook({ setLoading }: Props) {

    const [ordenesExterior, setOrdenesExterior] = useState<OrdenExterior[]>([]);
    const [ordenesExteriorFilt, setOrdenesExteriorFilt] = useState<OrdenExterior[]>([]);
    const [ordenesChecked, setOrdenesChecked] = useState<OrdenCheck[]>([]);
    const [showOnlyPendientes, setShowOnlyPendientes] = useState<boolean>(false);

    // const [openModalImporte, setOpenModalImporte] = useState<boolean>(false);

    // const handleOpenModalImporteExterior = () => {
    //     console.log(':C')
    //     setOpenModalImporte(true);
    // }

    useEffect(() => {
        if (showOnlyPendientes) {
            const onlyPendientes = ordenesExterior.filter(e => {
                return (e.estadoActual === "PENDIENTE");
            });
            setOrdenesExteriorFilt(onlyPendientes);
        } else {
            setOrdenesExteriorFilt(ordenesExterior)
        }
    }, [showOnlyPendientes])

    // const actualizarOrdenedesCheck = (newValue: OrdenCheck) => {
    //     setOrdenesChecked((prevs) => [...prevs, newValue]);
    // };
    
    // const deleteOrdenesCheck = (removeValue: OrdenCheck) => {
    //     setOrdenesChecked((prevs) => prevs.filter((e) => e.id !== removeValue.id));
    // };

    // Petición a la api

    const getOrdenesExteriorData = async () => {
        try {
            const res: AxiosResponse<GetOrdenesExteriorResponse> = await getOrdenesExterior();
            
            let ordenesExteriorData = res?.data?.body?.ordenes;
            
            ordenesExteriorData = ordenesExteriorData?.reverse();

            const mappedOrdenes: OrdenExterior[] = ordenesExteriorData?.map((e) => ({
                id: e._id,
                fecha: `${e.createdAt.slice(8, 10)}/${e.createdAt.slice(5, 7)}/${e.createdAt.slice(0, 4)} ${e.createdAt.slice(11, 16)}`,
                cliente: e.comitente.toString(),
                recibio: e.estado,
                //<EstadoPedidoExterior id={e._id} estado={e.estado} actualizarOrdenedesCheck={actualizarOrdenedesCheck} deleteOrdenesCheck={deleteOrdenesCheck}/>,
                ejecutadas: e.ejecutadas,
                especie: e.ticker,
                operacion: e.tipoOperacion === "compra" ? "C" : "V",
                cantidad: e.cantidad.toString(),
                precio: e.precio.toString(),
                puntos: (
                        <MenuOrdenesExterior
                            id={e._id}
                            cliente= {e.comitente.toString()}
                            especie= {e.ticker}
                            operacion= {e.tipoOperacion === "compra" ? "C" : "V"}
                            cantidad= {e.cantidad.toString()}
                            precio= {e.precio.toString()}
                            importe= {e.importeInicial.toString()}
                            market={e.market}
                        />
                ),
                estadoActual: e.estado,
                market: e.market,
                // importe: 
                //     <ModalImporteExterior id={e._id} importe={e.importe.toString()}/>,
                importeInicial: e.importeInicial.toString(),
                importeFinal: e.importeFinal ? e.importeFinal?.toString() : '',
            }));

            setOrdenesExterior(mappedOrdenes);
            setOrdenesExteriorFilt(mappedOrdenes);
            
            !(mappedOrdenes?.length > 0) && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };


    // Funcion de filtro

	const filtOrdenesExterior = (name : string) => {
		name = name.toLowerCase();

		let filtered = ordenesExterior.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.cliente.toLowerCase().includes(name) ||
                e.especie.toLowerCase().includes(name) ||
                e.operacion.toLowerCase().includes(name) ||
                e.estadoActual.toLowerCase().includes(name) 
        );

		setOrdenesExteriorFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};

	return {
        getOrdenesExteriorData,
		ordenesExteriorFilt,
        filtOrdenesExterior,
        ordenesChecked,
        showOnlyPendientes,
        setShowOnlyPendientes,
	};
}
