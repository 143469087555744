import { TableColumn } from 'react-data-table-component';
import { RowOperarConfirmacion } from '../../interfaces/interfacesRows/rows';

const columnsOperarConfirmacion: TableColumn<RowOperarConfirmacion>[] = [
    {
        selector: (row: RowOperarConfirmacion) => row.cliente,
        name: "CLIENTE",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOperarConfirmacion) => row.operacion,
        name: "OPERACIÓN",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOperarConfirmacion) => row.especie,
        name: "ESPECIE",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOperarConfirmacion) => row.cantidad,
        name: "CANTIDAD",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOperarConfirmacion) => row.precio,
        name: 'PRECIO',
        center: true,
        style: {
            color: "#004B6F",
        },
    },
];

export default columnsOperarConfirmacion;
