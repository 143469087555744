import styled from "styled-components";

export const SelectorsContainer = styled.div`
    display: flex;
    justify-content: end;
    height: 2em;
    width: 85%;
    margin-top: 1em;
    margin-left: 3em;
`;

export const StyledIcon = styled.div`
    height: 100%;
    color: #004b6f;
    margin-top: 10px;
`;

export const DateContainer = styled.div`
    display: flex;
    align-items: center;
    width: 10em;
    position: relative;
    margin-right: 10px;
`;

export const InputDate = styled.input`
    width: 10em;
    height: 100%;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
    padding: 0 10px;
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #555;
    padding: 0;

    &:hover {
        color: #000;
    }
`;