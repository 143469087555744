import { generarArchivoCotizacionesMoneda } from "../api/cotizacionesMoneda";

export const useGeneradorArchivoCotizacionesMoneda = () => {
    
    const handleDownload = async () => {

            try {
                const resp = await generarArchivoCotizacionesMoneda()

                // Crear un objeto Blob a partir de los datos recibidos
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = URL.createObjectURL(blob);

                // Crear un enlace temporal para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.download = 'archivoCotizacion.xlsx'; // Nombre del archivo descargado
                document.body.appendChild(link);
                link.click();

                // Limpiar el enlace temporal
                link.remove();
                URL.revokeObjectURL(url);
            } catch (error) {
                console.log(error);
            }
    };

	return {
        handleDownload,
    };
};