import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import { columnsConciliacionesTitulosTable } from "../../helper/Colums/columnsConciliacionesTitulosTable";
import TableTitulos from "../../components/TableTitulos/TableTitulos";

const subtitulo = "CONCILIAR TITULOS";

export const ConciliacionesTitulosTable = () => {
	const { width } = useWindowDimensions();

	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<TableTitulos subtitulo={subtitulo} columns={columnsConciliacionesTitulosTable} />
			</Container>
		</>
	);
};
