import styled from "styled-components";

export const Form = styled.form`
    position: absolute;
    top: 2.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 0;
    padding: 0;
    overflow: hidden;
`;

export const Box = styled.div`
    background-color: white;
    width: 100%;
    padding: 1em 0;
    margin-bottom: 0;
`;

export const SeccionAdjuntos = styled.div`
    display: flex;
    align-items: center;
`;

export const LogoAdjuntar = styled.img`
    width: 2em;
    height: 2em;
    margin-right: 0.8em;
`;

export const SeccionButtons = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2em;
`;

export const MiniBox = styled.div`
    display: flex;
    align-items: center;
    margin: 1em 0.5em;
`;

export const Input = styled.input`
    display: none;
`;

export const Label = styled.label`
    display: flex;
    height: 3em;
    padding: 1.5em;
    margin-left: 0.5em;
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
    border-radius: 10px;
    border: solid rgba(183, 183, 183, 0.6) 1px;
    cursor: pointer;
    color: #757474;
    font-weight: bolder;
    align-items: center;
    font-size: 1em;
`;

export const TextError = styled.p`
    margin-left: 3em;
    color: #E3342F;
    font-weight: bold;
`;
