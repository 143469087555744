import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background: White;
    min-height: 7em;
    margin: 1em 0em 0em 3.8em;
    width: 86%;
    border-radius: 0.5em;
    color: #00386E;	
    box-shadow: 2px 2px 8px rgba(0, 0, 0.1, 0.2);
`;

export const Divider = styled.div`
    margin: 0.1em;
    display: flex;
    width: 70%;
`;

export const FirstColumn = styled.div`
    margin: 0.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
`;

export const SecondColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-left: 0.5em;
`;

export const ThirdColumn = styled.div`
    margin: 0.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ColumnTitles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ColumnData = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
`;
export const AccionText = styled.p`
    font-size: 1em;
    margin: 0.4em 0;
    font-weight: bold;
`;

export const ProgressbarContainer = styled.div`
    height: 3em;
    width: 3em;
`;

export const HoraText = styled.p`
    font-size: 0.8em;
    margin: 0.4em 0;
`;

export const TextRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Text = styled.p`
    font-size: 1em;
    margin: 0.1em 0.5em 0 0;
`;

export const TextData = styled.p`
    font-size: 1em;
    margin: 0.1em 0;
    font-weight: bold;
`;


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const NombreText = styled.p`
    font-size: 1em;
    margin: 0.3em 0;
    font-weight: bold;
`;

export const Img = styled.img`
    height: 15px;
    width: 15px;
    margin-left: 0.5em;
`;

export const EstadoContainer = styled.div`
    padding: 0.2em 0.3em;
    border-radius: 0.2em;
    text-align: center;
    margin: 0 0.2em;
    width: 6em;
`;

export const EstadoText = styled.p`
width: 2.5  
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
`;

export const Button = styled.button`
    background: none;
    border: none;
    margin: 0;
    color: ${({ theme }) => theme.primaryColor};
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
`;

export const LoadingContainer = styled.div`
    display: flex; 
    justify-content: center; 
    margin: 0.5em;
    align-items: center;
    height: 88.5vh;
`