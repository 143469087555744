import { ChangeEvent, useEffect, useState } from "react";
import { deleteAllTransferenciasArchivo, deleteTransferenciasArchivo, generarArchivoCaja, getTransferenciasArchivo, saveTransferenciasArchivo } from "../api/transferencias_archivo";
import { DataDeliver, DataDeliverLiq, DataDeliverLiqExt, DataReceive, TransferenciasGenArchivo, TransferenciasGenArchivoLiqExt,} from "../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos";
import { read, utils } from "xlsx";
import { deleteRow, getTodayDate } from "../helper/generarArchPesosHelper";

export type DataTotal = DataReceive | DataDeliver | DataDeliverLiq | DataDeliverLiqExt
export type TransferenciasTotales = TransferenciasGenArchivo | TransferenciasGenArchivoLiqExt

export const useGenerarArchivo = () => {
	const [tipoTransferencias, setTipoTransferencias] = useState<string>('LIQUIDACIÓN')
	const [transferenciasTable, setTransferenciasTable] = useState<TransferenciasTotales[]>([])
	const [baseTransferencias, setBaseTransferencias] = useState<TransferenciasTotales[]>([])
	const [seleccionados, setSeleccionados] = useState<string[]>([])

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
	
		setSeleccionados([]); // Limpiar los seleccionados
	
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target) {
					const data = e.target.result;
					const workbook = read(data, { type: "array" });
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];
					const nameFirstColumn = worksheet.A1.v;

					if (tipoTransferencias === "GARANTIA"){
						deleteRow(worksheet, 0);
					}
					let newData: any[]= [];
					
					if (nameFirstColumn === "Garantia Disponible") {
						const json: DataReceive[] = utils.sheet_to_json(worksheet);
						newData = mapearExcelReceive(json);
					} else {
						if (tipoTransferencias === "GARANTIA") {
							const json: DataDeliver[] = utils.sheet_to_json(worksheet);
							newData = mapearExcelDeliver(json);
						} else {
						newData = procesarExcel(worksheet, tipoTransferencias);
						}
					}
					setTransferenciasTable((prev) => ([...prev, ...newData]));
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const mapearExcelReceive = (arr: DataReceive[]) => {
		const arrFilt = arr.filter(a => !a['Instrumento'].includes("Pesos") && !a['Instrumento'].includes("Dólar"));
		const arrMapeado = arrFilt.map(e => {

			const match = e['Instrumento'].match(/((\d+))/);

			return {
				_id: generateUniqueId(),
				__v: 0,
				origen: "9180/" + e.Comitente,
				destino: "180/" + e.Comitente,
				tipoMovimiento: "DELIVER",
				cantidad: e['Disponible'],
				fecha: getTodayDate(),
				instrumento: match ? match[1] : "" //
			};
		});
		return arrMapeado;
	}

	const mapearExcelDeliver = (arr: DataDeliver[]) => {
		const arrFilt = arr.filter(a => !a['Tipo Requerimiento'].includes("Pesos"));
		const arrMapeado = arrFilt.map(e => {
			const match = e['Tipo Requerimiento'].match(/((\d+))/);

			return {
				_id: generateUniqueId(),
				__v: 0,
				origen: "180/" + e.Comitente,
				destino: "9180/" + e.Comitente,
				tipoMovimiento: "DELIVER",
				cantidad: e['No Cubierto'],
				fecha: getTodayDate(),
				instrumento: match ? match[1] : "" //
			};
		});
		return arrMapeado
	}

	function procesarExcel(
		worksheet: any,
		tipoTransferencias: string,
	): TransferenciasGenArchivo[] {
		const json: DataTotal[] = utils.sheet_to_json(worksheet)
		return mapeoGeneral(json, tipoTransferencias)
	}

	function mapeoGeneral(
		arr: DataTotal[], 
		tipoTransferencias: string
	): TransferenciasTotales[]{
		const baseMap = arr.map((e) => ({
			_id: generateUniqueId(),
			__v: 0,
			fecha: getTodayDate(),
			tipoMovimiento: "DELIVER",
			tipoTransferencias: tipoTransferencias
		}))
		switch (tipoTransferencias) {
			case "LIQUIDACIÓN":
				return baseMap.map((item, index) => ({
					...item,
					origen: (arr[index] as DataDeliverLiq)["Cuenta de origen"],
					destino: (arr[index] as DataDeliverLiq)["Cuenta destino"],
					cantidad: (arr[index] as DataDeliverLiq).Cantidad,
					instrumento: (arr[index] as DataDeliverLiq).Instrumento,
				}))
			case "LIQUIDACIÓN EXTERIOR":
				return baseMap.map((item, index) => ({
					...item,
					origen: (arr[index] as DataDeliverLiqExt)["Cuenta origen"],
					destino: (arr[index] as DataDeliverLiqExt)["Cuenta destino"],
					cantidad: (arr[index] as DataDeliverLiqExt).Cantidad,
					agenteDestino: (arr[index] as DataDeliverLiqExt)["Ag destino"],
					instrumento: (arr[index] as DataDeliverLiqExt).instrumento,
				}))
			default:
				throw new Error(`Tipo de transferencia no reconocido: ${tipoTransferencias}`)
		}
	}

	async function saveApi() {
		await saveTransferenciasArchivo(transferenciasTable)
	}

	async function deleteController() {
		if (seleccionados.length === transferenciasTable.length) {
			await deleteAllTransferenciasArchivo()
			setBaseTransferencias([])
			setTransferenciasTable([])
		} else if (seleccionados.length > 0) {
			await deleteTransferenciasArchivo(seleccionados)
			await getAllTransferencias()
		}
	}

	async function downloadArchive() {
		let datosProcesar = transferenciasTable.filter(a => seleccionados.some(value => value === a._id))

		if (datosProcesar.length > 0) {
			const archiveResponse = await generarArchivoCaja(tipoTransferencias, datosProcesar)
			const extensionArchivo = tipoTransferencias === "LIQUIDACIÓN EXTERIOR" ? 'SI1' : 'SI2'

			const blob = new Blob([archiveResponse.data], { type: `application/${extensionArchivo}` });
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');

			link.href = blobUrl;
			link.download = `TRANSFERENCIAS.${extensionArchivo}`
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(blobUrl);
		}
	}

	const handleChangeTipoTransferencia = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		setTipoTransferencias(value);
	}

	useEffect(() => {
		getAllTransferencias()
	}, [])

	const getAllTransferencias = async () => {
		try {
			const res = await getTransferenciasArchivo();
			setTransferenciasTable(res.data.body)
			setBaseTransferencias(res.data.body)
		} catch (error) {
			console.log(error)
		}
	}

	const addItem = () => {
		let arr: TransferenciasTotales[] = [
			{
				origen: "", 
				destino: "", 
				tipoMovimiento: "DELIVER", 
				instrumento: "", 
				cantidad: "", 
				fecha: getTodayDate(), 
				_id: generateUniqueId(), 
				__v: 0,
				tipoTransferencias: tipoTransferencias,
			}
		]

		if(tipoTransferencias === "LIQUIDACIÓN EXTERIOR"){
			arr = arr.map((obj) => ({...obj, agenteDestino: "" }))
		}
		arr = [...arr, ...transferenciasTable]
		setTransferenciasTable(arr)
	}

	function generateUniqueId(): string {
		let newId: string;

		do {
			newId = Array.from(crypto.getRandomValues(new Uint8Array(12)))
				.map((byte) => byte.toString(16).padStart(2, '0'))
				.join('');
		} while (transferenciasTable.some((transferencia) => transferencia._id === newId));

		return newId;
	}

	return {
		transferenciasTable,
		tipoTransferencias,
		handleChangeTipoTransferencia,
		setTransferenciasTable,
		addItem,
		saveApi,
		downloadArchive,
		deleteController,
		setSeleccionados,
		handleUpload
	};
};
