import { TableColumn } from 'react-data-table-component';
import { RowOrdenesExterior } from '../../interfaces/interfacesRows/rows';

const columnsOrdenesExterior: TableColumn<RowOrdenesExterior>[] = [
    {
        selector: (row: RowOrdenesExterior) => row.fecha,
        name: "Fecha/Hora",
        center: true,
        grow: 2.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.cliente,
        name: "Cliente",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.recibio,
        name: "Estado",
        center: true,
        // grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.ejecutadas,
        name: "Ejecutadas",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.especie,
        name: "Especie",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.operacion,
        name: "Operación",
        center: true,
        // grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.cantidad,
        name: "Cantidad",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.precio,
        name: "Precio",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.importeInicial,
        name: "Importe de orden",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.importeFinal,
        name: "Importe de ejecución",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.puntos,
        name: "",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
];

export {
    columnsOrdenesExterior,
};
