import styled from "styled-components";

export const Container = styled.div<{width: number, height: number}>`
    width: ${(props) => props.width * 0.85}px;
    height: ${(props) => props.height * 0.92}px;
    left: ${(props) => props.width * 0.15}px;
    top: ${(props) => props.height * 0.062}px;
    position: absolute;
`

export const SecondContainer = styled.div`
	padding-left: 1em;
    padding-top: 1em;
    height: 100%;
    width: 100%;
`;

export const SubtituloContainer = styled.div`
	display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    margin-right: 1em;
`;

export const Subtitulo = styled.h2`
	font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const WhiteBox = styled.div`
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 99%;
    background-color: white;
    border-radius: 10px;
`;

export const TableContainer = styled.div`
	display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
`;

export const customStyles = (height: number) => ({
    tableWrapper: {
        style: {
            display: "table",
            minHeight: height * 0.6,
            maxWidth: "100%",
            backgroundColor: "white",
            color: "#004B6F",
            padding: "0 1.2em",
        },
    },
    header: {
        style: {
            minHeight: "56px",
        },
    },
    rows: {
        style: {
            minHeight: "46px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
            backgroundColor: "rgba(142, 178, 221, 0.5)",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
})