import { useContext, useEffect, useState } from "react";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { ConciliacionesContext } from "../context/conciliacionesContext/conciliacionesContext";
import { ConciliacionTitulos, GetConciliacionesTitulosResp } from "../interfaces/interfaceConciliacionesTitulos/getConciliacionesTitulosInterface";
import { getConciliacionesTitulos } from "../api/conciliaciones";

interface Props {
	userRol: string,
	setLoading: (param: boolean) => void,
}

export const useConciliacionesTitulosTableHook = ({ userRol, setLoading }: Props) => {
	const { conciliacionesTitulos, actualizarConciliacionesTitulos } = useContext(ConciliacionesContext);
	const [conciliacionesTitulosFilt, setConciliacionesTitulosFilt] = useState<ConciliacionTitulos[]>([]);

	useEffect(() => {
		if (userRol === roles.administrador || userRol === roles.tesoreria) {
			if (conciliacionesTitulos.length !== 0) {
				setConciliacionesTitulosFilt(conciliacionesTitulos);
			} else {
				getconciliacionesApi();
			}
		}
		conciliacionesTitulos.length === 0 && setLoading(false);
	}, []);

	useEffect(() => {

		setConciliacionesTitulosFilt(conciliacionesTitulos)
	}, [conciliacionesTitulos])

	const getconciliacionesApi = async () => {
		try {
			const res: AxiosResponse<GetConciliacionesTitulosResp> = await getConciliacionesTitulos();
			if (res?.status === 200) {
				actualizarConciliacionesTitulos(res.data.body);
				setConciliacionesTitulosFilt(res.data.body);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const filtconciliacionesTitulos = (name: string) => {
		if (name.length === 0) {
			setConciliacionesTitulosFilt(conciliacionesTitulos);
		} else {
			let filtered = conciliacionesTitulos.filter((e) =>
				e["accountNumber_Gallo"] ? e["accountNumber_Gallo"].toString().startsWith(name) : e["accountNumber_Caja"] ? e["accountNumber_Caja"].toString().startsWith(name) : null
			);

			setConciliacionesTitulosFilt(filtered);
			filtered.length === 0 && setLoading(false);
		}
	};

	return {
		conciliacionesTitulos,
		conciliacionesTitulosFilt,
		filtconciliacionesTitulos,
	};
};

const styles = {
	button: {
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
		color: "#004B6F",
	},
};
