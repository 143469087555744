import { useState, useEffect, useMemo, useCallback } from 'react';
import { GetTenenciaResponse } from "../interfaces/interfaceTenecia/getTenenciaInterface";
import { getTenencia } from "../api/tenencia";
import { AxiosResponse } from "axios";
import { Order } from "../interfaces/interfacesOrdenes/interfacesOrdenes";
import { getHistorialOrdenes } from "../api/historialOrdenes";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatEstado, procesarOrdenesHistorial } from "../helper/HistorialOrdenesHelpers/historialOrdenesHelpers";

interface FilterParams {
    especie: string
    fecha: Date | null
    estado: string
}

export const useHistorialOrdenesHook = () => {
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [loadingHistorial, setLoadingHistorial] = useState<boolean>(false)
    const [nroCuentaCliente, setNroCuentaCliente] = useState<string>("")
    const [infoCuenta, setInfoCuenta] = useState<any | null>(null)
    const [especies, setEspecies] = useState<string[]>([])
    const [especie, setEspecie] = useState("")
    const [totalOrdenes, setTotalOrdenes] = useState<Order[]>([])
    const [ordenesFilt, setOrdenesFilt] = useState<Order[]>([])
    const [loadingOrdenes, setLoadingOrdenes] = useState<boolean>(false)
    const [errorOrders, setErrorOrders] = useState(false)
    const [error, setError] = useState<string>("")
    const [actualMenuEstado, setActualMenuEstado] = useState("TODAS")

    useEffect(() => {
        setInfoCuenta(null)
        setError('')
    }, [])

    async function handleEffectHistorial(nroCuentaNavigate: string) {
        setInfoCuenta(null)
        setError('')
        setLoadingHistorial(true)
        setNroCuentaCliente(nroCuentaNavigate)
        try {
            const data: AxiosResponse<GetTenenciaResponse> = await getTenencia(nroCuentaNavigate)
            setInfoCuenta(data.data.body)
        } catch (error) {
            setError('Error al buscar la cuenta.')
            setLoadingHistorial(false);
        }
        setLoadingHistorial(false);
    }

    const fetchOrdenes = useCallback(async () => {
        if (!nroCuentaCliente) return
        setLoadingOrdenes(true)
        setErrorOrders(false)
        try {
            const preData = await getHistorialOrdenes(nroCuentaCliente)
            const data = procesarOrdenesHistorial(preData)
            setTotalOrdenes(data)
            setOrdenesFilt(data)
            setEspecies(Array.from(new Set(data.map((item: Order) => item.especie))))
        } catch (error) {
            console.error("Error al obtener el historial de órdenes:", error)
            setErrorOrders(true)
        } finally {
            setLoadingOrdenes(false)
        }
    }
, [nroCuentaCliente]);

    useEffect(() => {
        fetchOrdenes();
    }, [nroCuentaCliente, fetchOrdenes]);

    const applyFilters = useMemo(() => {
        return (ordenes: Order[], { especie, fecha, estado }: FilterParams) => {
            return ordenes.filter((orden) => {

                const especieMatch = !especie || orden.especie === especie
                const estadoMatch =
                    estado.toLowerCase() === "todas" || formatEstado(orden.estado).toLowerCase() === estado.toLowerCase()

                let fechaMatch = true
                if (fecha) {
                    const [day, month, year] = orden.fecha ? orden.fecha.split("/").map(Number) : []
                    const fechaOrden = new Date(year, month - 1, day)

                    let fechaFiltro: any =`${String(fecha.getDate()).padStart(2, "0")}/${String(fecha.getMonth()).padStart(2, "0")}/${fecha.getFullYear()}`
                    const [filtroDia, filtroMes, filtroA] = fechaFiltro.split("/").map(Number)
                    fechaFiltro = new Date(filtroA, filtroMes, filtroDia)

                    fechaMatch = fechaFiltro <= fechaOrden
                }

                return especieMatch && estadoMatch && fechaMatch
            }
        )
    }
        
    }, [])

    useEffect(() => {
        const filteredOrders = applyFilters(totalOrdenes, { especie, fecha: startDate, estado: actualMenuEstado })
        setOrdenesFilt(filteredOrders)
        setEspecies(Array.from(new Set(filteredOrders.map((item: Order) => item.especie))))
    }, [totalOrdenes, especie, startDate, actualMenuEstado, applyFilters])

    const handleSubmit = () => {
        applyFilters(totalOrdenes, { especie, fecha: startDate, estado: actualMenuEstado })
    }

    const handleDownloadPDF = async (nroCuenta: string, nombre: string, fecha: Date | null) => {

        const dia = String(new Date()?.getDate())
        const mes = String(new Date()?.getMonth()+1)
        const año = String(new Date()?.getFullYear())
        const fechaActual = `${dia}/${mes}/${año}`

        const doc = new jsPDF()

        const imagePath = new URL("../images/logo-rava.png", import.meta.url).href;
        const loadImageToBase64 = async (url: string): Promise<string> => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };
        const logoBase64 = await loadImageToBase64(imagePath);

        doc.setFillColor(0, 55, 100);
        doc.rect(0, 0, 210, 30, "F");

        doc.addImage(logoBase64, "PNG", 10, 6, 54, 10);

        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255);
        doc.text(`HISTORIAL DE OPERACIONES`, 10, 22);

        const fechaX = 195 - doc.getTextWidth(fechaActual);
        doc.text(`${fechaActual}`, fechaX, 10);
        const nroCuentaX = 195 - doc.getTextWidth(`N° de comitente: ${nroCuenta}`);
        doc.text(`N° de comitente: ${nroCuenta}`, nroCuentaX, 16);
        const nombreX = 195 - doc.getTextWidth(`Nombre: ${nombre}`);
        doc.text(`Nombre: ${nombre}`, nombreX, 22);

        if (fecha !== null) {  
            const fechaSet = `${fecha.getDate()}/${fecha.getMonth()+1}/${fecha.getFullYear()}`
            doc.setTextColor(0, 0, 0)
            doc.text(`Desde fecha: ${fechaSet}`, 15, 36)
        }

        const groupedData = ordenesFilt.reduce((acc, orden) => {
            const { especie } = orden;
            if (!acc[especie]) {
                acc[especie] = [];
            }
            acc[especie].push(orden);
            return acc;
        }, {} as Record<string, any[]>);

        let currentY = 45;

        Object.keys(groupedData).forEach((especie) => {
            const rows = groupedData[especie].map((op) => {
            const currencySymbol = especie.includes("_US") ? "USD" : "$"
    
            return [
                op.tipoOrden,
                op.nroOrden,
                op.cantidad,
                `${currencySymbol} ${op.precio}`,
                `${currencySymbol} ${op.importe}`,
                op.fecha,
                op.hora,
                op.plazo,
                op.vencimiento,
                op.estado,
            ];
        });
    
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(`Especie: ${especie}`, 15, currentY);
        currentY += 2;

            doc.autoTable({
                head: [ ["OPERACION", "NUM", "CANTIDAD", "PRECIO", "IMPORTE", "FECHA", "HORA", "PLAZO", "VTO.", "ESTADO" ] ],
                body: rows,
                startY: currentY,
                theme: "striped",
                headStyles: { fillColor: [0, 55, 100] }, 
                styles: { fontSize: 8, cellPadding: 2, halign: "center" }
            });
            currentY = (doc as any).lastAutoTable.finalY + 5
        });
    
        doc.save(`hist_oper_cliente_${nroCuenta}.pdf`);

    }

    return {
        ordenesFilt,
        loadingHistorial,
        infoCuenta,
        nroCuentaCliente,
        error,
        setLoadingHistorial,
        setActualMenuEstado,
        actualMenuEstado,
        formatEstado,
        errorOrders,
        loadingOrdenes,
        especie,
        setEspecie,
        especies,
        handleDownloadPDF,
        startDate,
        setStartDate,
        handleEffectHistorial,
        setNroCuentaCliente,
        handleSubmit
    }
}