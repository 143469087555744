import { ReactNode, useContext, useEffect, useState } from "react";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { GaraContext } from "../context/garaContext/garaContext";
import { getGara } from "../api/gara";
import { GetGaraResponse } from "../interfaces/interfaceGara/getGara";

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export interface garaType {
	tipo: string,
    operacion: string,
    moneda: string,
    contado: string,
    total: number,
}

export const useGaraTableHook = ({ userRol, setLoading }: Props) => {
	const { gara, actualizarGara } = useContext(GaraContext);
	const [garaFilt, setGaraFilt] = useState<garaType[]>([]);

	useEffect(() => {
		setLoading(true);
		if (userRol === roles.administrador || userRol === roles.tesoreria) {
			if (gara.length !== 0) {
				setGaraFilt(gara);
			} else {
				getGaraApi();
			}
		}
		setLoading(false);
	}, [gara, setLoading]);

	const getGaraApi = async () => {
		try {
			const res: AxiosResponse<GetGaraResponse> = await getGara();
			if (res?.status === 200) {
				const sumas = res?.data?.body?.sumas;
				if (sumas) {
					let ultGara = Object.keys(sumas).map((key) => {

						let total = sumas[key];
						let tipo = "";
						let operacion = "";
						let moneda = "";
						let contado = "";

						if (key.includes("opciones")) {
							tipo = "Opciones";
							contado = ""; // Vacío para opciones
							moneda = "Pesos"; // Opciones solo en pesos
						} else if (key.includes("accionesBonos")) {
							tipo = "Acciones/Bonos";
							moneda = key.includes("Dolar") ? "Dólar" : "Pesos";
							contado = key.includes("24") ? "24hs" : "Inmediato";
						} else if (key.includes("cauciones")) {
							tipo = "Cauciones";
							contado = ""; // Vacío para cauciones
							moneda = key.includes("Dolar") ? "Dólar" : "Pesos";
						}

						operacion = key.includes("Compra") ? "Compra" : "Venta";

						return {
							tipo,
							operacion,
							moneda,
							contado,
							total,
						};
					});
					// Tipo instrumento (opciones, acciones y bonos, cauciones)
					// C/V (compra o venta)
					// Moneda (peso/dolar -en opciones solo hay de pesos-)
					// Contado (inmediato/24 hs -en opciones y cauciones dejalo vacio-)
					// Total (aca pones la suma)

					actualizarGara(ultGara);
					setGaraFilt(ultGara);
				}

			}
		} catch (error) {
			console.log(error);
		}
	};

	const filtGara =(name: string) => {
        if (name.length === 0) {
            setGaraFilt(gara);
        } else {
			// @ts-ignore: Ignore TypeScript error here
			let value = name.toLocaleLowerCase();
			const filtered = gara.filter((e: garaType) =>
				e.tipo.toLocaleLowerCase().includes(value) ||
				e.operacion.toLocaleLowerCase().includes(value) ||
				e.moneda.toLocaleLowerCase().includes(value) ||
            	e.contado.toLocaleLowerCase().includes(value) ||
                e.total.toString().includes(value)
			);
            setGaraFilt(filtered as (garaType[]));
            if (filtered.length === 0) {
                setLoading(false);
            }
        }
    };

	return {
		garaFilt,
		filtGara,
	};
};

const styles = {
	button: {
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
		color: "#004B6F",
	},
};


