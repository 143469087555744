import { createContext, useEffect, useState } from "react";
import { TipoMercado } from "../../components/CardEspecieOrdenes/enums";
import { DatosClaveEspecie } from "../../interfaces/interfaceNombreEspecie/interfaceNombreEspecie";
// import { getNombreEspecies } from "../../api/mercadoApi";

interface Horarios {
    mercadoLocal: {
        apertura: null | string;
        cierre: null | string;
    };
    mercadoExterior: {
        apertura: null | string;
        cierre: null | string;
    };
}

export interface EspecieOpcion {
    especie: string;
    codigo:  string;
}

type mercadoContextProps = {
    tipoMercado: TipoMercado,
    setTipoMercado: React.Dispatch<React.SetStateAction<TipoMercado>>,
    especieOpciones: EspecieOpcion[] | null,
    setEspecieOpciones: React.Dispatch<React.SetStateAction<EspecieOpcion[] | null>>,
    dataLocal: DatosClaveEspecie[] | null,
    dataExterior: DatosClaveEspecie[] | null,
    especieSearch: DatosClaveEspecie | null,
    setEspecieSearch: React.Dispatch<React.SetStateAction<DatosClaveEspecie | null>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    reloaded: boolean,
    setReloaded: React.Dispatch<React.SetStateAction<boolean>>,
    onChangeHorariosLocal: (apertura: string, cierre: string) => void,
    onChangeHorariosExterior: (apertura: string, cierre: string) => void,
    horarios: Horarios,
}

export const MercadoContext = createContext({} as mercadoContextProps);


export const MercadoProvider = ({ children }: any) => {

    const mercadoStorage = localStorage.getItem('tipoMercado');

    const [tipoMercado, setTipoMercado] = useState<TipoMercado>(
        mercadoStorage ?
            mercadoStorage === TipoMercado.Local ?
                TipoMercado.Local :
                TipoMercado.Exterior :
            TipoMercado.Local
    );
    const [especieOpciones, setEspecieOpciones] = useState<EspecieOpcion[] | null>(null);
    const [dataLocal, setDataLocal] = useState<DatosClaveEspecie[] | null>(null);
    const [dataExterior, setDataExterior] = useState<DatosClaveEspecie[] | null>(null);
    const [especieSearch, setEspecieSearch] = useState<DatosClaveEspecie | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [reloaded, setReloaded] = useState<boolean>(false);
    const [horarios, setHorarios] = useState<Horarios>({
        mercadoLocal: {
            apertura: null,
            cierre: null,
        },
        mercadoExterior: {
            apertura: null,
            cierre: null,
        },
    });


    function onChangeHorariosLocal(apertura: string, cierre: string) {
        setHorarios(prevHorarios => ({
            ...prevHorarios,
            mercadoLocal: {
                apertura: apertura,
                cierre: cierre,
            },
        }));
    }
    function onChangeHorariosExterior(apertura: string, cierre: string) {
        setHorarios(prevHorarios => ({
            ...prevHorarios,
            mercadoExterior: {
                apertura: apertura,
                cierre: cierre,
            },
        }));
    }

    // useEffect(() => {
    //     async function obtenerData() {
    //         try {
    //             const resp = await Promise.all([
    //                 getNombreEspecies(TipoMercado.Local),
    //                 getNombreEspecies(TipoMercado.Exterior),
    //             ]);

    //             setDataLocal(resp[0].data.body.data.body);
    //             setDataExterior(resp[1].data.body.data.body);
    //             setReloaded(false);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     obtenerData();
    // }, []);


    return (
        <MercadoContext.Provider
            value={{
                tipoMercado,
                setTipoMercado,
                especieOpciones,
                setEspecieOpciones,
                dataLocal,
                dataExterior,
                especieSearch,
                setEspecieSearch,
                loading,
                setLoading,
                reloaded,
                setReloaded,
                onChangeHorariosLocal,
                onChangeHorariosExterior,
                horarios,
            }}>
            {children}
        </MercadoContext.Provider>
    );
}