import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles"
import { Box, Button } from "@mui/material";

export const ButtonUpload = muiStyled(Button)(({ theme}) => ({
    height: "2.5em",
    minWidth: "10em",
    backgroundColor: "#004B6F",
    color: "white",
    alignSelf:"flex-end",
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#003855",
    },
}))

export const Container = styled.div<{width: number, height: number}>`
    height: calc(100vh - 3em);
    width: ${(props) => props.width * 0.85}px;
    left: ${(props) => props.width * 0.15}px;
    position: absolute;
    margin-top: ${(props) => props.height * 0.03}px;
    display: flex;
    flex-direction: column;
    padding: 2em 1em;
`;

export const Subtitulo = styled.p`
    font-size: 2rem;
    font-weight: bold;
    color: rgba(0, 75, 111, 0.75);
`;

export const ContainerRight = styled.div<{width: number}>`
    display: flex;
    height: 2.5em;
    width: ${(props) => props.width * 0.42}px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`

export const RowContainerBetween = styled.div<{height: number}>`
    height: ${(props) => props.height * 0.05}px;
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    margin-block: ${(props) => props.height * 0.015}px;
`;

export const Select = styled.select`
    width: 9em;
    background: transparent;
	padding: 0.5em;
	color: #00386E;	
    font-weight: bold;
    font-size: 16px;
    border: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
    outline: none;
	background-image: url("https://api.iconify.design/ep/arrow-right-bold.svg?color=#00386E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border-radius: 5px;

    &:hover {
        background-color: #d1ebf7;
    }
`;

export const customStyles = (height: number) => ({
    tableWrapper: {
        style: {
            display: "table",
            minHeight: height * 0.75,
            maxHeight: "15em",
            maxWidth: "100%",
            backgroundColor: "white",
            color: "#004B6F",
        },
    },
    header: {
        style: {
            minHeight: "56px",
        },
    },
    rows: {
        style: {
            maxHeight: "50px",
            minHeight: "45px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
            backgroundColor: "rgba(142, 178, 221, 0.5)",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
            whiteSpace: 'normal', 
        },
    },
})

export const DataBoxTable = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "1em"
})) 

export const ErrorContainer = styled.div`
    background-color: #f2f2f2;
    border: 0.5px solid #c9c9c9;
    border-radius: 5px;
    display: flex;
    height: 2.5em;
    padding-inline: 5px;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 4px 8px rgba(0, 0, 0.1, 0.1);
`

export const ButtonCloseAlert = styled.button`
    margin-left: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #c80000;
`

export const MensajeError = styled.span`
    color: black;
`;

export const TableContainer = styled.div`
    padding-top: 0.5em;
    display: flex;
    flex-direction: column;
    height: 76vh;
    background-color: white;
`