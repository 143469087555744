import { ChangeEvent, useContext, useState } from "react";
import { procesarDividendos } from "../api/dividendos";
import { DividendosContext } from "../context/dividendosContext/dividendosContext";
import { useNavigate } from "react-router-dom";

export const useDividendos = () => {
	const [cajaArchive, setCajaArchive] = useState<File | null>(null);
	const [galloArchive, setGalloArchive] = useState<File | null>(null);
	const { actualizarDividendos } = useContext(DividendosContext);
	const navigate = useNavigate();
	const handleSubmit = async () => {
		try {
			const resp = await procesarDividendos(cajaArchive, galloArchive);
			actualizarDividendos(resp.data.body);
			// navigate("/tabla-dividendos");
		} catch (error) {
			console.log(error);
		}
	};

	return {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		galloArchive,
		setGalloArchive,
	};
};
