import React from "react";
import { Button, CircularProgress } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container, FileText, Input, Label, Row, Subtitulo, SubtituloContainer, WhiteBox } from "./elements";
import { useGeneradorArchivoCotizacionesMoneda } from "../../hooks/useGeneradorArchivoCotizacionesMoneda";

export const GeneradorCotizacionesMoneda = () => {

    const { width, height } = useWindowDimensions();

    const {
        handleDownload,
    } = useGeneradorArchivoCotizacionesMoneda();

    return (
        <div
            style={{
                width: width * 0.85,
                height: height * 0.92,
                left: width * 0.15,
                top: height * 0.062,
                position: "absolute",
            }}
        >
            <Container>
                <SubtituloContainer>
                    <Subtitulo>GENERADOR DE COTIZACIONES DE MONEDA INGEMATICA</Subtitulo>
                </SubtituloContainer>
                <WhiteBox>
                    <Button
                        style={{
                            marginTop: "24px",
                            backgroundColor: "#004B6F",
                            color: 'white',
                            fontSize: "0.7em",
                            padding: "0.7em 2em",
                        }}
                        onClick={handleDownload}
                        variant="contained"
                        size="medium"
                    >
                        DESCARGAR
                    </Button>
                </WhiteBox>
            </Container>
        </div>
    );
};


const styles = {
    logo: {
        fontSize: "2em",
        marginRight: "0.5em",
    }
}