import styled from "styled-components";

export const SubtituloContainer = styled.div`
    white-space: nowrap;
    margin-left: 2em;
`;

export const Subtitulo = styled.h1`
    font-size: 1.5em;
    color: rgba(0, 75, 111, 0.75);
`;

export const SearchContainer = styled.input`
    display: flex;
    align-items: center;
    height: 2.3em;
    width: 85%;
    margin-left: 1em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;

export const SearchContainerSinInput = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 2.3em;
    width: 85%;
    margin-left: 4em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;

export const SearchButton = styled.button`
    border-radius: 2em;
    background: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding-right: 1em;
`;