import { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CardInfoCuenta } from '../../components/TenenciaComponents/CardInfoCuenta/CardInfoCuenta';
import { CardInfoUser } from '../../components/TenenciaComponents/CardInfoUser/CardInfoUser';
import CardEspecieTenencia from '../../components/TenenciaComponents/CardEspeciesTenencia/CardEspecieTenencia';
import { useTenenciaHook } from "../../hooks/useTenenciaHook";
import { CircularProgress } from "@mui/material";
import { CardsInfoCuentaontainer, CardsInfoUserContainer, Container, ContainerError, ContainerEspecies, 
    ContainerLoading, SecondContainer, Tab, TabContainer, TenenciaContainer, TextoError } from "./elements";
import { columnsTransferenciasDolares } from "../../helper/Colums/columsTransferenciasDolares";
import TableDolarRenta from "../../components/TableDolarRenta/TableDolarRenta";
import { useLocation } from "react-router-dom";
import { BuscadorNroCuenta } from "../../components/BuscadorNroCuenta/BuscadorNroCuenta";
import { CuentasBancarias } from "../../components/CuentasBancarias/CuentasBancarias";

export const Tenencia = () => {
    const location = useLocation();
    const infoCuentaNavigate: any = location.state?.infoCuenta;
    const nroCuentaNavigate: any = location.state?.nroCuenta;
    const [activeTab, setActiveTab] = useState<string>("tenencia");
    const { width, height } = useWindowDimensions();

    const {
        handleInput,
        handleSubmit,
        changeColor,
        loading,
        infoCuenta,
        nroCuentaCliente,
        handleKeyDown,
        error,
        actualOption,
        setTipoDolarImporte,
        transferirDolares,
        sortArray,
        cuentasBancarias
    } = useTenenciaHook();

    const [reloadTable, setReloadTable] = useState<boolean>(false);

    const datosCuenta = infoCuenta || infoCuentaNavigate
    const nroCuenta = nroCuentaCliente || nroCuentaNavigate

    const tabs = [
        { id: "tenencia", label: "TENENCIA" },
        { id: "cuentaBancaria", label: "CUENTAS BANCARIAS" },
    ];

    return (
        <Container width={width} height={height}>
            <>
                <BuscadorNroCuenta
                    handleInputHistorial={handleInput}
                    handleDoble={handleKeyDown}
                    handleSubmitHistorial={handleSubmit}
                />
            </>
                {
                    loading ? 
                        (
                            <ContainerLoading>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </ContainerLoading>
                        ) 
                        : 
                        datosCuenta ? 
                            (
                                <SecondContainer>
                                    <CardsInfoUserContainer>
                                        <CardInfoUser
                                            userName={datosCuenta.userName}
                                            nroCuenta={nroCuenta}
                                        />
                                    </CardsInfoUserContainer>

                                    <CardsInfoCuentaontainer>
                                        <CardInfoCuenta
                                            tipoMercado="LOCAL"
                                            pesosEnCuenta={datosCuenta.cartera?.actualWallet?.pesos || 0}
                                            dolaresMercadoLocal={datosCuenta.cartera?.actualWallet?.dolarBillete || 0}
                                            variacion={datosCuenta.variacionMercadoLocal || 0}
                                            img={require("../../images/banderas/bandera-argentina-cuenta.png")}
                                        />
                                        <CardInfoCuenta
                                            tipoMercado="EXTERIOR"
                                            dolaresEnCuenta={datosCuenta.cartera?.actualWallet?.dolarExterior || 0}
                                            variacion={datosCuenta.variacionMercadoExterior || 0}
                                            img={require("../../images/banderas/bandera-usa.png")}
                                        />
                                    </CardsInfoCuentaontainer>

                                    <TabContainer>
                                        {tabs.map(({ id, label }) => (
                                            <Tab
                                                key={id}
                                                active={activeTab === id}
                                                onClick={() => setActiveTab(id)}
                                            >
                                                {label}
                                            </Tab>
                                        ))}
                                    </TabContainer>
                                    
                                    { activeTab === "tenencia" &&
                                        <TableDolarRenta 
                                            subtitulo={"PEDIDOS DOLARES TENENCIA"} 
                                            columns={columnsTransferenciasDolares} 
                                            filtroTenencia={nroCuenta} 
                                            reloadTable={reloadTable} 
                                            setReloadTable={setReloadTable} 
                                        />
                                    }
                                </SecondContainer>
                            ) 
                            : error &&
                        <ContainerError>
                            <TextoError>{error}</TextoError>
                        </ContainerError>
                } 
                { datosCuenta && activeTab === 'tenencia' ?
                        (
                            <TenenciaContainer>
                                <ContainerEspecies>
                                    {
                                        sortArray(datosCuenta.tenenciaMercadoLocal).map((t, index) =>
                                            <CardEspecieTenencia
                                                key={t.nombreEspecie}
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                pintar={changeColor(index)}
                                                tipoMercado='LOCAL'
                                                nombreEspecie={t.nombreEspecie}
                                                setTipoDolarImporte={setTipoDolarImporte}
                                                transferirDolares={transferirDolares}
                                                dolarRenta={datosCuenta.pedidosDolarRenta}
                                                setReloadTable={setReloadTable}
                                            />
                                        )
                                    } 
                                </ContainerEspecies>
                                <ContainerEspecies>
                                    {
                                        sortArray(datosCuenta.tenenciaMercadoExterior).map((t, index) =>
                                            <CardEspecieTenencia
                                                key={t.nombreEspecie}
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                pintar={changeColor(index)}
                                                tipoMercado='EXTERIOR'
                                                nombreEspecie={t.nombreEspecie}
                                                setReloadTable={setReloadTable}
                                            />
                                        )
                                    } 
                                </ContainerEspecies>
                            </TenenciaContainer>
                    ) 
                    : 
                    ( 
                        datosCuenta && activeTab === 'cuentaBancaria' ? (
                            <CuentasBancarias
                                cuentasBancarias={cuentasBancarias}
                            />
                ) : null
            )}
        </Container>
    )
}