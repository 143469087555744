import apiInstance from "../utils/Axios";

export async function generarArchivoCotizaciones (archive: any) {
    let data = new FormData();
    data.append("archivoPrn", archive);

    // Configurar la petición para recibir datos binarios
    return await apiInstance.post("/cotizaciones/archivoCotizacion", data, {
        responseType: "arraybuffer", // Indica que esperas un archivo binario
    });
}