import { CuentasBancariasResponse,  } from "../../interfaces/interfaceTenecia/getTenenciaInterface";
import { Contenedor, CuentaContainer  } from "./elements";

export const CuentasBancarias = (  { cuentasBancarias }: any ) => {
    return (
        <Contenedor>
            {cuentasBancarias.map((cuenta:CuentasBancariasResponse) => (
                <CuentaContainer>
                    {
                    cuenta.Moneda === "PESOS" ? 
                        (
                            <h3 style={{ flex: "0 0 8%" }}>Pesos</h3>
                        ) : (
                            <h3 style={{ flex: "0 0 8%" }}>U$D</h3>
                        )
                    }
                    <h3 style={{ flex: "0 0 20%" }}>{cuenta.Banco}</h3>
                    <h3 style={{ flex: "0 0 20%" }}>{"****  ****  " + cuenta["CBU-CVU"].slice(-4)}</h3>
                </CuentaContainer>
            ))}
        </Contenedor>
    );
};
