import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { procesarGara } from "../api/gara";
import { GaraContext } from "../context/garaContext/garaContext";
import { AxiosResponse } from "axios";
import { ProcesarGaraResponse } from "../interfaces/interfaceGara/procesarGara";

export const useGara = () => {
	const [archive, setArchive] = useState<File | null>(null);
	const { actualizarGara } = useContext(GaraContext);
	const navigate = useNavigate();
	const handleSubmit = async () => {
		try {
			const res: AxiosResponse<ProcesarGaraResponse>  = await procesarGara(archive);
			const sumas = res?.data?.body?.sumas;
			let ultGara = Object.keys(sumas).map((key) => {

				let total = sumas[key];
				let tipo = "";
				let operacion = "";
				let moneda = "";
				let contado = "";

				if (key.includes("opciones")) {
					tipo = "Opciones";
					contado = ""; // Vacío para opciones
					moneda = "Pesos"; // Opciones solo en pesos
				} else if (key.includes("accionesBonos")) {
					tipo = "Acciones/Bonos";
					moneda = key.includes("Dolar") ? "Dólar" : "Pesos";
					contado = key.includes("24") ? "24hs" : "Inmediato";
				} else if (key.includes("cauciones")) {
					tipo = "Cauciones";
					contado = ""; // Vacío para cauciones
					moneda = key.includes("Dolar") ? "Dólar" : "Pesos";
				}

				operacion = key.includes("Compra") ? "Compra" : "Venta";

				return {
					tipo,
					operacion,
					moneda,
					contado,
					total,
				};
			});
				actualizarGara(ultGara);
			} catch (error) {
				console.log(error);
			}
		};

		return {
			handleSubmit,
			archive,
			setArchive,
		};
	};
