import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import { columnsGaraTable } from "../../helper/Colums/columsGaraTable";
import TableTitulos from "../../components/TableTitulos/TableTitulos";

const subtitulo = "GARA";

export const GaraTable = () => {
	const { width } = useWindowDimensions();
	
	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<TableTitulos subtitulo={subtitulo} columns={columnsGaraTable} />
			</Container>
		</>
	);
};
