import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel } from "@mui/x-data-grid"
import { Transferencia } from "../../interfaces/interfaceTransferencias/getPedidosTranferencias"
import { Button,  Menu, } from "@mui/material"
import React, { MouseEvent, useContext, useState } from "react";
import { CheckInput, ContainerEstado, MoreHorizIconContainer } from "./elements";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TextRow, TypographyMenu } from "./elementsMui";
import { columnsToMap, paginationModel } from "./enums";

interface Props {
    transferencias: Transferencia[]
    setTransferencias: React.Dispatch<React.SetStateAction<Transferencia[]>>
    setSeleccionados: React.Dispatch<React.SetStateAction<string[]>>
    setCheckboxStates: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
    checkboxStates: { [key: string]: boolean }
}

export const PedidoTransferenciaTable = ({transferencias, setSeleccionados, checkboxStates, setCheckboxStates}: Props) => {

    const { user } = useContext(UserContext)
    const [openMenuId, setOpenMenuId] = useState<string | null>(null)

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        params: GridRenderCellParams<Transferencia>
    ) => {
        const { checked } = event.target;
        setCheckboxStates((prevStates) => ({
            ...prevStates,
            [params.id]: checked,
        }));
    }

    const handleClick = (event: MouseEvent<HTMLElement>, newOpenMenuId: string | null) => {
        setOpenMenuId(newOpenMenuId)
    }

    const handleClose = () => {
        setOpenMenuId(null)
    }

    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        setSeleccionados((prev) => {
            const newSelection = selectionModel.map((id: GridRowId) => id.toString());
            const isDuplicate = newSelection.some(id => prev.includes(id));

            if (isDuplicate) {
                const updatedSeleccionados = prev.filter(id => !newSelection.includes(id));
                return [...updatedSeleccionados];
            } else {
                return [...prev, ...newSelection];
            }
        });
    };

    const columns: GridColDef<Transferencia>[] = columnsToMap.map((field: any) => ({
        field: field.name,
        minWidth: field.minWidth,
        flex: field.flex,
        headerName: field.headerName,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderHeader: field.renderHeader,
        renderCell: (params) => {
            const getBackgroundColor = () => {
                switch (params.row.estado) {
                    case "Aprobada" : return "#99ff99";
                    case "Rechazada" : return "#ff9999";
                    case "Pendiente" : 
                    if(params.row.descripcion === "Sin saldo con aforo"){
                        return "#e69dfb"
                    } else {
                        return "#ffff99"
                    }
                        default: return "transparent";
                }
            };

            // ESTE IF ES PARA LA COLUMNA DE 3 PUNTOS "OPTIONS"

            if (field.name === "options"){
                return (
                    <div style={{display:"flex", justifyContent: "center", alignContent: "center", height: "2.5em", margin: "8px"}}>
                        <MoreHorizIconContainer onClick={(e) => e.stopPropagation()} >
                            <Button
                                sx={{ width:"100%",}}
                                id={`fade-button-${params.id}`}
                                aria-controls={openMenuId === params.id.toString() ? `fade-menu-${params.id}` : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenuId === params.id.toString() ? "true" : undefined}
                                onClick={(e) => handleClick(e, params.id.toString())}
                            >
                                <MoreHorizIcon fontSize="medium" color="action"/>
                            </Button>
                        </MoreHorizIconContainer>
                        <Menu
                            id={`fade-menu-${params.id}`}
                            anchorEl={document.getElementById(`fade-button-${params.id}`)}
                            open={openMenuId === params.id.toString()}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            onClose={handleClose}
                            sx={{
                                overflow: "hidden",   // Asegura que el contenido se mantenga dentro de los bordes redondeados
                            }}
                        >
                            <TypographyMenu variant="body1">Descripcion del cliente: {params.row.cliente}</TypographyMenu>
                            <TypographyMenu variant="body1">Tipo de cuenta: {params.row.tipoDeCuenta}</TypographyMenu>
                            <TypographyMenu variant="body1">Cuenta: {params.row.cuenta}</TypographyMenu>
                        </Menu>
                    </div>
                )
            }

            // ESTE ELSE ES PARA TODOS LOS TYPOGRAPHY, O SEA DATOS QUE NO SON EL MODAL DE OPTIONS
            // NI EL CHECKBOX DE ESTADO

            else if (field.name !== "estado") {
                return (
                    <TextRow onClick={(e) => e.stopPropagation()} >
                        {params.row[field.name as keyof Transferencia]}
                    </TextRow>
                );

            // ESTE ELSE ES PARA TODOS LOS ESTADO "APROBADO", "PENDIENTE" , "RECHAZADO"
            } else {
                return (
                    <ContainerEstado
                        onClick={(e) => {
                            if (params.row.estado === "Aprobada") {
                                e.stopPropagation()
                            }
                        }}
                        style={{ backgroundColor: getBackgroundColor() }}
                    >
                        {/* CON ESTADO "APROBADA" */}
                        {params.row.estado === "Aprobada" ? (
                            <span>{params.row.estado}</span>
                        ) : params.row.estado === "Pendiente" ? (
                            <>
                                <CheckInput
                                    type="checkbox"
                                    checked={checkboxStates[params.id] || false}
                                    onChange={(event) => handleInputChange(event, params)}
                                />
                                <span onClick={(e) => e.stopPropagation()}>{params.row.estado}</span>
                            </>
                        ) : params.row.estado === "Rechazada" ? (
                            user.rol === roles.administrador ? (
                                <>
                                    <CheckInput
                                        type="checkbox"
                                        checked={checkboxStates[params.id] || false}
                                        onChange={(event) => handleInputChange(event, params)}
                                    />
                                    <span onClick={(e) => e.stopPropagation()}>{params.row.estado}</span>
                                </>
                            ) : (
                                <span>{params.row.estado}</span>
                            )
                        ) : null}
                    </ContainerEstado>
                );
            }
        },
    }));

    return (
        <>
            <DataGrid
                rows={transferencias}
                columns={columns}
                getRowId={(row) => row._id}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                sx={{
                display: "flex",
                    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "center",
                        padding: "0",
                        margin: "0",
                    },
                    ".css-1qb993p-MuiDataGrid-columnHeaderTitle":{
                        whiteSpace: "pre-line"
                    },
                    "& .super-app-theme--header": {
                        width: "100%",
                        display: "flex",
                        textAlign: "center",
                        padding: "0",
                        margin: "0",
                    },
                    "& .MuiDataGrid-cell": {
                        padding: "0px",
                    },
                }}
                disableColumnMenu
                onRowSelectionModelChange={(itm) => handleRowSelection(itm)} 
            />
        </>
    );
}