import React, { ReactNode, createContext, useState } from "react";
import { garaType } from "../../hooks/useGaraTable";

interface GaraContextProps {
	gara: garaType[];
	actualizarGara: (newValue: garaType[]) => void;
}

export const GaraContext = createContext<GaraContextProps>({} as GaraContextProps);

interface GaraProviderProps {
	children: ReactNode;
}

export const GaraProvider = ({ children }: GaraProviderProps) => {
	const [gara, setGara] = useState<garaType[]>([]);

	const actualizarGara = (newValue: garaType[]) => {
		setGara([...newValue]);
	};
    

	return (
		<GaraContext.Provider
			value={{
				gara,
				actualizarGara,
			}}
		>
			{children}
		</GaraContext.Provider>
	);
};
