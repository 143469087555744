
import { TableColumn } from 'react-data-table-component';
import { RowGaraTable } from '../../interfaces/interfacesRows/rows';

const columnsGaraTable: TableColumn<RowGaraTable>[] = [
    {
        selector: (row: RowGaraTable) => row.tipo,
        name: "Tipo de instrumento",
        center: true,
        style: {
            color: "#004B6F",
        }
    },
    {
        selector: (row: RowGaraTable) => row.operacion,
        name: "Operación",
        center: true,
        style: {
            color: "#004B6F",
        }
    },
    {
        selector: (row: RowGaraTable) => row.moneda,
        name: "Moneda",
        center: true,
        style: {
            color: "#004B6F",
        }
    },
    {
        selector: (row: RowGaraTable) => row.contado,
        name: "Contado",
        center: true,
        style: {
            color: "#004B6F",
        }
    },
    {
        selector: (row: RowGaraTable) => row.total,
        name: "Total",
        center: true,
        style: {
            color: "#004B6F",
        }
    },
];


export {
    columnsGaraTable,
};
