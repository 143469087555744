import { useContext, useEffect, useState, ChangeEvent, MouseEvent, FormEvent } from "react";
import { useDividendosTableHook } from "./useDividendosTable";
import { useEstadoRentasTable } from "./useEstadoRentasTable";
import { useDolaresTableHook } from "./useDolaresTable";
import { UserContext } from "../context/userContext/UserContexts";
import useOrdenesExteriorHook from "./useOrdenesExteriorHook";
import usePedidosDolaresHook from "./usePedidosDolares";
import { useCBUsTableHook } from "./useCBUsTable";
import { useConciliacionesTableHook } from "./useConciliacionesTable";
import { useConciliacionesTitulosTableHook } from "./useConciliacionesTitulosTable";
import { useGaraTableHook } from "./useGaraTable";

// Hook que manipula la data que va a renderizar el componente NewTable

interface Props {
	subtitulo: string,
	titularId: string,
}

export default function useTableTitulos({ subtitulo, titularId }: Props) {
	const { user, getAllManagers } = useContext(UserContext);
	const userRol = user ? user.rol : "";

	const [loading, setLoading] = useState(true);

	const { dolaresFilt, filtDolares } = useDolaresTableHook({ userRol, setLoading });

	const { dividendosFilt, filtDividendos } =
		useDividendosTableHook({ userRol, setLoading });

	const { conciliacionesFilt, filtconciliaciones, } =
		useConciliacionesTableHook({ userRol, setLoading });

	const { conciliacionesTitulosFilt, filtconciliacionesTitulos } =
		useConciliacionesTitulosTableHook({ userRol, setLoading });

	const { filtRentas, getAllRenta, rentasFilt } =
		useEstadoRentasTable({ userRol, setLoading });

	const { getOrdenesExteriorData, ordenesExteriorFilt, filtOrdenesExterior } =
		useOrdenesExteriorHook({ setLoading });

	const {
		getPedidosDolares,
		filtPedidosTransferencias,
		pedidosDolaresFilt,
		showAprobadasRechazadas,
		setShowAprobadasRechazadas,
		pedidosDolares,
		setPedidosDolaresFilt,
	} = usePedidosDolaresHook({ setLoading });

	const { cbusFilt, filtCBUs } = useCBUsTableHook({ userRol, setLoading });

	const { garaFilt, filtGara } = useGaraTableHook({ userRol, setLoading });


	//se llama a las funciones que hacen las peticiones al montarse el componente

	useEffect(() => {
		getAllManagers();
		switch (subtitulo) {
			case "ESTADO DE RENTAS":
				getAllRenta();
				break;
			case "ÓRDENES A OPERAR":
				getOrdenesExteriorData();
				break;
			case "PEDIDOS DOLARES":
				getPedidosDolares();
				break;
			default:
				break;
		}
	}, []);

	const [name, setName] = useState("");

	useEffect(() => {
		if (subtitulo === "PEDIDOS DOLARES") {
			const interval = setInterval(() => {
				getPedidosDolares().then(() => {
					// Reaplicar el filtro actual después de la actualización
					filtPedidosTransferencias(name);
				});
			}, 2 * 60 * 1000); // 2 minutos en milisegundos

			// Limpieza del intervalo al desmontar el componente
			return () => clearInterval(interval);
		}
	}, [name, getPedidosDolares, filtPedidosTransferencias]);

	useEffect(() => {
		if (showAprobadasRechazadas) {
			setPedidosDolaresFilt(pedidosDolares);
			setName("");
		} else {
			const withOutAprobadas = pedidosDolares.filter(e => e.estadoActual !== "APROBADA")
			setPedidosDolaresFilt(withOutAprobadas);
			setName("");
		}
	}, [showAprobadasRechazadas])

	// Renderización según el caso
	let data;
	let filtFunction: (name: string) => void;

	if (subtitulo === "DIVIDENDOS") {
		filtFunction = filtDividendos;
		data = dividendosFilt;
	}

	if (subtitulo === "CONCILIACIONES") {
		filtFunction = filtconciliaciones;
		data = conciliacionesFilt;
	}

	if (subtitulo === "CONCILIAR TITULOS") {
		filtFunction = filtconciliacionesTitulos;
		data = conciliacionesTitulosFilt;
	}

	if (subtitulo === "DOLARES") {
		filtFunction = filtDolares;
		data = dolaresFilt;
	}

	if (subtitulo === "ESTADO DE RENTAS") {
		filtFunction = filtRentas;
		data = rentasFilt;
	}

	if (subtitulo === "ÓRDENES A OPERAR") {
		filtFunction = filtOrdenesExterior;
		data = ordenesExteriorFilt;
	}

	if (subtitulo === "PEDIDOS DOLARES") {
		filtFunction = filtPedidosTransferencias;
		data = pedidosDolaresFilt;
	}

	if (subtitulo === "CBUs") {
		filtFunction = filtCBUs;
		data = cbusFilt;
	}

	if (subtitulo === "GARA") {
		filtFunction = filtGara;
		data = garaFilt;
	}

	const paginationOpciones = {
		rowsPerPageText: "Filas por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos",
	};

	function handleInput(e: ChangeEvent<HTMLInputElement>) {
		setName(e.target.value);
		filtFunction(e.target.value);
	}

	return {
		handleInput,
		data,
		paginationOpciones,
		loading,
		showAprobadasRechazadas,
		setShowAprobadasRechazadas,
		name,
	};
}
