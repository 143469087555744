import React, { useState } from "react";
import DataTable from "react-data-table-component";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useOrdenesCartera } from "../../hooks/useOrdenesCartera";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Container, Subtitulo, SubtituloContainer, TableContainer, WhiteBox } from "./elements";
import { columnsOrdenesCartera } from "../../helper/Colums/columsOrdenesCartera";

const paginationOpciones = {
	rowsPerPageText: "Filas por página",
	rangeSeparatorText: "de",
	selectAllRowsItem: true,
	selectAllRowsItemText: "Todos",
};

export const OrdenesCartera = () => {
	const { width, height } = useWindowDimensions();

	const {
		ordenesFilt,
		arrMenuList,
		loading,
		filtroActual,
		handleChange,
	} = useOrdenesCartera();

	const customStyles = {
		tableWrapper: {
			style: {
				display: "table",
				minHeight: height * 0.6,
				maxWidth: "100%",
				backgroundColor: "white",
				color: "#004B6F",
				padding: "0 1.2em",
			},
		},

		header: {
			style: {
				minHeight: "56px",
			},
		},
		rows: {
			style: {
				minHeight: "46px", // override the row height
			},
		},
		headCells: {
			style: {
				paddingLeft: "8px", // override the cell padding for head cells
				paddingRight: "8px",

				backgroundColor: "rgba(142, 178, 221, 0.5)",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px", // override the cell padding for data cells
				paddingRight: "8px",
			},
		},
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 4;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 200,
			},
		},
	};


	return (
		<div
			style={{
				width: width * 0.85,
				height: height * 0.92,
				left: width * 0.15,
				top: height * 0.062,
				position: "absolute",
			}}
		>
			<Container>
				<SubtituloContainer>
					<Subtitulo>ÓRDENES</Subtitulo>
					<div>
						<FormControl sx={{ width: 180 }}>
							<InputLabel id="demo-multiple-checkbox-label">Seleccionar filtro</InputLabel>
							<Select
								labelId="demo-multiple-checkbox-label"
								id="demo-multiple-checkbox"
								multiple
								value={filtroActual}
								onChange={handleChange}
								input={<OutlinedInput label="Seleccionar filtro" />}
								renderValue={(selected) => selected.join(', ')}
								MenuProps={MenuProps}
								disableUnderline={true}
							>
								{arrMenuList.map((name) => (
									<MenuItem key={name} value={name}>
										<Checkbox checked={filtroActual.includes(name)} />
										<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</SubtituloContainer>
				<WhiteBox>
					<TableContainer>
						<DataTable
							columns={columnsOrdenesCartera}
							data={ordenesFilt? ordenesFilt : []}
							pagination
							paginationPerPage={25}
							paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
							paginationComponentOptions={paginationOpciones}
							fixedHeader
							fixedHeaderScrollHeight="600px"
							// minHeight="600px"
							customStyles={customStyles}
							noDataComponent={
								loading ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<CircularProgress
											style={{ color: "#004B6F" }}
										/>
									</Box>
								) : (
									<>No hay órdenes por el momento</>
								)
							}
							responsive
							keyField="id"
						/>
					</TableContainer>
				</WhiteBox>
			</Container>
		</div>
	);
};

const styles = {
	menu: {
		height: "2.2em",
		borderRadius: "4px",
		padding: 0,
		margin: "0.2em 0 0 0",
	},
	listItem: {
		padding: "0.1em 0.5em 0.3em 0.5em",
		marginLeft: "0.1em",
		fontSize: "0.9em",
		cursor: "pointer",
	},
	menuItem: {
		width: "10.5em",
		fontSize: "0.9em",
		backgroundColor: "white", 
		color: "rgba(0, 75, 111, 0.75)",  
		borderBottom: "0.15em solid gray",
	},
}