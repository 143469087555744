import { styled } from "@mui/material/styles"
import { Box, Button, Typography } from "@mui/material"

export const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: '1em',
    borderRadius: "0.5em",
})) 

export const CancelarButton = styled(Button)(({ theme }) => ({
    marginLeft: "0.5em",
    width: "8em",
    color: "black",
    borderColor: "gray",
    cursor: "pointer",
})) 

export const ConfirmarButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#004b6f", 
    width: "8em",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#003855",
    },
})) 

export const XButton = styled(Button)(({ theme }) => ({
    textAlign: "left",
    justifyContent: "end",
    margin: "0",
    padding: "0",
    color: "black",
    fontSize: "18px",
    fontWeight: "500",
    cursor: "pointer",
})) 