import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container, RowContainerBetween, Subtitulo, ContainerOpcional} from "./elements";
import { usePedidoTransferenciaHook } from '../../hooks/usePedidoTransferenciaHook';
import { PedidoTransferenciaTable } from "../../components/PedidoTransferenciaTable/PedidoTransferenciaTable";
import {  Button, Chip, Stack } from "@mui/material";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useState } from "react";
import { ModalCambiarEstado } from "../../components/ModalCambiarEstado/ModalCambiarEstado";

export const PedidoTransferencia = () => {

    const { width } = useWindowDimensions();
    const [checkboxStates, setCheckboxStates] = useState({});

    const {
        seteadorDeTransferencia,
        allTransferencias,
        setAllTransferencias,
        setSeleccionados,
        seleccionados,
        openModalCambiarEstado,
		setOpenModalCambiarEstado,
		handleOpenModalCambiarEstado,
        actualizarEstado
    } = usePedidoTransferenciaHook()
    
    const clearAllCheckboxes = () => {
        setCheckboxStates({})
    };

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <RowContainerBetween>
                <Subtitulo>PEDIDO DE TRANSFERENCIAS</Subtitulo>
                <Stack direction="row" spacing={1}>
                    <Chip size="small" avatar={<CircleOutlinedIcon/>} label="Aprobada" style={{backgroundColor: "#99ff99"}} />
                    <Chip size="small" avatar={<CircleOutlinedIcon/>} label="Sin saldo con aforo" style={{backgroundColor: "#e69dfb"}} />
                    <Chip size="small" avatar={<CircleOutlinedIcon/>} label="Sin saldo sin aforo" style={{backgroundColor: "#ffff99"}} />
                    <Chip size="small" avatar={<CircleOutlinedIcon/>} label="Rechazada" style={{backgroundColor: "#ff9999"}} />
                </Stack>
            </RowContainerBetween>
            {   
            seleccionados.length > 0 &&
                <ContainerOpcional>
                    <h2>
                        {seleccionados.length > 0
                            ? `${seleccionados.length} Items seleccionados`
                            : null}
                    </h2>
                    <Button
                        onClick={handleOpenModalCambiarEstado}
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#004b6f", fontSize: "1em" }}
                    >
                        CAMBIAR DE ESTADO
                    </Button>
                    <ModalCambiarEstado
                        open={openModalCambiarEstado}
                        setOpen={setOpenModalCambiarEstado}
                        seleccionados={seleccionados}
                        onChangeEstado={actualizarEstado}
                        onSubmit={clearAllCheckboxes}
                        seteadorDeTransferencia={seteadorDeTransferencia}
                    />
                </ContainerOpcional>
            }
            <PedidoTransferenciaTable
                transferencias={allTransferencias}
                setTransferencias={setAllTransferencias}
                setSeleccionados={setSeleccionados}
                checkboxStates={checkboxStates}
                setCheckboxStates={setCheckboxStates}
            />
        </Container>
    )
}
