import { TableColumn } from 'react-data-table-component';
import { RowTransferenciaDolar } from '../../interfaces/interfacesRows/rows';

// Función para generar una columna con los estilos comunes
const crearColumna = (
    selector: (row: RowTransferenciaDolar) => any,
    name: string,
    center: boolean = true,
    grow?: number
): TableColumn<RowTransferenciaDolar> => ({
    selector,
    name,
    center,
    grow,
    style: {
        color: "#004B6F",
    },
    conditionalCellStyles: [
        {
            when: (row) => row["origen"] === "Web vieja",
            style: {
                backgroundColor: "#FAC6B5",
            },
        },
        {
            when: (row) => row["tipoDolar"] === "exterior",
            style: {
                backgroundColor: "#D2F2EE",
            },
        },
    ],
});


const columnsTransferenciasDolares: TableColumn<RowTransferenciaDolar>[] = [
    crearColumna((row) => row.fecha, "Fecha"),
    crearColumna((row) => row.hora, "Hora"),
    crearColumna((row) => row.cliente, "Cliente"),
    crearColumna((row) => row.origen ? row.origen : "No definido", "Origen"),
    crearColumna((row) => row.ticker, "Ticker", true, 2),
    crearColumna((row) => row.cantidad, "Cantidad", true, 1.5),
    crearColumna((row) => row.estado, "Estado", true, 2),
    crearColumna((row) => row.tipoDolar, "Tipo dolar", true, 2),
    crearColumna((row) => row.detalle, "Detalles", true, 2),
];

export { columnsTransferenciasDolares };
