import { AsideMenus, MenuSection, Rol } from "../interfaces/interfacesAsideBar/interfacesAsideBar";

export const validRoles: Rol[] = ['administrador', 'compliance', 'comercial', 'tesoreria', 'comunicacion', 'operadorDeCarteras', 'operador'];


//Funcion para obtener el menu segun el rol
export function getMenu(userRol: string): MenuSection[] | undefined {
    if (validRoles.includes(userRol as Rol)) {
        const rol = userRol as Rol; // Ahora sabemos que es seguro convertirlo a Rol
        return asideMenus[rol];
    } else {
        console.error(`El rol "${userRol}" no es válido.`);
        return undefined;
    }
}


//Objeto con todos los menus segun el rol
export const asideMenus: AsideMenus = {
    administrador: [
        {
            subTitle: "APERTURA DE \nCUENTA",
            items: [
                {
                    name: "Pendientes",
                    url: "/pendientes"
                },
                {
                    name: "Aprobados",
                    url: "/aprobados"
                },
                {
                    name: "Informados",
                    url: "/informados"
                },
                {
                    name: "Rechazados",
                    url: "/rechazados"
                },
                {
                    name: "Historial de \nmovimientos",
                    url: "/historial"
                },
            ]
        },
        {
            subTitle: "CLIENTES",
            items: [
                {
                    name: "Tenencia",
                    url: "/tenencia"
                },
                {
                    name: "Tabla liquidez blanqueo",
                    url: "/liquidez-blanqueo"
                },
                // {
                //     name: "Estado de rentas",
                //     url: "/estado-rentas"
                // },
                // {
                //     name: "Reinversión renta",
                //     url: "/reinversion-renta"
                // },
                {
                    name: "Pedidos dólar renta",
                    url: "/pedidos-dolares"
                },
                {
                    name: "Estado PIN",
                    url: "/consulta-pin"
                },
                {
                    name: "Autorizar clientes PJ",
                    url: "/agregar-autorizado"
                },
            ]
        },
        {
            subTitle: "ÓRDENES EXTERIOR",
            items: [
                {
                    name: "Operar",
                    url: "/consulta-ordenes"
                },
            ]
        },
        {
            subTitle: "OPERADOR DE \nCARTERA",
            items: [
                {
                    name: "Subir Archivo",
                    url: "/operar-carteras"
                },
                {
                    name: "Órdenes",
                    url: "/ordenes-carteras"
                },
            ]
        },
        {
            subTitle: "TÍTULOS",
            items: [
                {
                    name: "Procesar dólares",
                    url: "/procesar-dolares"
                },
                {
                    name: "Procesar dividendos",
                    url: "/procesar-dividendos"
                },
                {
                    name: "Procesar conciliaciones",
                    url: "/procesar-conciliaciones"
                },
                {
                    name: "Procesar conciliaciones \ntítulos",
                    url: "/procesar-conciliacionesTitulos"
                },
                {
                    name: "Procesar CBUs",
                    url: "/procesar-cbu"
                },
                {
                    name: "Procesar GARA",
                    url: "/procesar-gara"
                },
                {
                    name: "Generar archivo - Pesos",
                    url: "/generador-archivo-pesos"
                },
                {
                    name: "Generar archivo - Dólares",
                    url: "/generador-archivo-dolares"
                },
                {
                    name: "Conciliacion Exterior",
                    url: "/conciliacion-exterior"
                },
            ]
        },
        {
            subTitle: "INGEMATICA",
            items: [
                {
                    name: "Generador de precios",
                    url: "/generador-precios"
                },
                {
                    name: "Generador de \ncotizaciones de moneda ",
                    url: "/generador-cotizaciones"
                },
            ]
        },
        {
            subTitle: "NOTIFICACIONES",
            items: [
                {
                    name: "Historial",
                    url: "/app/historial"
                },
                {
                    name: "Agregar",
                    url: "/app/agregar"
                },
            ]
        },
        {
            subTitle: "TRANFERENCIAS",
            items: [
                {
                    name: "Pedidos",
                    url: "/pedido-transferencia"
                },
            ]
        },
    ],
    compliance: [
        {
            subTitle: "APERTURA DE \nCUENTA",
            items: [
                {
                    name: "Pendientes",
                    url: "/pendientes"
                },
                {
                    name: "Aprobados",
                    url: "/aprobados"
                },
                {
                    name: "Informados",
                    url: "/informados"
                },
                {
                    name: "Rechazados",
                    url: "/rechazados"
                },
                {
                    name: "Historial de \nmovimientos",
                    url: "/historial"
                },
            ]
        },
        {
            subTitle: "CLIENTES",
            items: [
                {
                    name: "Tenencia",
                    url: "/tenencia"
                },
                {
                    name: "Tabla liquidez blanqueo",
                    url: "/liquidez-blanqueo"
                },
                // {
                //     name: "Estado de rentas",
                //     url: "/estado-rentas"
                // },
                // {
                //     name: "Reinversión renta",
                //     url: "/reinversion-renta"
                // },
                {
                    name: "Pedidos dólar renta",
                    url: "/pedidos-dolares"
                },
                {
                    name: "Estado PIN",
                    url: "/consulta-pin"
                },
                {
                    name: "Autorizar clientes PJ",
                    url: "/agregar-autorizado"
                },
            ]
        },
        {
            subTitle: "TRANFERENCIAS",
            items: [
                {
                    name: "Pedidos",
                    url: "/pedido-transferencia"
                },
            ]
        },
    ],
    comercial: [
        {
            subTitle: "APERTURA DE \nCUENTA",
            items: [
                {
                    name: "Pendientes",
                    url: "/pendientes"
                },
                {
                    name: "Aprobados",
                    url: "/aprobados"
                },
                {
                    name: "Informados",
                    url: "/informados"
                },
                {
                    name: "Rechazados",
                    url: "/rechazados"
                },
                {
                    name: "Historial de \nmovimientos",
                    url: "/historial"
                },
            ]
        },
        {
            subTitle: "CLIENTES",
            items: [
                {
                    name: "Tenencia",
                    url: "/tenencia"
                },
                {
                    name: "Tabla liquidez blanqueo",
                    url: "/liquidez-blanqueo"
                },
                // {
                //     name: "Estado de rentas",
                //     url: "/estado-rentas"
                // },
                // {
                //     name: "Reinversión renta",
                //     url: "/reinversion-renta"
                // },
                {
                    name: "Pedidos dólar renta",
                    url: "/pedidos-dolares"
                },
                {
                    name: "Estado PIN",
                    url: "/consulta-pin"
                },
                {
                    name: "Autorizar clientes PJ",
                    url: "/agregar-autorizado"
                },
            ]
        },
        {
            subTitle: "ÓRDENES EXTERIOR",
            items: [
                {
                    name: "Operar",
                    url: "/consulta-ordenes"
                },
            ]
        },
        {
            subTitle: "TRANFERENCIAS",
            items: [
                {
                    name: "Pedidos",
                    url: "/pedido-transferencia"
                },
            ]
        },
    ],
    tesoreria: [
        {
            subTitle: "APERTURA DE \nCUENTA",
            items: [
                {
                    name: "Pendientes",
                    url: "/pendientes"
                },
                {
                    name: "Aprobados",
                    url: "/aprobados"
                },
                {
                    name: "Informados",
                    url: "/informados"
                },
                {
                    name: "Rechazados",
                    url: "/rechazados"
                },
                {
                    name: "Historial de \nmovimientos",
                    url: "/historial"
                },
            ]
        },
        {
            subTitle: "ÓRDENES EXTERIOR",
            items: [
                {
                    name: "Operar",
                    url: "/consulta-ordenes"
                },
            ]
        },
        {
            subTitle: "TÍTULOS",
            items: [
                {
                    name: "Pedidos dólar renta",
                    url: "/pedidos-dolares"
                },
                {
                    name: "Procesar dólares",
                    url: "/procesar-dolares"
                },
                {
                    name: "Procesar dividendos",
                    url: "/procesar-dividendos"
                },
                {
                    name: "Procesar conciliaciones",
                    url: "/procesar-conciliaciones"
                },
                {
                    name: "Procesar conciliaciones \ntítulos",
                    url: "/procesar-conciliacionesTitulos"
                },
                {
                    name: "Procesar CBUs",
                    url: "/procesar-cbu"
                },
                {
                    name: "Procesar GARA",
                    url: "/procesar-gara"
                },
                {
                    name: "Generar archivo - Pesos",
                    url: "/generador-archivo-pesos"
                },
                {
                    name: "Generar archivo - Dólares",
                    url: "/generador-archivo-dolares"
                },
                {
                    name: "Conciliacion Exterior",
                    url: "/conciliacion-exterior"
                },
            ]
        },
        {
            subTitle: "INGEMATICA",
            items: [
                {
                    name: "Generador de precios",
                    url: "/generador-precios"
                },
                {
                    name: "Generador de \ncotizaciones de moneda ",
                    url: "/generador-cotizaciones"
                },
            ]
        },
        {
            subTitle: "TRANFERENCIAS",
            items: [
                {
                    name: "Pedidos",
                    url: "/pedido-transferencia"
                },
            ]
        },
    ],
    comunicacion: [
        {
            subTitle: "NOTIFICACIONES",
            items: [
                {
                    name: "Historial",
                    url: "/app/historial"
                },
                {
                    name: "Agregar",
                    url: "/app/agregar"
                },
            ]
        },
    ],
    operadorDeCarteras: [
        {
            subTitle: "OPERADOR DE \nCARTERA",
            items: [
                {
                    name: "Subir Archivo",
                    url: "/operar-carteras"
                },
                {
                    name: "Órdenes",
                    url: "/ordenes-carteras"
                },
            ]
        },
        {
            subTitle: "NOTIFICACIONES",
            items: [
                {
                    name: "Historial",
                    url: "/app/historial"
                },
                {
                    name: "Agregar",
                    url: "/app/agregar"
                },
            ]
        },
    ],
    operador: [
        {
            subTitle: "APERTURA DE \nCUENTA",
            items: [
                {
                    name: "Pendientes",
                    url: "/pendientes"
                },
                {
                    name: "Aprobados",
                    url: "/aprobados"
                },
                {
                    name: "Informados",
                    url: "/informados"
                },
                {
                    name: "Rechazados",
                    url: "/rechazados"
                },
                {
                    name: "Historial de \nmovimientos",
                    url: "/historial"
                },
            ]
        },
        {
            subTitle: "CLIENTES",
            items: [
                {
                    name: "Tenencia",
                    url: "/tenencia"
                },
                {
                    name: "Tabla liquidez blanqueo",
                    url: "/liquidez-blanqueo"
                },
                // {
                //     name: "Estado de rentas",
                //     url: "/estado-rentas"
                // },
                // {
                //     name: "Reinversión renta",
                //     url: "/reinversion-renta"
                // },
                {
                    name: "Pedidos dólar renta",
                    url: "/pedidos-dolares"
                },
                {
                    name: "Estado PIN",
                    url: "/consulta-pin"
                },
                {
                    name: "Autorizar clientes PJ",
                    url: "/agregar-autorizado"
                },
            ]
        },
        {
            subTitle: "TRANFERENCIAS",
            items: [
                {
                    name: "Pedidos",
                    url: "/pedido-transferencia"
                },
            ]
        },
    ]

}

