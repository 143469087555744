import { date } from '../../../helper/calcularDateTenencia';
import { formatName } from '../../../helper/formatearNombreTenencia';
import { Container, ContainerNameYPaper, ContainerTipoDeCuenta, ContainerInfoYFecha, ContainerLeft, ContainerAzul, ContainerRigth, CuentaInfo, FechaTenencia, Name, DialogoText, TipoDeCuentaText } from './elements';
import { MenuCardInfoUser } from '../MenuCardInfoUser/MenuCardInfoUser';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Paper } from '@mui/material';

export interface DataCard {
    nroCuenta: string;
    userName: string;
}

export const CardInfoUser = ({ nroCuenta, userName }: DataCard) => {
    const { width, height } = useWindowDimensions()

    return (
        <ContainerAzul width={width} height={height}>
            <Container>
                <ContainerRigth>
                    <ContainerInfoYFecha>
                        <CuentaInfo>
                            Cuenta N° {nroCuenta}
                        </CuentaInfo>
                        <FechaTenencia>
                            Tenencia hasta {date()}
                        </FechaTenencia>
                    </ContainerInfoYFecha>
                    <ContainerNameYPaper>
                        <Name>
                            {formatName(userName)}
                        </Name>
                            { 
                                Number(nroCuenta) < 100000 && Number(nroCuenta) >= 90000 &&
                                    <Paper elevation={3} sx={{width: "4em", paddingBlock: "4px"}}>

                                            <DialogoText>CCERA </DialogoText>

                                        {/* <DialogoText>dd/mm/aaaa - 3 dias</DialogoText>  */}
                                    </Paper>
                            }
                    </ContainerNameYPaper>
                </ContainerRigth>
                <ContainerLeft>
                    <ContainerTipoDeCuenta>
                        {/* //!  esto se comenta hasta que ingematica mande la respuesta  
                        <TipoDeCuentaText>Cuenta administrada: Cartera agresiva </TipoDeCuentaText>
                        <TipoDeCuentaText> opera en pesos en el mercado local </TipoDeCuentaText> */}
                    </ContainerTipoDeCuenta> 
                    <MenuCardInfoUser nroCuenta={nroCuenta}/>
                </ContainerLeft>  
            </Container>
        </ContainerAzul>
    )
}