import { GetTransferenciasArchivoResponse, TransferenciasGenArchivo } from "../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos.js";
import apiInstance from "../utils/Axios.js";

export async function getTransferenciasArchivo() {
	return await apiInstance.get<GetTransferenciasArchivoResponse>("/generarArchivoCaja/transferencias");
}

export async function saveTransferenciasArchivo(dataOperaciones: TransferenciasGenArchivo[]) {
	return await apiInstance.post("/generarArchivoCaja/guardarTransferencias", { dataOperaciones });
}

export async function deleteTransferenciasArchivo(ids: string[]) {
	return await apiInstance.post("/generarArchivoCaja/borrarTransferencia", { ids });
}

export async function deleteAllTransferenciasArchivo() {
	return await apiInstance.delete("/generarArchivoCaja/borrarTransferencias");
}

export async function generarArchivoCaja(tipoTransferencias: string, dataOperaciones: TransferenciasGenArchivo[]) {
	return await apiInstance.post("/generarArchivoCaja", {
		tipoTransferencias,
		dataOperaciones
	});
}
