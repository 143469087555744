import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import TableBlanqueo from "../../components/TableBlanqueo/TableBlanqueo";
import { columnsLiquidezBlanqueo } from "../../helper/Colums/columsLiquidezBlanqueo";

const subtitulo = "LIQUIDEZ CCERAS";

export const LiquidezBlanqueoTabla = () => {

	const { width } = useWindowDimensions();

	return (
		<>
			<Container style={{ width: width * 0.85, left: width * 0.15, }}>
				<TableBlanqueo subtitulo={subtitulo} columns={columnsLiquidezBlanqueo} />
			</Container>
		</>
	);
};
