import styled from "styled-components";

export const Container = styled.div<{ width: number; height: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width * 0.85}px;
    height: ${(props) => props.height * 0.92}px;
    left: ${(props) => props.width * 0.15}px;
    top: ${(props) => props.height * 0.062}px;
`;

export const SubtituloContainer = styled.div`
    white-space: nowrap;
    margin-left: 2em;
`;

export const Subtitulo = styled.h1`
    font-size: 1.5em;
    color: rgba(0, 75, 111, 0.75);
`;

export const SearchContainer = styled.input`
    display: flex;
    align-items: center;
    height: 2.3em;
    width: 85%;
    margin-left: 1em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;

export const SearchContainerSinInput = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 2.3em;
    width: 85%;
    margin-left: 4em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;

export const SearchButton = styled.button`
    border-radius: 2em;
    background: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding-right: 1em;
`;

export const ContainerLoading = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 100%;
`;

export const SecondContainer = styled.div`
    display: flex; 
    flex-direction: column;
`;

export const CardsInfoUserContainer = styled.div`
    margin: 1em 0em 0em 3.8em;
    width: 85%;
`;

export const CardsInfoCuentaontainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 1em 0em 0em 3.8em;
    width: 85%;
`;

export const TenenciaContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0.8em 0em 0em 3.8em;
    gap: 20px;
    width: 85%;
    height: 6em;
    flex-grow: 1;
`;

export const ContainerEspecies = styled.div`
    background-color: white;
    overflow-y: scroll;
    border-radius: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const ContainerError = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 80%;
`;

export const TextoError = styled.p`
    font-size: 1.5em;
    font-weight: bold;
`;


export const TabContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    margin-top: 0.8rem;
    flex-wrap: wrap;
    max-width: 86%; 
    overflow-x: auto; 
    margin-left: 3em;
    margin-bottom: -0.5rem;
`;


export const Tab = styled.button<{ active: boolean }>`
    color: ${props => (props.active ? '#0099cc' : '#666')}; // Azul para el activo, gris para el resto
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
    padding-inline: 1rem; 
    max-width: 100%;
    white-space: nowrap; 
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    height: 2.5rem;

    &:hover {
        color: #0099cc;
    }

    &::after {
        content: '';
        position: absolute;
        right: -0.5rem;
        top: 50%;
        transform: translateY(-50%);
        height: 2rem;
        width: 1px;
        background: #0099cc;
    }

    &:last-child::after {
        display: none; // El último tab no necesita separador
    }
`;