import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ButtonsContainer, Input, Label, LabelContainer, LogoAdjuntar, Select, SelectContainer } from "./elements";
import { procesarOrdenesExterior } from "../../../api/ordenesExterior";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 370,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    padding: '2em 1em'
};
const styleButtons = {
    width: "130px",
    height: "35px",
    fontWeight: "bold",
    borderRadius: '5px',
    color: 'white',
};

interface Props {
    open: boolean,
    setOpen: (param: boolean) => void,
}

export default function ModalSubirArchivo({ open, setOpen }: Props) {

    const { width, height } = useWindowDimensions();

    const handleCerrar = () => {
        setArchive(null);
        setMercado('');
        setOpen(false);
    };

    const [archive, setArchive] = useState<File | null>(null);
    const [mercado, setMercado] = useState<string>('');

    const handleSubmit = async () => {
        if (mercado.length !== 0) {
            try {
                const resp = await procesarOrdenesExterior(
                    archive,
                    mercado
                );
                // Crear un objeto Blob a partir de los datos recibidos
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = URL.createObjectURL(blob);

                // Crear un enlace temporal para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.download = 'archivo.xlsx'; // Nombre del archivo descargado
                document.body.appendChild(link);
                link.click();

                // Limpiar el enlace temporal
                link.remove();
                URL.revokeObjectURL(url);
                if (resp.status === 200) {
                    handleCerrar();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleCerrar}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        fontWeight: "bold",
                        // textAlign: "center",
                        lineHeight: 1.2,
                        width: 300,
                        margin: "0em 0 0 0",
                    }}
                >
                    Subir archivo
                </Typography>

                <SelectContainer>
                    <Select
                        onChange={(e) => setMercado(e.target.value)}
                        name="cuitVerificado"
                        value={mercado}
                    >
                        <option value="" disabled hidden>
                            Selecciona
                        </option>

                        <option value='SCHWAB'>
                            SCHWAB
                        </option>
                        <option value='INTERACTIVE'>
                            Interactive brokers
                        </option>
                    </Select>
                </SelectContainer>

                <LabelContainer>
                    <Label
                        htmlFor='archivo'
                    >
                        {archive ?
                            <LogoAdjuntar
                                src={require(`../../../images/documentos/adjuntar-dorado.png`)}
                                alt="logo"
                            />
                            :
                            <LogoAdjuntar
                                src={require(`../../../images/documentos/adjuntar.png`)}
                                alt="logo"
                            />}

                        SUBIR ARCHIVO (CSV)
                    </Label>
                    <Input
                        type="file"
                        accept=".csv"
                        id='archivo'
                        onChange={(e) =>
                            setArchive(e.target.files?.[0] || null)
                        }
                    />
                </LabelContainer>

                <ButtonsContainer>
                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#004B6F",
                            fontSize: "0.8em",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                        size="large"
                        disabled={archive ? false : true}
                    >
                        DESCARGAR
                    </Button>

                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#C9C9C9",
                            fontSize: "0.8em",
                        }}
                        onClick={handleCerrar}
                        variant="contained"
                        size="large"
                    >
                        CANCELAR
                    </Button>
                </ButtonsContainer>
            </Box>
        </Modal>
    );
}
