import { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { procesarConciliaciones } from "../api/conciliaciones";
import { ConciliacionesContext } from "../context/conciliacionesContext/conciliacionesContext";

export const useConciliaciones = () => {
	const [cajaArchive, setCajaArchive] = useState<File | null>(null);
	const [galloArchive, setGalloArchive] = useState<File | null>(null);
	const [errorConciliaciones, setErrorConciliaciones] = useState<string>('');
	const { actualizarConciliaciones } = useContext(ConciliacionesContext);

	// const navigate = useNavigate();
	const handleSubmit = async () => {
		try {
			const resp = await procesarConciliaciones(cajaArchive, galloArchive);
			actualizarConciliaciones(resp.data.body);
		} catch (error) {
			console.log(error);
			setErrorConciliaciones('Ocurrió un error');
		}
	};

	return {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		galloArchive,
		setGalloArchive,
		errorConciliaciones,
	};
};
