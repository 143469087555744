import React, { useRef, useState } from "react";
import { generarArchivoCotizaciones } from "../api/cotizaciones";


export const useGeneradorArchivo = () => {

	const [name, setName] = useState<string | undefined>(undefined);
	const [archive, setArchive] = useState<File | null>(null);
	// Crear una referencia al input de archivo
    const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setArchive(e.target.files?.[0] || null)
		setName(e.target.files?.[0].name)

		// Reiniciar el valor del input después de procesar
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}

	}

	const handleDownload = async () => {
        if (archive !== null) {
            try {
                const resp = await generarArchivoCotizaciones(
                    archive,
                );
                // Crear un objeto Blob a partir de los datos recibidos
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = URL.createObjectURL(blob);

                // Crear un enlace temporal para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.download = 'archivoCotizacion.xlsx'; // Nombre del archivo descargado
                document.body.appendChild(link);
                link.click();

                // Limpiar el enlace temporal
                link.remove();
                URL.revokeObjectURL(url);
                if (resp.status === 200) {
                    setArchive(null);
					setName('');
                }
            } catch (error) {
                console.log(error);
            }
        }
    };


	return {
		name,
        handleDownload,
		archive,
		fileInputRef,
		handleChange,
	};
};
