import styled from "styled-components";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import { styled as muiStyled } from "@mui/material/styles"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 23%;
    height: 20%;
    background-color: white;
    box-shadow: 24;
    border-radius: 1em;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    height: 40%;
    width: 100%;
`;

export const BoxText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60%;
    justify-content: center;
    padding-inline: 2em;
`;

export const Button1 = styled.button`
    color: #00386E;
    font-size: 1.2em;
    font-weight: 400;
    width: 100%;
    border-radius: 0em 0em 0em 1em;
    border: 1px solid #d3d3d3;
    background-color: white;

    &:hover {
        font-weight: 500;
    }
`;

export const Button2 = styled.button`
    color: #00386E;
    font-size: 1.2em;
    font-weight: 400;
    width: 100%;
    border-radius: 0em 0em 1em 0em;
    border: 1px solid #d3d3d3;
    background-color: white;

    &:hover {
        font-weight: 500;
    }
`;

export const Button3 = styled.button`
    color: #00386E;
    font-size: 1.2em;
    font-weight: 400;
    width: 1em;
    margin-right: 1em;
    border: none;
    background-color: white;

    &:hover {
        font-weight: 500;
    }
`;

export const BoxText2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60%;

`;

export const ExitoText = styled.h3`
    margin-top:0.5em;
    text-align: center;
`;

export const PrimerText = styled.h3`
    margin-top:0.5em;
    text-align: center;
`;

export const MuiIcon1 = muiStyled(CheckBoxOutlinedIcon)(({ theme }) => ({
    width: "1.5em",
    height:"1.5em",
    marginTop: "0px",
})) 

export const MuiIcon2 = muiStyled(BackHandOutlinedIcon)(({ theme }) => ({
    width: "1.5em",
    height:"1.5em",
    marginTop: "0px",
})) 