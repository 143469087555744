import type * as XLSX from "xlsx"
import { getDatosPorFecha } from "../api/conciliacionExterior"
import { RowConciliacionesExterior } from "../interfaces/interfacesRows/rows"

export const useSchwabBroker = () => {
    const processSchwabData = (worksheet: XLSX.WorkSheet, jsonData: any[]) => {
        const celdaA1: string = worksheet["A1"] ? worksheet["A1"].v : "";

    
        let posibleFecha = "";
    
        // Verificar si jsonData[0] tiene al menos 2 elementos y el segundo es un string
        if (Array.isArray(jsonData[0]) && jsonData[0].length > 1 && typeof jsonData[0][1] === "string") {
            posibleFecha = jsonData[0][1]; // Extraer la fecha
        } 
    
        if (!celdaA1.startsWith("Positions")) {
            throw new Error("Archivo no válido para Schwab.");
        }
    
        const fechaSchwab = convertirFechaSchwab(celdaA1, posibleFecha);
        const schwabData = jsonData.slice(4, -2);
    
        return construirDataTotal("SCHWAB", fechaSchwab, schwabData);
    };
    
    const convertirFechaSchwab = (a1: string, posibleFecha: string): string => {
        const fechaRegex = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
    
        // Buscar fecha en A1 si contiene una coma
        if (a1.includes(",")) {
            const partes = a1.split(",");
            const fechaMatch = partes[1].trim().match(fechaRegex);
    
            if (fechaMatch) {
                const [, mm, dd, yyyy] = fechaMatch;
                return `${dd}/${mm}/${yyyy.length === 2 ? "20" + yyyy : yyyy}`;
            }
        }
    
        // Buscar en la posibleFecha obtenida de jsonData
        const fechaMatchPosible = posibleFecha.trim().match(fechaRegex);
        if (fechaMatchPosible) {
            const [, mm, dd, yyyy] = fechaMatchPosible;
            return `${dd}/${mm}/${yyyy.length === 2 ? "20" + yyyy : yyyy}`;
        }
    
        throw new Error("No se encontró una fecha válida.");
    };

    const construirDataTotal = (tipoBroker: string, fecha: string, jsonData: any[]): any => {
        const tenenciaExterior = jsonData.map((row: any) => (
            {
            
        symbol: row[0],
        quantity: Number.parseFloat(row[2].replace(",", "")),
        }))

        return {
        tenenciaExterior: tenenciaExterior,
        fecha: fecha,
        mercado: tipoBroker,
        }
    }

    return {
        processSchwabData,
    }
}

export const useInteractiveBroker = () => {
    const convertirFecha = (fecha: string): string => {
        const meses: Record<string, string> = {
        Enero: "01",
        Febrero: "02",
        Marzo: "03",
        Abril: "04",
        Mayo: "05",
        Junio: "06",
        Julio: "07",
        Agosto: "08",
        Septiembre: "09",
        Octubre: "10",
        Noviembre: "11",
        Diciembre: "12",
        }

        return (
        fecha.replace(
            /^([A-Za-z]+) (\d{1,2}), (\d{4})$/,
            (_, mes, dia, año) => `${dia.padStart(2, "0")}/${meses[mes]}/${año}`,
        ) || ""
        )
    }

    const processInteractiveData = (worksheet: XLSX.WorkSheet, jsonData: any[]) => {
        const celda2: string = worksheet["D2"] ? worksheet["D2"].v : ""
        if (!celda2.startsWith("Interactive")) {
        throw new Error("Archivo no válido para Interactive.")
        }

        const celda1: string = worksheet["D5"] ? worksheet["D5"].v : ""
        const fechaInteractive = convertirFecha(celda1)
        const interactiveData = jsonData.slice(7, -7)

        return construirDataTotal("INTERACTIVE", fechaInteractive, interactiveData)
    }

    const construirDataTotal = (tipoBroker: string, fecha: string, jsonData: any[]): any => {
        const tenenciaExterior = jsonData.map((row: any) => ({
        symbol: row[5],
        quantity: Number.parseFloat(row[6].replace(",", ".")),
        }))

        return {
        tenenciaExterior: tenenciaExterior,
        fecha: fecha,
        mercado: tipoBroker,
        }
    }

    return {
        processInteractiveData,
    }
}

export const extraccionDeFechas = (documentosModificados: any) => {
    return documentosModificados.reduce((acc: { fechasSchwab: any[]; fechasInteractive: any[] }, doc: any) => {
        if (doc.mercado === "SCHWAB") acc.fechasSchwab.push(doc);
        if (doc.mercado === "INTERACTIVE") acc.fechasInteractive.push(doc);
        return acc;
        },
        { fechasSchwab: [], fechasInteractive: [] }
    )
}

export const loadLatestData = async (fechasClasificadas: any) => {
    const ultimaSchwab = fechasClasificadas.fechasSchwab.length
        ? fechasClasificadas.fechasSchwab.sort(
            (a: any, b: any) => b.fechaObjeto.getTime() - a.fechaObjeto.getTime()
        )?.[0]?._id
        : null;

    const ultimaInteractive = fechasClasificadas.fechasInteractive.length
        ? fechasClasificadas.fechasInteractive.sort(
            (a: any, b: any) => b.fechaObjeto.getTime() - a.fechaObjeto.getTime()
        )?.[0]?._id
        : null;

        const conciliacionesSchwab = ultimaSchwab
        ? (await getDatosPorFecha(ultimaSchwab)) as { arrayTenencia: { symbol: string, tenenciaSistema: number, tenenciaExterior: number, diferencia: number }[] }
        : { arrayTenencia: [] };
    
    const conciliacionesInteractive = ultimaInteractive
        ? (await getDatosPorFecha(ultimaInteractive)) as { arrayTenencia: { symbol: string, tenenciaSistema: number, tenenciaExterior: number, diferencia: number }[] }
        : { arrayTenencia: [] };
    
    // 🔹 Transformamos los datos al tipo RowConciliacionesExterior
    const conciliacionesSchwabTransformadas: RowConciliacionesExterior[] =
        conciliacionesSchwab.arrayTenencia.map((item) => ({
        symbol: item.symbol,
        tenenciaSistema: item.tenenciaSistema,
        tenenciaExterior: item.tenenciaExterior,
        diferencia: item.diferencia
    }));

    const conciliacionesInteractiveTransformadas: RowConciliacionesExterior[] =
        conciliacionesInteractive.arrayTenencia.map((item) => ({
            symbol: item.symbol,
            tenenciaSistema: item.tenenciaSistema,
            tenenciaExterior: item.tenenciaExterior,
            diferencia: item.diferencia
    }));

    return {
        conciliacionesSchwab: conciliacionesSchwabTransformadas,
        conciliacionesInteractive: conciliacionesInteractiveTransformadas,
        especiesSchwab: Array.from(new Set(conciliacionesSchwab.arrayTenencia.map((item) => item.symbol))),
        especiesInteractive: Array.from(new Set(conciliacionesInteractive.arrayTenencia.map((item) => item.symbol))),
    };
};
