import React from "react";
import { useState, useContext, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { signin } from "../../api/auth";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { Button, Container, Form, InputContainer, TextButton, Title, TitleContainer } from "./elements";
import { IconButton } from "@mui/material";

interface ObjectForm {
	email: string,
	password: string,
}

interface Erros {
	email?: string,
}

function validate(form: ObjectForm) {
	let errors: Erros = {};

	let reEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

	if (!form.email) {
		errors.email = "El campo 'Email' es requerido";
	} else if (!reEmail.test(form.email.trim())) {
		errors.email = "El email debe ser válido";
	}

	return errors;
}

const LoginForm = () => {
	const [form, setForm] = useState<ObjectForm>({
		email: "",
		password: "",
	});
	const [errors, setErrors] = useState<Erros>({});
	const [errorLogin, setErrorLogin] = useState("");
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const { signinAction, cambiarAppbar } = useContext(UserContext);


	const handleClickShowPassword = () => setShowPassword((show) => !show);

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});

		if (Object.keys(errors).length > 0) {
			setErrors(
				validate({
					...form,
					[name]: value,
				})
			);
		}
	}

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { name, value } = e.target as HTMLFormElement;

		setErrors(
			validate({
				...form,
				[name]: value,
			})
		);

		if (form.email && form.password && Object.keys(errors).length === 0) {
			try {
				const res = await signin(form.email, form.password);
				localStorage.setItem("token", res.data.body.token);
				let rol = res.data.body.rol;

				signinAction(form.email, rol)
				cambiarAppbar(true)

				if (rol === roles.comunicacion) {
					navigate("/app/historial");
				} else if (rol === roles.operadorDeCarteras) {
					navigate("/ordenes-carteras");
				} else if (rol === roles.tesoreria) {
					navigate("/procesar-dolares")
				} else {
					navigate("/pendientes");
				}


			} catch (error) {
				setErrorLogin("Usuario o contraseña incorrectos");
			}
		}
	}

	return (
		<Container>
			<TitleContainer>
				<Title>ADMINISTRACIÓN</Title>
			</TitleContainer>

			<Form onSubmit={handleSubmit} >
				<InputContainer>
					<TextField
						name="email"
						type="text"
						variant="outlined"
						size="small"
						fullWidth
						placeholder="Email"
						value={form.email}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EmailOutlinedIcon
										sx={{ color: "rgba(0, 75, 111, 0.75)" }}
									/>
								</InputAdornment>
							),
						}}
					/>
				</InputContainer>
				<InputContainer>
					<TextField
						name="password"
						type={showPassword ? "text" : "password"}
						variant="outlined"
						size="small"
						fullWidth
						placeholder="Contraseña"
						value={form.password}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LockOutlinedIcon
										sx={{ color: "rgba(0, 75, 111, 0.75)" }}
									/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<div>
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => handleClickShowPassword()}
											edge="end"
											tabIndex={-1}
										>
											{showPassword ? (
												<VisibilityOffOutlined sx={{ color: '#004B6F' }} />
											) : (
												<VisibilityOutlined sx={{ color: '#004B6F' }} />
											)}
										</IconButton>
									</div>
								</InputAdornment>
							)
						}}
					/>

				</InputContainer>
				{errorLogin ? (
					<div style={{ marginBottom: 8 }}>
						<span>{errorLogin}</span>
					</div>
				) : null}
				<Button>
					<TextButton>
						INICIAR SESIÓN
					</TextButton>
				</Button>
			</Form>
		</Container>
	);
};

export default LoginForm;
