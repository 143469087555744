import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1.5em;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Text = styled.p`
    color: #00386E;
    font-size: 1em;
    margin-top: 0.5em;
`;

export const BoldText = styled.p`
    color: #00386E;
    font-size: 1em;
    margin-top: 0.5em;
    font-weight: bold;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 2em;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    /* justify-content: space-around; */
    /* margin-top: 2em; */
`;

export const Input = styled.input`
    margin-top: 0.5em;
    margin-left: 0.5em;
    width: 50%;
    background: #ecf5ff;
    border: none;
	outline:none;
	border-radius: 5px;
	/* padding: 0.2em; */
	color: #00386E;	
	font-size: 0.9em;
`;

export const TextArea = styled.textarea`
    margin-top: 0.5em;
    width: 150%;
    background: #ecf5ff;
    border: none;
	outline:none;
	border-radius: 5px;
	padding: 0.2em;
	color: #00386E;	
	font-size: 0.9em;
`;
