import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

export const TextRow = styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
    height: "100%",
    padding: "0",
    margin: "0",
})) 

export const TypographyMenu = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    height: "1em",
    paddingInline: "0.5em",
    marginBlock: "1em",
})) 