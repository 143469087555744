import { useContext, useState } from "react";
import { SelectChangeEvent } from '@mui/material/Select';
import { procesarConciliacionesTitulos } from "../api/conciliaciones";
import { ConciliacionesContext } from "../context/conciliacionesContext/conciliacionesContext";

export const useConciliacionesTitulos = () => {
	const [cajaArchive, setCajaArchive] = useState<File | null>(null);
	const [galloArchive, setGalloArchive] = useState<File | null>(null);
	const { actualizarConciliacionesTitulos } = useContext(ConciliacionesContext);
	const [tipoProcesamiento, setTipoProcesamiento] = useState<string>('');

	const handleChange = (event: SelectChangeEvent) => {
		setTipoProcesamiento(event.target.value);
	};
	
	const handleSubmit = async () => {
		if(cajaArchive && galloArchive && tipoProcesamiento){
			try {
				const resp = await procesarConciliacionesTitulos(cajaArchive, galloArchive, tipoProcesamiento);
				actualizarConciliacionesTitulos(resp.data.body.resultado);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		galloArchive,
		setGalloArchive,
		tipoProcesamiento, 
		handleChange,
	};
};
