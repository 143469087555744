import { Modal } from '@mui/material'
import { useState } from 'react'
import { BoxText, BoxText2, Button1, Button2, Button3, ButtonsContainer, Container, ExitoText, MuiIcon1, MuiIcon2, PrimerText } from './elements';
import { BajaUsuario, ConsultarUsuario } from '../../api/DarDeBajaUsuario';
import { asideMenus } from '../../helper/helperAside';

interface DarDeBajaProps {
    open: boolean;
    setOpen: (param: boolean) => void;
    nroDeCuenta:string
}
export const ModalDarDeBaja = ({open, setOpen, nroDeCuenta}:DarDeBajaProps) => {

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [secondModalOpen, setSecondModalOpen] = useState(false);
    
    const handleCerrar = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            const cuentaNumber = parseInt(nroDeCuenta)
            const resp = await ConsultarUsuario(cuentaNumber)
            if(resp.data.body.length !== 0){
                BajaUsuario(cuentaNumber)
                setOpen(false)
                setSuccessModalOpen(true)
            }else{
                setOpen(false)
                setSecondModalOpen(true)
            }
        } catch (error) {
            console.error("Error al dar de baja:", error)
        }
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false)
        setSecondModalOpen(false)
    };

    return (
        <div>
            <Modal open={open} onClose={handleCerrar}>
                <Container>
                    <BoxText>
                        <PrimerText>
                            ¿Querés dar de baja al N° de cuenta "{nroDeCuenta}"?
                        </PrimerText>
                    </BoxText>
                    <ButtonsContainer>
                        <Button1 onClick={handleCerrar}>
                            Cancelar
                        </Button1>
                        <Button2 onClick={handleSubmit}>
                            Aceptar
                        </Button2>
                    </ButtonsContainer>
                </Container>
            </Modal>
            <Modal open={successModalOpen} onClose={handleSuccessModalClose}>
                <Container>
                    <div style={{display: "flex" , width: "100%", justifyContent: "end"}}>
                        <Button3 onClick={handleSuccessModalClose}>X</Button3>
                    </div>
                    <BoxText2>
                        <MuiIcon1/>
                        <ExitoText>
                            ¡Se dio de baja con éxito!
                        </ExitoText>
                    </BoxText2>
                </Container>
            </Modal>
            <Modal open={secondModalOpen} onClose={handleSuccessModalClose}>
                <Container>
                    <div style={{display: "flex" , width: "100%", justifyContent: "end"}}>
                        <Button3 onClick={handleSuccessModalClose}>X</Button3>
                    </div>
                    <BoxText2>
                        <MuiIcon2/>
                        <ExitoText>
                            ¡Este cliente no existe!
                        </ExitoText>
                    </BoxText2>
                </Container>
            </Modal>
        </div>    
    )
}


