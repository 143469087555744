import { ChangeEvent, useEffect, useState } from "react";
import { deleteAllTransferenciasArchivo, deleteTransferenciasArchivo, generarArchivoCaja, getTransferenciasArchivo, saveTransferenciasArchivo } from "../api/transferencias_archivo";
import { TransferenciasGenArchivo } from "../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos";
import { read, utils, WorkSheet, writeFile } from "xlsx";

export interface DataDeliver {
	Comitente: string, "Tipo Requerimiento": string, "Objeto Negociable": string, Exigido: string, Exigible: string, Cubierto: string, "No Cubierto": string
}
export interface DataReceive {
	Comitente: string, Instrumento: string, Retirable: string, Disponible: string, Utilizado: string, Total: string
}

export const useGenerarArchivo = () => {
	const [tipoTransferencias, setTipoTransferencias] = useState<string>('LIQUIDACIÓN')
	const [transferenciasTable, setTransferenciasTable] = useState<TransferenciasGenArchivo[]>([])
	const [seleccionados, setSeleccionados] = useState<string[]>([])


	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files) {
			// setName(e.target.files[0].name);
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target) {
					const data = e.target.result;
					const workbook = read(data, { type: "array" });
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];
					const nameFirstColumn = worksheet.A1.v
					console.log(nameFirstColumn)
					deleteRow(worksheet, 0)
					if (nameFirstColumn === "Garantia Disponible") {
						const json: DataReceive[] = utils.sheet_to_json(worksheet);
						// console.log(mapearExcel(json))
						setTransferenciasTable(mapearExcelReceive(json))
					} else {
						const json: DataDeliver[] = utils.sheet_to_json(worksheet);
						// console.log(mapearExcel(json))
						setTransferenciasTable(mapearExcelDeliver(json))
					}
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	function mapearExcelReceive(arr: DataReceive[]): TransferenciasGenArchivo[] {
		const arrFilt = arr.filter(a => !a['Instrumento'].includes("Pesos") && !a['Instrumento'].includes("Dólar"));
		const arrMapeado = arrFilt.map(e => {
			// Realiza la coincidencia
			const match = e['Instrumento'].match(/((\d+))/);

			return {
				_id: generateUniqueId(),
				__v: 0,
				origen: "9180/" + e.Comitente,
				destino: "180/" + e.Comitente,
				tipoMovimiento: "DELIVER",
				cantidad: e['Disponible'],
				fecha: getTodayDate(),
				instrumento: match ? match[1] : "" //
			};
		});
		return arrMapeado;
	}

	function mapearExcelDeliver(arr: DataDeliver[]): TransferenciasGenArchivo[] {
		const arrFilt = arr.filter(a => !a['Tipo Requerimiento'].includes("Pesos"));
		const arrMapeado = arrFilt.map(e => {
			// Realiza la coincidencia
			const match = e['Tipo Requerimiento'].match(/((\d+))/);

			return {
				_id: generateUniqueId(),
				__v: 0,
				origen: "180/" + e.Comitente,
				destino: "9180/" + e.Comitente,
				tipoMovimiento: "DELIVER",
				cantidad: e['No Cubierto'],
				fecha: getTodayDate(),
				instrumento: match ? match[1] : "" //
			};
		});
		return arrMapeado;
	}


	const ec = (r: number, c: number): string => {
		return utils.encode_cell({ r, c });
	}

	// Función para eliminar una fila en la hoja de trabajo
	const deleteRow = (ws: WorkSheet, rowIndex: number): void => {
		const range = utils.decode_range(ws["!ref"] || "A1"); // Obtiene el rango de celdas
		for (let R = rowIndex; R < range.e.r; ++R) {
			for (let C = range.s.c; C <= range.e.c; ++C) {
				ws[ec(R, C)] = ws[ec(R + 1, C)];
			}
		}
		range.e.r--; // Reduce el rango de filas
		ws['!ref'] = utils.encode_range(range.s, range.e); // Actualiza el rango en la hoja de trabajo
	}

	async function saveApi() {
		await saveTransferenciasArchivo(transferenciasTable)
	}

	async function deleteController() {
		if (seleccionados.length === transferenciasTable.length) {
			await deleteAllTransferenciasArchivo()
			setTransferenciasTable([])
		} else if (seleccionados.length > 0) {
			await deleteTransferenciasArchivo(seleccionados)
			await getAllTransferencias()
		}
	}

	async function downloadArchive() {
		const tipoTransferenciasApi = tipoTransferencias === "LIQUIDACIÓN" ? "LIQUIDACION" : "GARANTIA"
		const datosProcesar = transferenciasTable.filter(a => seleccionados.some(value => value === a._id))

		if (datosProcesar.length > 0) {
			const archiveResponse = await generarArchivoCaja(tipoTransferenciasApi, datosProcesar)

			const blob = new Blob([archiveResponse.data], {
				type: 'application/SI2',
			});

			// Crear una URL para el Blob
			const blobUrl = window.URL.createObjectURL(blob);

			// Crear un elemento <a> para la descarga
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = 'TRANSFERENCIAS.SI2'; // Nombre del archivo que se descargará

			// Simular un clic en el enlace
			document.body.appendChild(link);
			link.click();

			// Eliminar el enlace después de la descarga
			document.body.removeChild(link);

			// Revocar el objeto URL
			window.URL.revokeObjectURL(blobUrl);

		}
	}


	const handleChangeTipoTransferencia = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		setTipoTransferencias(value);
	}

	useEffect(() => {
		getAllTransferencias()
	}, [])

	const getAllTransferencias = async () => {
		try {
			const res = await getTransferenciasArchivo();
			setTransferenciasTable(res.data.body)
		} catch (error) {
			console.log(error)
		}
	}

	const addItem = () => {
		let arr = [
			{
				origen: "", destino: "", tipoMovimiento: "DELIVER", instrumento: "", cantidad: "", fecha: getTodayDate(), _id: generateUniqueId(), __v: 0
			},
			...transferenciasTable,]
		setTransferenciasTable(arr)
	}

	function generateUniqueId(): string {
		let newId: string;

		do {
			newId = Array.from(crypto.getRandomValues(new Uint8Array(12)))
				.map((byte) => byte.toString(16).padStart(2, '0'))
				.join('');
		} while (transferenciasTable.some((transferencia) => transferencia._id === newId));

		return newId;
	}

	function getTodayDate(): string {
		const today = new Date();
		const day = String(today.getDate()).padStart(2, '0');
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11, así que sumamos 1
		const year = today.getFullYear();

		return `${day}/${month}/${year}`;
	}

	return {
		transferenciasTable,
		tipoTransferencias,
		handleChangeTipoTransferencia,
		setTransferenciasTable,
		addItem,
		saveApi,
		downloadArchive,
		deleteController,
		setSeleccionados,
		handleUpload
	};
};
