import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Button as MaterialButton } from '@mui/material';
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { TableColumn } from 'react-data-table-component';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useTableHook from "../../hooks/useTableHook";
import { roles } from "../../helper/roles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { CriterioContainer, DataBox, InputSearch, PlusIconContainer, PlusTexto, SearchButton, SearchContainer, Sub, SubContainer, RowContent, ButtonContainer, CheckContainer, CheckText, Button } from "./elements";
import ModalEstadoExterior from "./ModalEstadoExterior/ModalEstadoPedidoDolar";
import ModalSubirArchivo from "./ModalSubirArchivo/ModalSubirArchivo";
import { baseURL } from "../../utils/Axios";

// Tabla que se renderiza en las diferentes screens

interface Props {
    subtitulo: string,
    titularId?: string,
    columns: TableColumn<any>[],
    filtroTenencia?: string
}

export const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 18,
	},
	"& .css-1cjpqna-MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
		backgroundColor: "white",
	},
}));

export default function Table({ subtitulo, columns, titularId, filtroTenencia }: Props) {
    const { width, height } = useWindowDimensions();
    const {
        handleInput,
        handleSubmit,
        data,
        paginationOpciones,
        loading,
        userRol,
        handleOpenEstadoCuit,
        cuitOpciones,
        openEstadoCuit,
        handleClickEstadoCuit,
        handleCloseEstadoCuit,
        selectedIndex,
        name,
        ordenesChecked,
        handleOpenModalEstadoExterior,
        openModalEstadoExterior, 
		setOpenModalEstadoExterior,
        showOnlyPendientes,
        setShowOnlyPendientes,
        openModalSubirArchivo,
		setOpenModalSubirArchivo,
		handleOpenModalSubirArchivo,
    } = useTableHook({ subtitulo, titularId: titularId || '', filtroTenencia,});

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: subtitulo === "PEDIDOS DOLARES TENENCIA" ? '6em' : height * 0.75,
                maxHeight: "33em",
                // minWidh: "30em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
                // borderRadius: "20px"
            },
        },

        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "58px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
                //wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            },
        },
    };

    let altura = subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS" ?
        `${height * 0.65}px` : `${height * 0.75}px`;


    let widthContainer = width * 0.84;
    let heightContainer = height * 0.85;

    const url = `${baseURL}/exportarAprobadas`;

    return (
        <div style={{ width: widthContainer, height: heightContainer, }}>
                <DataBox>
                <SubContainer>
                    <Sub>{subtitulo}</Sub>
                    {
                        subtitulo === "ÓRDENES A OPERAR" &&
                        <Button>
                            <a href={url} target="_blank" rel="noreferrer">
                                <Icon
                                    icon="lucide:download"
                                    style={{ ...styles.logo, color: "#004B6F" }}
                                />
                            </a>
                        </Button>
                    }
                        
                    </SubContainer>
                    <div style={{ display: "flex" }}>
                        {userRol === roles.compliance && subtitulo === "APROBADOS" && (
                            <>
                                <Link to={"/agregar-juridica"} style={styles.links}>
                                    <PlusIconContainer>
                                        <Icon
                                            icon="ph:plus-bold"
                                            style={styles.plusIcon}
                                        />
                                        <PlusTexto>
                                            Agregar persona jurídica
                                        </PlusTexto>
                                    </PlusIconContainer>
                                </Link>
                            </>
                        )}

                        {userRol === roles.comercial && subtitulo === "PENDIENTES" && (
                            <>
                                <div style={{ marginRight: "1em" }}>
                                    <List component="nav" style={styles.menu}>
                                        <ListItem
                                            onClick={handleOpenEstadoCuit}
                                            style={styles.listItem}
                                        >
                                            <ListItemText
                                                primary={
                                                    cuitOpciones[selectedIndex]
                                                }
                                            />
                                            <Icon
                                                icon="material-symbols:arrow-forward-ios-rounded"
                                                style={{
                                                    width: "1em",
                                                    height: "1em",
                                                    marginLeft: "0.3em",
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                    <StyledMenu
                                        id="lock-menu"
                                        anchorEl={openEstadoCuit}
                                        keepMounted
                                        open={Boolean(openEstadoCuit)}
                                        onClose={handleCloseEstadoCuit}
                                    >
                                        {cuitOpciones?.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                // disabled={index === selectedIndex}
                                                selected={index === selectedIndex}
                                                onClick={(event) =>
                                                    handleClickEstadoCuit(
                                                        event,
                                                        index
                                                    )
                                                }
                                                style={styles.menuItem}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </div>
                                <a
                                    href="https://seti.afip.gob.ar/padron-puc-constancia-internet/ConsultaConstanciaAction.do"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={styles.links}
                                >
                                    <PlusIconContainer>
                                        <Icon
                                            icon="mdi:check-bold"
                                            style={styles.plusIcon}
                                        />
                                        <PlusTexto>
                                            Verificar CUIT en AFIP
                                        </PlusTexto>
                                    </PlusIconContainer>
                                </a>
                            </>
                    )}

                    {
                        subtitulo === "ÓRDENES A OPERAR" &&
                        <CheckContainer>
                            <Checkbox
                                inputProps={{
                                    "aria-label": "controlled",
                                }}
                                onChange={() => setShowOnlyPendientes(!showOnlyPendientes)}
                                checked={showOnlyPendientes}
                                style={{
                                    color: "#004B6F",
                                    margin: 0,
                                }}
                                classes={{
                                    checked: "Mui-checked",
                                }}
                            />
                            <CheckText>Solo las pendientes</CheckText>
                            <MaterialButton
                                id="demo-customized-button"
                                onClick={handleOpenModalSubirArchivo}
                                variant="text"
                                size="small"
                                style={{
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    borderRadius: "5px",
                                    color: "#004B6F",
                                    marginLeft: '1em',
                                }}
                            >
                                <Icon
                                    icon="pajamas:export"
                                    style={styles.exportIcon}
                                />
                                SUBIR ARCHIVO
                            </MaterialButton>
                            <ModalSubirArchivo
                                open={openModalSubirArchivo}
                                setOpen={setOpenModalSubirArchivo}
                            />
                        </CheckContainer>
                    }

                    {
                        (subtitulo === "ÓRDENES A OPERAR" && ordenesChecked.length !== 0) &&
                        <>
                            <MaterialButton
                                id="demo-customized-button"
                                onClick={handleOpenModalEstadoExterior}
                                variant="contained"
                                size="small"
                                style={{
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    borderRadius: "5px",
                                    background: "#004B6F",
                                    color: "white",
                                    padding: "6px 12px",
                                    marginBottom: '6px',
                                    marginRight: '2em',
                                }}
                            >
                                CAMBIAR DE ESTADO
                            </MaterialButton>
                            <ModalEstadoExterior
                                open={openModalEstadoExterior}
                                setOpen={setOpenModalEstadoExterior}
                                ordenesChecked={ordenesChecked}
                            />
                        </>
                    }

                        <SearchContainer>
                            <InputSearch
                                type="text"
                                placeholder="Buscar..."
                                onChange={(e) => handleInput(e)}
                                value={name}
                            />
                            <SearchButton
                                type="submit"
                            >
                                <img
                                    src={require("../../images/tabla/search-icon.png")}
                                    alt="logo"
                                    style={{ width: "1em" }}
                                />
                            </SearchButton>
                        </SearchContainer>
                    </div>
                </DataBox>

            {subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS" && (
                <CriterioContainer>
                    <Link to={"/transferencias/criterio"} style={{ ...styles.links, marginRight: "0.8em", }}>
                        <PlusIconContainer>
                            <Icon
                                icon="ph:plus-bold"
                                style={styles.plusIcon}
                            />
                            <PlusTexto>
                                Agregar nuevo criterio
                            </PlusTexto>
                        </PlusIconContainer>
                    </Link>
                </CriterioContainer>
            )}
                <DataTable
                    columns={columns}
                    data={data!}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                    paginationComponentOptions={paginationOpciones}
                    fixedHeader
                    fixedHeaderScrollHeight={altura}
                    // minHeight={altura}
                    customStyles={customStyles}
                    noDataComponent={
                        loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </Box>
                        ) : (
                            <>No se encontró ningún elemento</>
                        )
                    }
                    responsive
                    keyField="id"
                />
        </div>
    );
}


const styles = {
    links: {
        textDecoration: "none",
        cursor: "pointer",
        marginRight: "2em",
        marginTop: "0.25em",
    },
    menu: {
        display: "flex",
        backgroundColor: "white",
        height: "2.2em",
        borderRadius: "14px",
        padding: 0,
        margin: 0,
    },
    listItem: {
        display: "flex",
        padding: "0.1em 0.5em 0.1em 0.5em",
        margin: 0,
        fontSize: "0.9em",
        cursor: "pointer",
    },
    menuItem: {
        fontSize: "0.9em",
        backgroundColor: "white",
        color: "rgba(0, 75, 111, 0.75)",
    },
    plusIcon: {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
    logo: {
        fontSize: "1.7em",
        color: "rgba(85, 86, 88, 0.75)",
        margin: "0.2em",
    },
    exportIcon: {
        width: "2em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
}