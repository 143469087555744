import React from 'react'
import DatePicker from 'react-datepicker'
import { ClearButton, DateContainer,InputDate, SelectorsContainer,StyledIcon,  } from './elements';
import { ButtonBuscar, ButtonDownload, StyledAutocomplete, StyledTextField } from './elementsMui';
import { Icon } from "@iconify/react";
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface Props {
    startDate: Date | null;
    setStartDate: (value: Date | null) => void;
    handleChangeDate: (date: Date | null) => void;
    especie: string;
    especies: string[];
    setEspecie: (value: string) => void;
    handleSubmit: () => void;
    submitDownloadPDF: () => void;
    nroCuentaNavigate:  string
}

export const HistorialOrdenesFilter: React.FC<Props> = ({
    startDate,
    setStartDate,
    handleChangeDate,
    especie,
    especies,
    setEspecie,
    handleSubmit,
    submitDownloadPDF,
    }) => {

    const { width } = useWindowDimensions();

    const handleClearDate = () => {
        setStartDate(null)
    };

    return (
        <SelectorsContainer>
            <DateContainer>
                <DatePicker
                    placeholderText="Fecha"
                    showIcon
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={handleChangeDate}
                    customInput={
                            <InputDate />
                        }
                />
                {startDate && (
                    <ClearButton onClick={handleClearDate}>
                        X
                    </ClearButton>
                )}
            </DateContainer>
            <StyledAutocomplete
                id="especie-autocomplete"
                freeSolo
                options={[...especies.filter((it) => 
                    it.toLowerCase().includes(especie.toLowerCase())
                    )
                ]}
                inputValue={especie}
                onInputChange={(event, newValue) => setEspecie(newValue)}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        label={especie === "" ? "Especie" : ""}
                        size="small"
                        type="text"
                        InputLabelProps={{ shrink: false }}
                        sx={{width: width * 0.12}}
                    />
                )}
            />
            <ButtonBuscar onClick={handleSubmit}>
                Buscar
            </ButtonBuscar>
            <ButtonDownload onClick={submitDownloadPDF}>
                <StyledIcon>
                    <Icon icon="lucide:download"/>
                </StyledIcon>
            </ButtonDownload>
        </SelectorsContainer>
    )
}



