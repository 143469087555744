import { styled } from "@mui/material/styles"
import { TextField, Select } from "@mui/material"

export const TextRowStyle = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    }
})) 

export const SelectRowStyle = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    }
})) 