import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { BoldText, ButtonsContainer, Container, Input, Row, Text, TextArea, TextContainer } from "./elements";
import { Button } from "@mui/material";
import { acciones } from "../MenuOrdenesExterior/MenuOrdenesExterior";
import { editarOrdenExterior } from "../../../api/ordenesExterior";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 450,
	// height: 300,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	padding: '2em 3em'
};

interface Props {
    open: boolean, 
    setOpen: (param:boolean) => void, 
    id: string, 
    cliente: string,
    especie: string,
    operacion: string,
    cantidad: string,
    precio: string,
    importe: string,
    accion: string,
    market: boolean,
}

interface Datos {
    id: string,
    estado: string | undefined,
    precio: string | undefined, 
    importe: string | undefined, 
    cantidad: string | undefined,
}

export default function ModalCambiarEstadoOrden({open, setOpen, id, cliente, especie, operacion, cantidad, precio, importe, accion, market} : Props) {
	
    const { width, height } = useWindowDimensions();

    const [motivo, setMotivo] = useState<string>('');

    const handleClose = () => {
        setOpen(false);
    };
    
    const [newDatos, setNewDatos] = useState<Datos>({
        id: id,
        estado: undefined,
        precio: undefined, 
        importe: importe, 
        cantidad: undefined,
    });

    const handleConfirmar = async() => {
        if(accion === acciones.cambiar){
            await handleCambiarDatos()
        } else {
            await handleCambiarEstado()
        }
    }

    const handleCambiarDatos = async() => {
        try {
            const res = await editarOrdenExterior(newDatos)
            if(res.status === 200){
                setOpen(false);  
                window.location.reload();
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleCambiarEstado = async() => {
        if(accion === acciones.anular){
            try {
                const res = await editarOrdenExterior({ ...newDatos, estado: 'RECHAZADA' })
                if(res.status === 200){
                    setOpen(false);  
                    window.location.reload();
                }
            } catch (error) {
                console.log(error)
            }
        } 
        if(accion === acciones.operar){
            try {
                const res = await editarOrdenExterior({ ...newDatos, estado: 'APROBADA' })
                if(res.status === 200){
                    setOpen(false);  
                    window.location.reload();
                }
            } catch (error) {
                console.log(error)
            }
        } 
    };


    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        setNewDatos({
            ...newDatos,
            [name]: value,
        })
    }


    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Container>
                    <TextContainer>
                        <BoldText>{accion}</BoldText>
                        <BoldText>Operación: {operacion}</BoldText>
                        <Text>Cliente: {cliente}</Text>
                        <Text>Especie: {especie}</Text>
                        {
                            accion === acciones.cambiar ?
                                <>
                                    {
                                        market ?
                                            <Text>Cantidad: {cantidad}</Text>
                                            : <Row>
                                                <Text>Cantidad: </Text>
                                                <Input
                                                    type="text"
                                                    name="cantidad"
                                                    value={newDatos.cantidad}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Row>
                                    }
                                    
                                    <Row>
                                        <Text>Precio: </Text> 
                                        <Input
                                        type="text"
                                        name="precio"
                                        value={newDatos.precio}
                                        onChange={(e) => handleChange(e)}
                                        />
                                    </Row>
                                </>
                                :
                                <>
                                    <Text>Cantidad: {cantidad}</Text>
                                    <Text>Precio: {precio}</Text>
                                </>
                        }
                        <Text>Importe: {importe}</Text>

                        {
                            accion === acciones.anular &&
                            <div>
                                <Text>Motivo:</Text>
                                <TextArea
                                    name="motivo"
                                    value={motivo}
                                    onChange={(e) => setMotivo(e.target.value)}
                                />
                            </div>
                        }

                    </TextContainer>

                </Container>
                <ButtonsContainer>
                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#004B6F",
                            fontSize: "0.8em",
                        }}
                        onClick={handleConfirmar}
                        variant="contained"
                        size="large"
                    >
                        CONFIRMAR
                    </Button>

                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#C9C9C9",
                            fontSize: "0.8em",
                        }}
                        onClick={handleClose}
                        variant="contained"
                        size="large"
                    >
                        CANCELAR
                    </Button>
                </ButtonsContainer>
            </Box>
        </Modal>
    );
}

const styleButtons = {
    width: "130px",
    height: "35px",
    fontWeight: "bold",
    borderRadius: '5px',
    color: 'white',
};