import React, { useState } from "react"

export const useDDJJHook = () => {

    const [openModaDDJJ, setOpenModaDDJJ] = useState<boolean>(false);

    const handleOpenModaDDJJ = () => {
        setOpenModaDDJJ(true)
    }

    return{
        handleOpenModaDDJJ,
        setOpenModaDDJJ,
        openModaDDJJ
    }
};

