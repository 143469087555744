import React, { useEffect, useState } from 'react'
import { getPedidosTranferencias } from '../api/transferencias'
import { Transferencia } from '../interfaces/interfaceTransferencias/getPedidosTranferencias';
import { cambioEstadoTransferencias } from '../api/transferencias';

export const usePedidoTransferenciaHook = () => {

  const [allTransferencias, setAllTransferencias] = useState<Transferencia[]>([]);
  const [seleccionados, setSeleccionados] = useState<string[]>([])
  const [openModalCambiarEstado, setOpenModalCambiarEstado] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false)

  useEffect(() => {
    seteadorDeTransferencia()
  }, [])

  useEffect(() => {
    setSeleccionados([])
  }, [reload])

  const smaller = (str: string) => str.length <= 10 ? str : str.slice(0, 4);
  const refactorizado = (str: string) => {
    const fecha = str.slice(0, 10)
    let hora = Number(str.slice(11, 13)) - 3
    const minSeg = str.slice(13,19)

    let newFecha = new Date(fecha);
    if (hora < 0) {
      newFecha.setDate(newFecha.getDate() - 1)
      hora += 24
    }
  
    const newFechaFormateada = newFecha.toISOString().slice(0, 10);
  
    return `${newFechaFormateada} ${hora}${minSeg}`;
  };

  const seteadorDeTransferencia = async () => {
    try {
      const resp = await getPedidosTranferencias();
      const dataResolved = resp.data.body;
      
      const dataMapeada: Transferencia[] = dataResolved.map((item: any) => ({
        _id: item._id,
        fechaHora: refactorizado(item.createdAt),
        nroCliente: item.idComitente,
        cliente: item.titular || '',
        moneda: item.idMoneda  === "1" ? "Pesos" : "D. Billete",
        descripcion: item.descripcion,
        importe: item.importeString || '0',
        banco: item.bancoDescripcion || '',
        tipoDeCuenta: item.cuentaTipoDescripcion || '',
        cuenta: item.cuenta || '',
        CBU: item.cbu || '',
        tipoIdentImp: smaller(item.identificadorImpositivoTipo) || '',
        numIdentImp: item.identificadorImpositivo || '',
        titular: item.titular || '',
        estado: item.estado || 'Desconocido',
      }));
      setAllTransferencias(dataMapeada);
      setSeleccionados([])
    } catch (error) {
      console.error('Error al obtener transferencias:', error);
    }
  };

  const handleOpenModalCambiarEstado = () => {
    setOpenModalCambiarEstado(true);
  };

  const actualizarEstado = async (ids: string[], nuevoEstado: string) => {
    const transferencias = [...ids]
    setReload(false)
    try{

    transferencias.forEach(async (tr) => await cambioEstadoTransferencias(tr, nuevoEstado))

    seteadorDeTransferencia();
    setReload(true)
    }catch(error){
      console.error(error)
    }
  }

  return {
    seteadorDeTransferencia,
    allTransferencias,
    setAllTransferencias,
    setSeleccionados,
    seleccionados,
    openModalCambiarEstado,
    setOpenModalCambiarEstado,
    handleOpenModalCambiarEstado,
    actualizarEstado,
  }
}
