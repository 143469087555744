import { useEffect, useState } from "react";
import { getLiquidezBlanqueo } from "../api/blanqueo";
import { DatosBlanqueo } from "../interfaces/interfaceBlanqueoLiquidez/getLiquidezBlanqueoInterface";

interface Props {
    setLoading: (param: boolean) => void,
    filtro?: string | undefined,
}

export default function useBlanqueoHook({ setLoading, filtro }: Props) {

    const [blanqueoData, setBlanqueoData] = useState<DatosBlanqueo[]>([])
    const [blanqueoDataFilter, setBlanqueoDataFilter] = useState<DatosBlanqueo[]>([])

    useEffect(() => {
        getBlanqueoLiquidez()
    }, [])


    useEffect(() => {
        filtrar()
    }, [filtro, blanqueoData])


    // Petición a la api
    const getBlanqueoLiquidez = async () => {
        try {
            setLoading(true);
            const { data } = await getLiquidezBlanqueo();
            setBlanqueoData(data.body.datosBlanqueo)
            filtrar()
            // setBlanqueoDataFilter(data.body.datosBlanqueo)
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    };

    // Funcion de filtro

    const filtrar = () => {
        let filtered = blanqueoData
        if (filtro) {
            filtered = filtro.length === 0 ? blanqueoData : blanqueoData.filter(
                (e) =>
                    e.comitente.includes(filtro)
            );
        }

        sort(filtered);
        setLoading(false);
    };

    const sort = (blancarr: DatosBlanqueo[]) => {
        const arr = blancarr.sort((a, b) => {
            // Ordenar primero por `ultimaOperacionPesos`
            if (a.ultimaOperacionPesos === null && b.ultimaOperacionPesos !== null) {
                return 1;
            }
            if (a.ultimaOperacionPesos !== null && b.ultimaOperacionPesos === null) {
                return -1;
            }

            // Si ambos tienen la misma condición para `ultimaOperacionPesos`, ordenar por `ultimaOperacionPesosDolares`
            if (a.ultimaOperacionPesosDolares === null && b.ultimaOperacionPesosDolares !== null) {
                return 1;
            }
            if (a.ultimaOperacionPesosDolares !== null && b.ultimaOperacionPesosDolares === null) {
                return -1;
            }

            // // Si ambas condiciones son iguales, ordenar por `saldoPesos`, manejando `null` como menor que cualquier número
            // if (a.saldoPesos === null && b.saldoPesos !== null) {
            //     return 1;  // Colocar los `null` después de los números
            // }
            // if (a.saldoPesos !== null && b.saldoPesos === null) {
            //     return -1;
            // }

            // // Si ambos son números, comparar normalmente
            // if (a.saldoPesos !== null && b.saldoPesos !== null) {
            //     return b.saldoPesos - a.saldoPesos;  // Ordenar de mayor a menor
            // }

            // Si todas las condiciones son iguales, no cambiar el orden
            return 0;
        });

        setBlanqueoDataFilter(arr);
    };





    return {
        blanqueoData,
        blanqueoDataFilter
    };
}
