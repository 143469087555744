import DataTable from "react-data-table-component";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useOrdenesCartera } from "../../hooks/useOrdenesCartera";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Container, SecondContainer, Subtitulo, SubtituloContainer, TableContainer, WhiteBox, customStyles } from "./elements";
import { columnsOrdenesCartera } from "../../helper/Colums/columsOrdenesCartera";

const paginationOpciones = {
	rowsPerPageText: "Filas por página",
	rangeSeparatorText: "de",
	selectAllRowsItem: true,
	selectAllRowsItemText: "Todos",
};

export const OrdenesCartera = () => {
	const { width, height } = useWindowDimensions();

	const {
		ordenesFilt,
		arrMenuList,
		loading,
		filtroActual,
		handleChange,
	} = useOrdenesCartera();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 4;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 200,
			},
		},
	};


	return (
		<Container width={width} height={height}>	
			<SecondContainer>
				<SubtituloContainer>
					<Subtitulo>ÓRDENES</Subtitulo>
					<div>
						<FormControl sx={{ width: 180 }}>
							<InputLabel id="demo-multiple-checkbox-label">Seleccionar filtro</InputLabel>
							<Select
								labelId="demo-multiple-checkbox-label"
								id="demo-multiple-checkbox"
								multiple
								value={filtroActual}
								onChange={handleChange}
								input={<OutlinedInput label="Seleccionar filtro" />}
								renderValue={(selected) => selected.join(', ')}
								MenuProps={MenuProps}
								disableUnderline={true}
							>
								{arrMenuList.map((name) => (
									<MenuItem key={name} value={name}>
										<Checkbox checked={filtroActual.includes(name)} />
										<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</SubtituloContainer>
				<WhiteBox>
					<TableContainer>
						<DataTable
							columns={columnsOrdenesCartera}
							data={ordenesFilt? ordenesFilt : []}
							pagination
							paginationPerPage={25}
							paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
							paginationComponentOptions={paginationOpciones}
							fixedHeader
							fixedHeaderScrollHeight="600px"
							customStyles={customStyles(height)}
							noDataComponent={
								loading ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<CircularProgress
											style={{ color: "#004B6F" }}
										/>
									</Box>
								) : (
									<>No hay órdenes por el momento</>
								)
							}
							responsive
							keyField="id"
						/>
					</TableContainer>
				</WhiteBox>
			</SecondContainer>
		</Container>
	);
};