import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Icon } from '@iconify/react';
import Button from "@mui/material/Button";
import { TableColumn } from 'react-data-table-component';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useTableHook from "../../hooks/useTableHook";
import { roles } from "../../helper/roles";
import { DownloadButton, CheckContainer, CheckText, DataBox, InputSearch, PlusIconContainer, PlusTexto, SearchButton, SearchContainer, Sub, SubContainer, RowContent, ButtonContainer } from "./elements";
import { baseURL } from "../../utils/Axios";
import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import ModalEstadoPedidoDolar from "../Table/ModalEstadoPedidoDolar/ModalEstadoPedidoDolar";
import useTableDolarRenta from "../../hooks/useTableDolarRenta";

// Tabla que se renderiza en las diferentes screens

interface Props {
    subtitulo: string,
    titularId?: string,
    columns: TableColumn<any>[],
    filtroTenencia?: string
    reloadTable?: boolean
    setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TableDolarRenta({ subtitulo, columns, titularId, filtroTenencia, reloadTable, setReloadTable }: Props) {
    const { width, height } = useWindowDimensions();
    const {
        handleInput,
        data,
        paginationOpciones,
        loading,
        showAprobadasRechazadas,
        setShowAprobadasRechazadas,
        name,
        pedidos,
    } = useTableDolarRenta({ subtitulo, titularId: titularId || '', filtroTenencia, reloadTable, setReloadTable});

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: subtitulo === "PEDIDOS DOLARES TENENCIA" ? '6em' : height * 0.75,
                maxHeight: "33em",
                // minWidh: "30em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
                // borderRadius: "20px"
            },
        },

        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "58px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
                //wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            },
        },
    };

    let altura = subtitulo === "PEDIDOS DOLARES TENENCIA" ?
            `${height * 0.22}px`
            : `${height * 0.75}px`;


    let widthContainer = subtitulo !== "PEDIDOS DOLARES TENENCIA" ? width * 0.84 : "85%"
    let heightContainer = subtitulo !== "PEDIDOS DOLARES TENENCIA" ? height * 0.85 : undefined
    const urlPedidosDola = `${baseURL}/exportarDola/${showAprobadasRechazadas ? 'show' : 'notshow'}`;
    const urlDolares = `${baseURL}/exportarDolares`;

    const [openModalPedidoDolar, setOpenModalPedidoDolar] = useState<boolean>(false);

    const handleOpenModalPedidoDolar = () => {
        setOpenModalPedidoDolar(true);
    }

    return (
        <div style={{ width: widthContainer, height: heightContainer, margin: subtitulo === "PEDIDOS DOLARES TENENCIA" ? "1em 0em 0em 3.8em" : undefined }}>
            {subtitulo
                !== "PEDIDOS DOLARES TENENCIA" ?
                <DataBox>
                    <SubContainer>
                        <Sub>{subtitulo}</Sub>
                        {
                            (subtitulo === "PEDIDOS DOLARES" || subtitulo === "DOLARES") &&
                            <div style={{ marginRight: "0.3em" }}>
                                <DownloadButton>
                                    <a href={subtitulo === "PEDIDOS DOLARES" ? urlPedidosDola : urlDolares} target="_blank" rel="noreferrer">
                                        <Icon
                                            icon="lucide:download"
                                            style={{ ...styles.logo, color: "#004B6F" }}
                                        />
                                    </a>
                                </DownloadButton>
                            </div>
                        }
                    </SubContainer>
                    {
                        subtitulo === "PEDIDOS DOLARES" &&
                        <RowContent>
                            <CheckContainer>
                                <Checkbox
                                    inputProps={{
                                        "aria-label": "controlled",
                                    }}
                                    onChange={() => setShowAprobadasRechazadas(!showAprobadasRechazadas)}
                                    checked={showAprobadasRechazadas}
                                    style={{
                                        color: "#004B6F",
                                        margin: 0,
                                    }}
                                    classes={{
                                        checked: "Mui-checked",
                                    }}
                                />
                                <CheckText>Mostrar aprobadas/rechazadas</CheckText>
                            </CheckContainer>
                            <ButtonContainer>
                                {
                                    pedidos.length !== 0 &&
                                    <>
                                        <Button
                                            id="demo-customized-button"
                                            onClick={handleOpenModalPedidoDolar}
                                            variant="contained"
                                            size="small"
                                            style={{
                                                textTransform: "capitalize",
                                                fontSize: "12px",
                                                borderRadius: "5px",
                                                background: "#004B6F",
                                                color: "white",
                                                padding: "6px 12px",
                                                marginBottom: '6px',
                                            }}
                                        >
                                            CAMBIAR DE ESTADO
                                        </Button>
                                        <ModalEstadoPedidoDolar
                                            open={openModalPedidoDolar}
                                            setOpen={setOpenModalPedidoDolar}
                                            pedidos={pedidos}
                                        />
                                    </>
                                }
                            </ButtonContainer>
                        </RowContent>
                    }
                    <div style={{ display: "flex" }}>

                        <SearchContainer>
                            <InputSearch
                                type="text"
                                placeholder="Buscar..."
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
                                value={name}
                            />
                            <SearchButton
                                type="submit"
                            >
                                <img
                                    src={require("../../images/tabla/search-icon.png")}
                                    alt="logo"
                                    style={{ width: "1em" }}
                                />
                            </SearchButton>
                        </SearchContainer>
                    </div>
                </DataBox>
                : null}

                <DataTable
                    columns={columns}
                    data={data!}
                    pagination={subtitulo === "PEDIDOS DOLARES TENENCIA"? false : true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                    paginationComponentOptions={paginationOpciones}
                    fixedHeader
                    fixedHeaderScrollHeight={altura}
                    // minHeight={altura}
                    customStyles={customStyles}
                    progressPending={reloadTable}
                    progressComponent={<CircularProgress />}
                    noDataComponent={
                        loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center", marginTop:"1em" }}>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </Box>
                        ) : (
                            <p style={{ marginTop: "2em" }}>No se encontró ningún elemento</p>
                        )
                    }
                    responsive
                    keyField="id"
                />
        </div>
    );
}


const styles = {
    links: {
        textDecoration: "none",
        cursor: "pointer",
        marginRight: "2em",
        marginTop: "0.25em",
    },
    menu: {
        display: "flex",
        backgroundColor: "white",
        height: "2.2em",
        borderRadius: "14px",
        padding: 0,
        margin: 0,
    },
    listItem: {
        display: "flex",
        padding: "0.1em 0.5em 0.1em 0.5em",
        margin: 0,
        fontSize: "0.9em",
        cursor: "pointer",
    },
    menuItem: {
        fontSize: "0.9em",
        backgroundColor: "white",
        color: "rgba(0, 75, 111, 0.75)",
    },
    plusIcon: {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
    logo: {
        fontSize: "1.7em",
        color: "rgba(85, 86, 88, 0.75)",
        margin: "0.2em",
    },
}