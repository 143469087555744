import styled from "styled-components";

export const styles = {
    icon: {
        marginLeft: '0.5em',
    },
}

export const Container = styled.div`
    height: calc(100vh - 2em);
    position: absolute;
    top: 2em;
    display: flex;
    flex-direction: column;
    padding: 2em;
`;

export const Subtitulo = styled.p`
    font-size: 2em;
    font-weight: bold;
    color: rgba(0, 75, 111, 0.75);
`;

export const WhiteBox = styled.div`
    background-color: white;
    margin-top: 1em;
    height: 90%;
    width: 100%;
    /* border-radius: 0.8em; */
    margin-bottom: 0;
`;

export const RowContainerBetween = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0.4em;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 0.5em;
`;

export const Select = styled.select`
    width: 20%;
    background: transparent;
	padding: 0.5em;
	color: #00386E;	
    font-weight: bold;
    border: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
    outline: none;
	background-image: url("https://api.iconify.design/ep/arrow-right-bold.svg?color=#00386E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
    cursor: pointer;
	/* @media (max-width: 992px) {
		font-size: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 0.9em;
	} */
`;

export const Option = styled.option`
    font-size: 1em;
`;

export const ButtonOutline = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1em;
    color: #00386E;	
    margin: 0.5em 1em;
    font-weight: bold;
`;

export const TableContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 1em;
`;