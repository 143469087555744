import DatePicker from "react-datepicker"
import { ButtonSearch, ClearButton, DateContainer, InputDate, SecondContainer, StyledAutocomplete, StyledTextField } from "./elements"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { EspeciesTotales, FechaBroker } from "../../interfaces/interfConciliacionExteior/interfConciliacionExterior"

interface Props {
    handleSearch: () => void;
    fechasPosibles: FechaBroker | undefined;
    tipoBroker: string;
    handleClearDate: () => void;
    handleDateChange: (date: Date | null) => void;
    getEspecie: () => string;
    especies: EspeciesTotales;
    setEspecie: (value: string) => void;
    startDate: Date | null;
}
export const ConciliacionExteriorFilter = (
    {handleSearch, fechasPosibles, tipoBroker, handleClearDate, handleDateChange, getEspecie, especies, setEspecie, startDate }: Props
) => {
    
    const { width } = useWindowDimensions()

    return(
        <SecondContainer>
            <StyledAutocomplete
                id="especie-autocomplete"
                freeSolo
                options={
                    (tipoBroker === "INTERACTIVE" ? especies.especiesInteractive : especies.especiesSchwab)
                        .filter((it) => it.toLowerCase().includes(getEspecie().toLowerCase()))
                }
                inputValue={getEspecie()}
                onInputChange={(event, newValue) => setEspecie(newValue)}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        label={getEspecie() === "" ? "Especie" : ""}
                        size="small"
                        type="text"
                        InputLabelProps={{ shrink: false }}
                        sx={{ width: width * 0.11 }}
                    />
                )}
            />
        <div style={{ display:"flex" }}>
            <DateContainer>
                <DatePicker
                    placeholderText="Fecha"
                    showIcon
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={handleDateChange}
                    highlightDates={ tipoBroker === "SCHWAB"
                        ? fechasPosibles?.fechasSchwab
                        : fechasPosibles?.fechasInteractive
                    }
                    filterDate={(date:Date) => {
                        const fechasValidas =
                            tipoBroker === "SCHWAB"
                                ? fechasPosibles?.fechasSchwab
                                : fechasPosibles?.fechasInteractive;
                        return fechasValidas?.some((fecha) =>
                            fecha.toISOString().split("T")[0] === date.toISOString().split("T")[0]
                        );
                    }}
                    customInput={
                            <InputDate />
                        }
                />
                {startDate && (
                    <ClearButton onClick={handleClearDate}>
                        X
                    </ClearButton>
                )}
            </DateContainer>
            <ButtonSearch
                type="button"
                variant="contained"
                size="large"
                onClick={handleSearch}
            >Buscar</ButtonSearch>
        </div>
    </SecondContainer>
    )
}