import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Box, Form, Input, Label, LogoAdjuntar, MiniBox, SeccionAdjuntos, SeccionButtons, SelectContainer } from "./elements";
import { useConciliacionesTitulos } from "../../hooks/useConciliacionesTitulos";

export const ConciliacionesTitulos = () => {
	const {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		galloArchive,
		setGalloArchive,
		tipoProcesamiento, 
		handleChange,
	} = useConciliacionesTitulos();
	const data = [
		{
			id: "caja",
			label: "CAJA (XLS)",
			accept: ".xls,.xlsx",
			state: cajaArchive,
			setState: setCajaArchive,
			alt: "adjuntar-dorado.png",
		},
		{
			id: "gallo",
			label: "GALLO (XLS)",
			accept: ".xls,.xlsx",
			state: galloArchive,
			setState: setGalloArchive,
			alt: "adjuntar-dorado.png",
		},
	];

	const { width, height } = useWindowDimensions();

	return (
		<Form
			style={{
				width: width * 0.84,
				height: height * 0.15,
			}}
			onSubmit={async (event) => {
				event.preventDefault(); // Evitar que el formulario se envíe automáticamente
				await handleSubmit(); // Esperar a que se complete la función handleSubmit
			}}
		>
			<Box>
				<>
					<SeccionAdjuntos>
						{data.map(
							({ id, label, accept, state, setState, alt }) => (
								<MiniBox key={id}>
									{state ? (
										<>
											<Label
												htmlFor={id}
											>
												<LogoAdjuntar
													src={require(`../../images/documentos/${alt}`)}
													alt="logo"
												/>
												{label}
											</Label>
											<Input
												type="file"
												accept={accept}
												id={id}
												onChange={(e) => {
													if (e.target.files && e.target.files[0]) {
														setState(e.target.files[0]);
													}
												}}
											/>
										</>
									) : (
										<>
											<Label
												htmlFor={id}
											>
												<LogoAdjuntar
													src={require("../../images/documentos/adjuntar.png")}
													alt="logo"
												/>
												{label}
											</Label>
											<Input
												type="file"
												accept={accept}
												id={id}
												onChange={(e) => {
													if (e.target.files && e.target.files[0]) {
														setState(e.target.files[0]);
													}
												}}
											/>
										</>
									)}
								</MiniBox>
							)
						)}
						<SelectContainer>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Seleccionar</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={tipoProcesamiento}
									label="Seleccionar"
									onChange={handleChange}
								>
									<MenuItem value='conciliarClientes'>Titulos</MenuItem>
									<MenuItem value='conciliarEspecies'>Especies</MenuItem>
								</Select>
							</FormControl>
						</SelectContainer>
						<SeccionButtons>
							<Button
								style={{
									marginRight: "16px",
									backgroundColor: "#004B6F",
								}}
								type="submit"
								variant="contained"
								size="large"
							>
								PROCESAR
							</Button>
						</SeccionButtons>
					</SeccionAdjuntos>
				</>
			</Box>
		</Form>
	);
};
