import styled from "styled-components";

export const ContainerEstado = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 8px;
    height: 2.5em;
    border-radius: 8px;
    gap: 8px;
`

export const CheckInput = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;
`
export const MoreHorizIconContainer = styled.div`
    width: 4em;
    display: flex;
    align-items: center;
    align-content: center;
`