import styled from "styled-components";

export const Contenedor = styled.div`
width: 85%;
padding: 1rem 1rem 0 1rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
margin-left: 4rem;
margin-top: 1rem;
border-radius: 16px;
boxShadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const CuentaContainer = styled.div`
    display: flex;
    width: 100%;
    color: #515151;
    background-color: #f7f8fa;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding-block: 0.5rem;
    padding-left: 0.5rem;
`