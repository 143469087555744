import styled from "styled-components";

export const Container = styled.div`
width: 85%;
display: flex;
justify-content: space-between;
margin-top: 1em;
margin-left: 3.8em;
cursor: pointer;
`

export const ContainerButtons = styled.div`
width: 100%;
text-align: center;
`

export const TextoBotones = styled.p`
margin: 0.3em;
`